import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { DialogActions, Stack, Typography } from '@mui/material';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import { ThemeButton } from '../../../components/button';
import ThemeCheckbox from '../../../components/checkbox/themeChekbox';
import FormInput from '../../../components/formComponents/FormInput';

const FilterModal = ({
  filterOptions,
  closeFilter,
  filterConfig = {
    filters: {}, // Stores filters for multiple columns, where each column is an array of values
    sortOrder: '',
    sortBy: '',
  },
  setFilterConfig,
  filterBy,
  setFilterBy,
  sortBy,
}) => {
  const [filterValues, setFilterValues] = useState([]);
  const [tempFilterConfig, setTempFilterConfig] = useState({
    filters: {}, // Stores filters for each column
    sortOrder: '',
    sortBy: '',
  });
  const [searchKeys, setSearchKeys] = useState('');
  const [sortOrder, setSortOrder] = React.useState('');
  // const handleOptionChange = (event, value) => {
  //   if (filterValues.includes(value)) {
  //     const newValues = filterValues.filter((option) => option !== value);
  //     setFilterValues(newValues);
  //   } else {
  //     setFilterValues((prevValues) => [...prevValues, value]);
  //   }
  // };
  const handleFilterChange = (event, value, column) => {
    const isChecked = event.target.checked;
    // setFilterConfig((prevConfig) => {
    setTempFilterConfig((prevConfig) => {
      const filters = prevConfig.filters[column] || [];

      // If the checkbox is checked, add the value to the filters array
      if (isChecked && !filters.includes(value)) {
        return {
          ...prevConfig,
          filters: {
            ...prevConfig.filters,
            [column]: [...filters, value], // Add the filter value
          },
        };
      }

      // If the checkbox is unchecked, remove the value from the filters array
      if (!isChecked) {
        return {
          ...prevConfig,
          filters: {
            ...prevConfig.filters,
            [column]: filters.filter((filter) => filter !== value), // Remove the filter value
          },
        };
      }

      return prevConfig;
    });
  };
  const isChecked = (value, column) => {
    if (tempFilterConfig?.filters[column]) {
      return tempFilterConfig?.filters[column]?.includes(value);
    }
    // if (filterConfig?.filters[column]) {
    //   return filterConfig?.filters[column]?.includes(value);
    // }
    return false;
  };
  const filteredOptions = useMemo(
    () =>
      filterOptions?.length >= 0
        ? filterOptions?.filter((filterOption) =>
            // eslint-disable-next-line no-nested-ternary
            typeof filterOption?.label === 'string'
              ? filterOption?.label?.toLocaleLowerCase().includes(searchKeys.toLocaleLowerCase())
              : searchKeys
                ? filterOption?.label === searchKeys
                : true
          )
        : [],
    [filterOptions, searchKeys]
  );

  useEffect(() => {
    if (filterConfig) {
      // setFilterValues(filterConfig?.filterColumns);
      setTempFilterConfig(() => filterConfig);
    }
    if (filterConfig?.sortOrder && filterConfig?.sortBy === sortBy) {
      setSortOrder(filterConfig.sortOrder);
    }
  }, [filterBy, filterConfig, sortBy]);
  // useEffect(() => {
  //   if (filterConfig?.filterColumns?.length > 0 && filterConfig?.filterBy === filterBy) {
  //     setFilterValues(filterConfig?.filterColumns);
  //   }
  //   if (filterConfig?.sortOrder && filterConfig?.sortBy === sortBy) {
  //     setSortOrder(filterConfig.sortOrder);
  //   }
  // }, [filterBy, filterConfig, sortBy]);

  const handleFilter = () => {
    // setFilterConfig({
    //   filterColumns: filterValues,
    //   filterBy,
    //   sortBy,
    //   sortOrder,
    // });
    setFilterConfig(() => tempFilterConfig);
    if (setFilterBy) {
      setFilterBy('');
    }
    closeFilter();
  };
  const handleInputChange = (event) => setSearchKeys(() => event?.target.value);
  const clearFilters = () => {
    setFilterValues([]);
    setSortOrder('');
    setFilterConfig({
      // filterColumns: [],
      sortType: 'string',
      filterBy,
      sortOrder: '',
      sortBy: '',
      isLabelNumeric: false,
      filters: {}, // Stores filters for multiple columns, where each column is an array of values
    });
    closeFilter();
    if (setFilterBy) {
      setFilterBy('');
    }
  };
  const handleCloseFilter = () => {
    closeFilter();
    if (setFilterBy) {
      setFilterBy('');
    }
  };
  const getDecendingLabel = () => {
    if (filterConfig?.isLabelNumeric || filterConfig?.sortType === 'date') {
      return 'High to Low';
    }
    return 'Z to A';
  };
  const getAscendingLabel = () => {
    if (filterConfig?.isLabelNumeric || filterConfig?.sortType === 'date') {
      return 'Low to High';
    }
    return 'A to Z';
  };
  return (
    <>
      <Stack
        direction="column"
        sx={{ p: 2, height: '440px', overflowY: 'auto', '& .MuiCheckbox-root': { py: '2px !important' } }}
      >
        <FormControl sx={{ mb: 2 }} component="fieldset" variant="standard">
          <FormLabel component="legend">Sort</FormLabel>
          <FormGroup row>
            <ThemeCheckbox
              InputProps={{ size: 'small' }}
              label={getAscendingLabel()}
              onChange={(event) => {
                setSortOrder('asc');
                setTempFilterConfig((prevConfig) => ({ ...prevConfig, sortBy, sortOrder: 'asc' }));
              }}
              checked={sortOrder === 'asc' && tempFilterConfig.sortBy === sortBy}
            />
            <ThemeCheckbox
              InputProps={{ size: 'small' }}
              label={getDecendingLabel()}
              onChange={(event) => {
                setTempFilterConfig((prevConfig) => ({ ...prevConfig, sortBy, sortOrder: 'desc' }));
                setSortOrder('desc');
              }}
              checked={sortOrder === 'desc' && tempFilterConfig.sortBy === sortBy}
            />
          </FormGroup>
        </FormControl>
        <FormInput
          // width="90%"
          height={36}
          label="Filter"
          placeholder="Search..."
          InputProps={{
            value: searchKeys,
            onChange: handleInputChange,
            // ...register('name'),
          }}
        />
        {filteredOptions?.map((option) => (
          <ThemeCheckbox
            InputProps={{ size: 'small' }}
            label={option?.label}
            // onChange={(event) => handleOptionChange(event, option?.value)}
            onChange={(event) => handleFilterChange(event, option?.value, filterBy)}
            // checked={filterValues.includes(option?.value)}
            checked={isChecked(option?.value, filterBy)}
          />
          // <span>{option.label}</span>
        ))}
        {searchKeys && filteredOptions?.length <= 0 && (
          <Typography variant="subtitle1" textAlign="center">
            No data found for <b>{searchKeys}</b>
          </Typography>
        )}
      </Stack>

      <DialogActions sx={{ mt: 2, px: 0, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <ThemeButton type="button" onClick={clearFilters}>
          Clear All
        </ThemeButton>
        <ThemeButton type="button" onClick={handleCloseFilter} color="error" variant="outlined">
          Cancel
        </ThemeButton>
        <ThemeButton onClick={handleFilter} type="button" variant="contained">
          Apply
        </ThemeButton>
      </DialogActions>
    </>
  );
};

FilterModal.propTypes = {
  filterOptions: PropTypes.array,
  closeFilter: PropTypes.func,
  setFilterBy: PropTypes.func,
  filterConfig: PropTypes.any,
  setFilterConfig: PropTypes.any,
  filterBy: PropTypes.string,
  sortBy: PropTypes.string,
};

export default FilterModal;
