/* eslint-disable no-unreachable */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, DialogActions, Grid, IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import { get, has, isEmpty } from 'lodash';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmDialog from '../../components/ConfirmDialog';
import ModalComponent from '../../components/ModalComponent';
import StudiesComponent from '../../components/StudiesComponent';
import { ThemeButton } from '../../components/button';
import CheckboxGroup from '../../components/checkbox/CheckboxGroup';
import GroupCheckbox from '../../components/formComponents/FormCheckbox';
import FormInput from '../../components/formComponents/FormInput';
import FormRadioGroup from '../../components/formComponents/formRadioGroup';
import CustomSelect from '../../components/select';
import { setSnackbar } from '../../components/snackbar/snackbarSlice';
import ThemeSwitch from '../../components/switch';
import DataTable from '../../components/table';
import { Types } from '../../types';
import { filterEmptyValues } from '../../utils';
import axiosInstance from '../../utils/axiosInstance';
import { addElementSchema, addTaskSchema } from '../../utils/schema';
import { getGroups } from '../groupRole/groupSlice';
import { getCategories } from '../projectList/category/projectCategoriesSlice';
import { addElement, editElement, getElements } from '../projectList/elements/elementsSlice';
import { addTask, getTasks, reorderTasks, updateTask } from './taskSlice';
import { SortableElement } from './TasksNestedRow';

const TasksListPage = ({ projectID, customerID }) => {
  const dispatch = useDispatch();
  const { tasks, isLoading, isAdding, isReorderLoding } = useSelector((state) => state.tasks);
  const { elements, addElementLoading } = useSelector((state) => state.projectElements);
  const { projectCategories: categories } = useSelector((state) => state.projectCategories);
  const { groups } = useSelector((state) => state.groups);
  const [tasksRows, setTasksRows] = useState([]);
  const { DEFAULT, ACTIVE, ARCHIVED } = Types;
  const [activeId, setActiveId] = useState('');
  const [elementRowToEdit, setElementRowToEdit] = useState('');
  const [sortedTasks, setSortedTasks] = useState([]);
  const [rowToEdit, setRowToEdit] = useState('');
  const [selectedRowToDelete, setSelectedRowToDelete] = useState('');
  const [taskToUpdate, setTaskToUpdate] = useState(null);
  const [overId, setOverId] = useState('');
  const [isModalopen, setisModalopen] = useState(false);
  const [isDeleteModelOpen, setDeleteModelOpen] = useState(false);
  const [isElementModalopen, setIsElementModalopen] = useState(false);
  const [Status, setStatus] = useState(ACTIVE);
  const [isReording, setIsReording] = useState(false);
  const [elementRows, setElementRows] = useState([]);
  const [isTaskReording, setTaskIsReording] = useState(false);
  const [sortedElements, setSortedElements] = useState([]);
  const hadleReorderClick = () => setIsReording(!isReording);
  const saveReorder = () => setIsReording(!isReording);
  useEffect(() => {
    if (projectID) {
      dispatch(getTasks({ projectID }));
      dispatch(getElements({ projectID, defaultEntry: false, isFromTask: true }));
      dispatch(getCategories({ projectID, defaultEntry: false }));
      dispatch(getGroups({ projectID }));
    }
  }, [dispatch, projectID]);
  const studyTypeConfig = { label: 'label', value: 'value' };
  const studyTypeOptions = [
    { label: 'Efficiency Study', value: 1, color: '#f59d0f' },
    { label: 'Role Study', value: 2, color: '#784fc2' },
    { label: 'Activity Study', value: 3, color: '#0ba0ef' },
  ];
  const headCells = [
    {
      id: 'name',
      numeric: false,
      width: '30%',
      // disablePadding: true,
      label: 'Task',
      // align: 'left',
    },
    {
      id: 'studiesLength',
      disablePadding: false,
      label: 'Studies',
      // minWidth: '360px',
      // maxWidth: '360px',
      align: 'start',
      renderElement: (cell) => <StudiesComponent pl={0.1} studyTypes={get(cell, 'projectSetting.studyTypes')} />,
    },
    {
      id: 'groupName',
      numeric: true,
      disablePadding: false,
      label: 'Groups',
      // renderElement: (cell) =>
      //   get(cell, 'projectSetting.groups') && (
      //     <Stack direction="row">
      //       {get(cell, 'projectSetting.groups')?.map((groupField, index) => (
      //         <React.Fragment key={groupField}>
      //           <span>{groups?.find((group) => group?._id === groupField)?.name}</span>
      //           {index < get(cell, 'projectSetting.groups').length - 1 && ', '}
      //         </React.Fragment>
      //       ))}
      //     </Stack>
      //   ),
    },
  ];
  const ratingOptions = useMemo(
    () => [
      {
        label: 'Not Rated',
        description: 'No rating screen in app, SMV not shown in CMS or in any values. Only in data summary export',
        value: 1,
      },
      { label: '100', description: 'No rating screen in app, element defaults to 100 rating', value: 2 },
      { label: 'Field User Input', description: 'Show rating screen in the app for the element', value: 3 },
    ],
    []
  );
  const groupOptions = useMemo(
    () =>
      groups && groups?.length > 0
        ? groups
            ?.filter((currentGroup) => currentGroup?.projectSetting?.status === 'active')
            ?.map((group) => ({ label: group?.name, value: group?._id }))
        : [],
    [groups]
  );
  const result = useMemo(
    () =>
      tasks.reduce(
        (acc, task, index) => {
          const allElements =
            get(task, 'projectSetting.elements') &&
            task?.projectSetting?.elements?.length > 0 &&
            !!elements?.length &&
            task?.projectSetting?.elements
              .map((element) => elements.find((el) => el?._id === element) || null)
              .filter((el) => el);
          // elements.filter((element) => task?.projectSetting?.elements?.includes(element?._id));
          // task?.projectSetting?.elements?.map(
          //   (element) => elements.find((currentElement) => currentElement?._id === element) || null
          // );

          // task = { ...task, elements: allElements, _id: index + 1 };
          task = {
            ...task,
            status: get(task, 'projectSetting.status', ''),
            elements: allElements,
            studiesLength: get(task, 'projectSetting.studyTypes') && task?.projectSetting?.studyTypes?.length,
            groupName:
              get(task, 'projectSetting.groupID') &&
              groups &&
              groups?.find((group) => group?._id === task?.projectSetting?.groupID)?.name,
          };
          if (get(task, 'projectSetting.status')) {
            if (get(task, 'projectSetting.status') === 'active') {
              acc.active.push(task);
            } else if (get(task, 'projectSetting.status') === 'archive') {
              acc.archive.push(task);
            }
            acc.default.push(task);
          }
          return acc;
        },
        { active: [], archive: [], default: [] }
      ),
    [elements, groups, tasks]
  );
  useEffect(() => {
    if (result) {
      if (Status === DEFAULT) {
        setTasksRows(result?.default);
      } else if (Status === ARCHIVED) {
        setTasksRows(result?.archive);
      } else if (Status === ACTIVE) {
        setTasksRows(result?.active);
      }
    } else {
      setTasksRows([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);
  const onStatusChange = (value) => {
    if (value) {
      setStatus(value);
      if (value === DEFAULT) {
        setTasksRows(result.default);
      } else if (value === ACTIVE) {
        setTasksRows(result.active);
      } else if (value === ARCHIVED) {
        setTasksRows(result.archive);
      }
    }
  };
  const categoryOptions = useMemo(
    () =>
      categories
        .filter((category) => has(category, 'projectSetting.status') && category.projectSetting.status === 'active')
        .map((category) => ({ label: category.name, value: category._id })),
    [categories]
  );
  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      setActiveId(active.id);
      setOverId(over.id);
      // here we want to do temporary sorting

      const activeTempIndex = tasksRows.findIndex((row) => row._id === active.id);
      const overTempIndex = tasksRows.findIndex((row) => row._id === over.id);
      const activeIndex =
        sortedTasks?.length > 0
          ? sortedTasks.findIndex((row) => row._id === active.id)
          : result?.default?.findIndex((row) => row._id === active.id);
      const overIndex =
        sortedTasks?.length > 0
          ? sortedTasks.findIndex((row) => row._id === over.id)
          : result?.default?.findIndex((row) => row._id === over.id);

      const sortedTempArray = arrayMove(tasksRows, activeTempIndex, overTempIndex);
      setTasksRows(sortedTempArray);
      const sortedArray =
        sortedTasks?.length > 0
          ? arrayMove(sortedTasks, activeIndex, overIndex)
          : arrayMove(result.default, activeIndex, overIndex);

      setSortedTasks(sortedArray);
    }
  };
  const onEditClick = async (event, row) => {
    if (row?._id) {
      setRowToEdit((prevRow) => row?._id);
      const taskToEdit = result.default.find((task) => task?._id === row?._id);
      setValue('name', get(taskToEdit, 'name', ''));
      setValue('controllingElementID', get(taskToEdit, 'projectSetting.controllingElementID', ''));
      setValue('studyTypes', get(taskToEdit, 'projectSetting.studyTypes', []));
      setValue('groupID', get(taskToEdit, 'projectSetting.groupID', ''));
      setValue('elements', get(taskToEdit, 'elements', []) ? taskToEdit.elements.map((el) => el?._id) : []);
      openModal();
    } else {
      console.warn('No Row Id found');
    }
  };
  const handleGlobalReorder = async () => {
    if (sortedTasks?.length > 0) {
      const pureTasks = sortedTasks.map((task) => task?._id);
      if (projectID) {
        dispatch(reorderTasks({ projectID, tasks: pureTasks }))
          .then((response) => {
            if (response.payload?.success) {
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'success',
                  snackbarMessage: get(response, 'payload.message', 'Tasks Reordered Successfully'),
                })
              );
              dispatch(getTasks({ projectID }));
            } else if (get(response, 'payload.message')) {
              const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'error',
                  snackbarMessage: errorMessage,
                })
              );
            }
          })
          .catch((error) => {
            console.log('error==> ', error);
          });
      }
    }
    setSortedTasks([]);
    hadleReorderClick();
  };

  const cancelReorder = () => {
    setSortedTasks([]);
    setTasksRows(get(result, `${Status}`));
    hadleReorderClick();
  };
  const onRowStatusChange = async (row, status, startLoading, closeLoading) => {
    if (!status) {
      // also check if status is archive or active then only make api call
      console.log('No status provided');
      return;
    }
    if (row?._id) {
      startLoading();
      try {
        const response = await axiosInstance({
          // url: `users/updatestatus/${row?._id}/${status}`,
          url: 'tasks/update/status',
          method: 'PATCH',
          data: { taskIDs: [row?._id], status, projectID },
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: `Successfully ${status === 'active' ? 'Activated' : 'Archived'} Task`,
            })
          );
          dispatch(getTasks({ projectID }));
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        closeLoading();
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  const onDelete = async (row, startLoading, closeLoading) => {
    if (row?._id && projectID) {
      startLoading();
      try {
        const response = await axiosInstance({
          url: `tasks/delete/${row?._id}`,
          method: 'DELETE',
          data: { projectID },
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'data.message'),
            })
          );
          dispatch(getTasks({ projectID }));
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        closeLoading();
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  const openModal = () => setisModalopen(true);
  const closeModal = () => {
    setisModalopen(() => false);
    setTaskIsReording(false);
    reset();
    // reset({ permission: '' });
    setTimeout(() => {
      setRowToEdit('');
    }, 200);
  };
  const openElementModal = (row) => {
    const tasksValues = [
      {
        taskID: row?._id,
        elements: (row?.elements?.length && row?.elements?.map((element) => element?._id)) || [],
      },
    ];
    form2.setValue('tasks', tasksValues);
    setIsElementModalopen(true);
  };
  const closeElementModal = () => {
    setIsElementModalopen((prevState) => false);
    if (elementRowToEdit) {
      setElementRowToEdit(false);
    }
    form2.reset();
  };
  const studyAppConfig = { label: 'label', value: 'value' };
  const studyAppOptions = [
    // { label: 'Efficiency Study', value: 1, color: '#f59d0f' },
    { label: 'Role Study', value: 2, color: '#784fc2' },
    { label: 'Activity Study', value: 3, color: '#0ba0ef' },
    // { label: 'Predetermined Study', value: 4, color: '#9CC13B' },
  ];
  const form = useForm({
    defaultValues: {
      name: '',
      elements: [''],
      studyTypes: [2, 3],
      controllingElementID: '',
      groupID: '',
    },
    resolver: yupResolver(addTaskSchema),
  });
  const { register, watch, handleSubmit, formState, reset, getValues, setValue, control } = form;
  const { errors } = formState;
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormProvider)
    name: 'elements', // unique name for your Field Array
  });
  const form2 = useForm({
    defaultValues: {
      // defaultEntry: true,
      name: '',
      unitOfMeasure: '',
      studyTypes: [],
      tasks: [],
      rating: 3,
      type: 2,
      count: false,
      contingencyAllowance: null,
      relaxationAllowance: null,
      categoryID: '',
    },
    resolver: yupResolver(addElementSchema),
  });
  const { register: register2, watch: watch2, formState: formState2, control: control2 } = form2;
  const { errors: errors2 } = formState2;
  const elementValues = watch('elements');
  const rowElements = useMemo(
    () => (elementValues && !!elementValues?.length ? elementValues.map((element, index) => ({ id: element })) : []),
    [elementValues]
  );
  const handleElementDrag = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      // setActiveId(active.id);
      // setOverId(over.id);
      // here we want to do temporary sorting

      const activeTempIndex = elementRows.findIndex((element) => element.id === active.id);
      const overTempIndex = elementRows.findIndex((element) => element.id === over.id);
      const activeIndex =
        sortedElements?.length > 0
          ? sortedElements.findIndex((element) => element.id === active.id)
          : elementRows?.findIndex((element) => element.id === active.id);
      const overIndex =
        sortedElements?.length > 0
          ? sortedElements.findIndex((element) => element.id === over.id)
          : elementRows?.findIndex((element) => element.id === over.id);

      const sortedTempArray = arrayMove(elementRows, activeTempIndex, overTempIndex);
      setElementRows(sortedTempArray);
      const sortedArray =
        sortedElements?.length > 0
          ? arrayMove(sortedElements, activeIndex, overIndex)
          : arrayMove(rowElements, activeIndex, overIndex);

      setSortedElements(sortedArray);
    }
  };

  const cancelTaskReorder = () => {
    setElementRows(rowElements);
    setSortedElements([]);
    // setIsReording(!isReording);
    setTaskIsReording(!isTaskReording);
  };
  const saveTaskReorder = () => {
    setTaskIsReording(() => false);
    if (sortedElements && sortedElements?.length > 0) {
      const newElementIds = sortedElements.map((currentElement) => currentElement?.id);
      setValue('elements', newElementIds);
      setElementRows(() => newElementIds?.map((elem) => ({ id: elem })));
    } else {
      setElementRows(rowElements);
    }
  };
  useEffect(() => {
    setElementRows(rowElements);
  }, [rowElements]);
  const elementOptions = useMemo(
    () =>
      elements &&
      !!elements?.length &&
      elements.map((element) => ({
        label: get(element, 'name'),
        value: get(element, '_id'),
        status: get(element, 'projectSetting.status'),
      })),
    [elements]
  );
  const typeOptions = useMemo(
    () => [
      { label: 'Fixed', value: 1 },
      { label: 'Variable', value: 2 },
    ],
    []
  );
  const showReorderButton = useMemo(() => tasks && tasks?.length > 0, [tasks]);
  const toolbarProps = {
    status: Status,
    onStatusChange,
    isReording,
    hadleReorderClick,
    saveReorder,
    onSave: handleGlobalReorder,
    onCancel: cancelReorder,
    openModal,
    showReorderButton,
    projectID,
  };
  const nestedRowProps = {
    openElementModal,
    setValue: form2.setValue,
    setRowToEdit: setElementRowToEdit,
    rowToEdit: elementRowToEdit,
    setSelectedRowToDelete,
    elementOptions,
    setTaskToUpdate,
    setDeleteModelOpen,
  };
  useEffect(() => {
    setValue('elements', ['']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (values) => {
    if (customerID && projectID && values) {
      const payload = values;
      if (isTaskReording && elementRows && elementRows?.length > 0) {
        payload.elements = elementRows.map((elementRow) => elementRow?.id);
      }
      const filteredPayload = filterEmptyValues(payload);
      if (rowToEdit) {
        dispatch(
          updateTask({
            ...filteredPayload,
            customerID,
            projectID,
            _id: rowToEdit,
            groupID: values?.groupID ? values?.groupID : '',
          })
        )
          .then((response) => {
            if (response.payload?.success) {
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'success',
                  snackbarMessage: get(response, 'payload.message', 'Task Updated Successfully'),
                })
              );
              dispatch(getTasks({ projectID }));
              closeModal();
            } else if (get(response, 'payload.message')) {
              const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'error',
                  snackbarMessage: errorMessage,
                })
              );
            }
          })
          .catch((error) => {
            console.log('error==> ', error);
          });
      } else {
        dispatch(addTask({ ...filteredPayload, customerID, projectID }))
          .then((response) => {
            if (response.payload?.success) {
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'success',
                  snackbarMessage: get(response, 'payload.message', 'Task Added Successfully'),
                })
              );
              dispatch(getTasks({ projectID }));
              closeModal();
            } else if (get(response, 'payload.message')) {
              const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'error',
                  snackbarMessage: errorMessage,
                })
              );
            }
          })
          .catch((error) => {
            console.log('error==> ', error);
          });
      }
    }
  };
  const controllingElement = watch('controllingElementID');
  const handleControllElementChange = (event, index) => {
    if (event.target.checked) {
      setValue('controllingElementID', elementValues[index]);
    }
  };
  const onElementSubmit = (values) => {
    if (values) {
      if (get(values, 'rating')) {
        values.rating = parseInt(values.rating, 10);
      }
      if (get(values, 'type')) {
        values.type = parseInt(values.type, 10);
      }
      if (customerID && projectID) {
        if (elementRowToEdit) {
          dispatch(editElement({ ...values, defaultEntry: false, _id: elementRowToEdit, customerID, projectID })).then(
            (response) => {
              if (response.payload?.success) {
                closeElementModal();
                dispatch(
                  setSnackbar({
                    snackbarOpen: true,
                    snackbarType: 'success',
                    snackbarMessage: get(response, 'payload.message' || 'User Updated Successfully'),
                  })
                );
                dispatch(getElements({ projectID, defaultEntry: false }));
              } else {
                const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
                dispatch(
                  setSnackbar({
                    snackbarOpen: true,
                    snackbarType: 'error',
                    snackbarMessage: errorMessage,
                  })
                );
              }
            }
          );
        } else {
          const newTasks = values?.tasks?.length
            ? values?.tasks?.map((task) => ({
                ...task,
                elements: task.elements?.length ? [...task.elements, values?.name] : [values?.name],
              }))
            : [];
          // return;
          dispatch(addElement({ ...values, defaultEntry: false, customerID, projectID, tasks: newTasks || values?.tasks }))
            .then((response) => {
              if (response.payload?.success) {
                dispatch(
                  setSnackbar({
                    snackbarOpen: true,
                    snackbarType: 'success',
                    snackbarMessage: get(response, 'payload.message', 'Element Added Successfully'),
                  })
                );
                dispatch(getElements({ projectID, defaultEntry: false }));
                dispatch(getTasks({ projectID }));
                closeElementModal();
              } else if (get(response, 'payload.message')) {
                const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
                dispatch(
                  setSnackbar({
                    snackbarOpen: true,
                    snackbarType: 'error',
                    snackbarMessage: errorMessage,
                  })
                );
              }
            })
            .catch((error) => {
              console.log('error==> ', error);
            });
        }
      }
    }
  };
  const onDeleteElement = async (rowID) => {
    if (rowID && projectID && taskToUpdate) {
      // startLoading();
      const values = { ...taskToUpdate, elements: taskToUpdate?.elements?.filter((element) => element !== rowID) };
      const { currentElement, ...rest } = values;
      const payload = filterEmptyValues(rest);
      dispatch(updateTask(payload))
        .then((response) => {
          if (response.payload?.success) {
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'success',
                snackbarMessage: get(response, 'payload.message', 'Task Updated Successfully'),
              })
            );
            dispatch(getTasks({ projectID: values?.projectID }));
            setDeleteModelOpen(false);
          } else if (get(response, 'payload.message')) {
            const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'error',
                snackbarMessage: errorMessage,
              })
            );
          }
        })
        .catch((error) => {
          console.log('error==> ', error);
        });
    } else {
      console.warn('No Row Id found');
    }
  };
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const handleDragStart = (event) => {
    // Check if the clicked element or one of its ancestors has the class "no-drag"
    if (event.target.closest('.no-drag')) {
      event.stopPropagation(); // Stop the drag event from propagating
    }
  };

  return (
    <Box>
      <ConfirmDialog
        title="Remove"
        open={isDeleteModelOpen}
        setOpen={setDeleteModelOpen}
        selectedField={selectedRowToDelete}
        onConfirm={onDeleteElement}
        confirmText="Remove"
        isLoading={isAdding}
      >
        <Typography variant="body1">
          Are you sure you want to <b>remove</b> this?
        </Typography>
      </ConfirmDialog>
      <ModalComponent
        persist
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '600px !important',
            // maxHeight: 'calc(100vh - 80px)',
          },
        }}
        title={rowToEdit ? 'Edit Task' : 'Create Task'}
        // title="Add Area"
        open={isModalopen}
        onClose={closeModal}
        DialogActions={
          <DialogActions sx={{ mt: 2, px: 0, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <ThemeButton type="button" onClick={closeModal} color="error" variant="outlined">
              Cancel
            </ThemeButton>
            <ThemeButton loading={isAdding} onClick={handleSubmit(onSubmit)} variant="contained">
              Save Changes
            </ThemeButton>
          </DialogActions>
        }
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mt={1}>
            <Grid columnSpacing={3} rowSpacing={2} container justifyContent="space-between">
              <Grid item lg={12} sm={12} xs={12} md={12}>
                <FormInput
                  label="Name"
                  placeholder="Enter Name"
                  InputProps={{
                    ...register('name'),
                  }}
                  error={!!errors?.name}
                  helperText={errors?.name && errors?.name?.message}
                />
              </Grid>
              <Grid item lg={12} sm={12} xs={12} md={12}>
                <CheckboxGroup
                  control={control}
                  label="Study Types"
                  name="studyTypes"
                  options={studyAppOptions}
                  config={studyAppConfig}
                />
              </Grid>
              <Grid mt={1} item lg={12} sm={12} xs={12} md={12}>
                <label style={{ fontSize: '0.8600rem' }}>Groups</label>
                <CustomSelect
                  name="groupID"
                  control={control}
                  options={[{ label: 'No Group', value: '' }, ...groupOptions]}
                  error={!!errors?.groupID}
                  helperText={errors?.groupID && errors?.groupID?.message}
                  label="No Group"
                />
              </Grid>
              <Grid item lg={12} sm={12} xs={12} md={4}>
                <Stack mb={2} justifyContent="space-between" direction="row">
                  <Stack direction="column">
                    <Typography variant="subtitle2">Element</Typography>
                    {get(errors, 'elements.root.message') && (
                      <Typography sx={{ color: 'error.main' }} variant="caption">
                        {get(errors, 'elements.root.message')}
                      </Typography>
                    )}
                  </Stack>
                  <Stack direction="column">
                    <Typography variant="subtitle2">Controlling Element</Typography>
                    {get(errors, 'controllingElementID.message') && (
                      <Typography sx={{ color: 'error.main' }} variant="caption">
                        {get(errors, 'controllingElementID.message')}
                      </Typography>
                    )}
                  </Stack>
                </Stack>
                {!isTaskReording ? (
                  <ThemeButton
                    sx={{ mb: 2, display: elementValues && elementValues?.length > 1 ? 'block' : 'none' }}
                    onClick={() => setTaskIsReording(true)}
                    // sx={{ width: { sm: '40%', xs: '30%' } }}
                    variant="outlined"
                    size="small"
                  >
                    Re-Order Elements
                  </ThemeButton>
                ) : (
                  <Stack sx={{ mb: 2 }} direction="row" spacing>
                    <ThemeButton size="small" sx={{ px: 3 }} onClick={saveTaskReorder} variant="contained">
                      save
                    </ThemeButton>
                    <ThemeButton size="small" onClick={cancelTaskReorder} variant="outlined" color="error">
                      Cancel
                    </ThemeButton>
                  </Stack>
                )}
                <Box>
                  {isTaskReording ? (
                    <DndContext
                      modifiers={[restrictToFirstScrollableAncestor, restrictToVerticalAxis]}
                      sensors={sensors}
                      collisionDetection={closestCenter}
                      onDragEnd={handleElementDrag}
                    >
                      <SortableContext items={elementRows} strategy={verticalListSortingStrategy}>
                        {elementRows.map((currentField, index) => (
                          <SortableElement
                            onMouseDown={handleDragStart}
                            sx={{ cursor: 'grab !important' }}
                            id={currentField.id}
                            key={`reordering_${currentField.id}`} // Unique key
                          >
                            <Stack sx={{ display: isTaskReording ? 'block' : 'none' }} width="100%" direction="column" gap mb={1}>
                              <Typography variant="caption">Element {index + 1}</Typography>
                              <Stack gap={2} direction="row" alignItems="center">
                                <CustomSelect
                                  disabled
                                  style={{ pointerEvents: 'auto', cursor: 'grab' }}
                                  className="no-drag"
                                  name={`elements.${index}`}
                                  control={control}
                                  options={
                                    elementOptions && elementOptions?.length > 0
                                      ? elementOptions?.map((element) =>
                                          element?.status !== 'active'
                                            ? { ...element, label: `Archived- ${element?.label}` }
                                            : element
                                        )
                                      : []
                                  }
                                  label="Select an Element"
                                  width="95%"
                                  isDisabled={(option) => {
                                    if (!option?.value) {
                                      return false;
                                    }
                                    if (elementValues?.includes(option?.value)) {
                                      return true;
                                    }
                                    if (option?.status !== 'active') {
                                      return true;
                                    }
                                    return false;
                                  }}
                                  error={!isEmpty(get(errors, `elements.${index}`))}
                                  helperText={
                                    has(errors, `elements[${index}].message`) && get(errors, `elements.${index}.message`)
                                  }
                                  maxCustomWidth={400}
                                />
                                <ThemeSwitch
                                  checked={!!controllingElement && controllingElement === currentField.id}
                                  onChange={(event) => handleControllElementChange(event, index)}
                                  width="90%"
                                  name="controllingElementID"
                                  disabled={!elementValues[index]}
                                />
                                <IconButton disabled={fields?.length <= 1} onClick={() => remove(index)} sx={{ mt: 0 }}>
                                  <CancelIcon color="error" />
                                </IconButton>
                              </Stack>
                            </Stack>
                          </SortableElement>
                        ))}
                      </SortableContext>
                    </DndContext>
                  ) : (
                    <>
                      {fields?.map((currentField, index) => (
                        <Stack key={`static_${elementValues[index]}`} width="100%" direction="column" gap mb={1}>
                          <Typography variant="caption">Element {index + 1}</Typography>
                          <Stack gap={2} direction="row" alignItems="center">
                            <CustomSelect
                              style={{ pointerEvents: 'auto' }}
                              className="no-drag"
                              name={`elements.${index}`}
                              control={control}
                              maxCustomWidth={400}
                              options={
                                elementOptions && elementOptions?.length > 0
                                  ? elementOptions?.map((element) =>
                                      element?.status !== 'active'
                                        ? { ...element, label: `Archived- ${element?.label}` }
                                        : element
                                    )
                                  : []
                              }
                              label="Select an Element"
                              width="95%"
                              key={`static_${elementValues[index]}`}
                              isDisabled={(option) => {
                                if (!option?.value) {
                                  return false;
                                }
                                if (elementValues?.includes(option?.value)) {
                                  return true;
                                }
                                if (option?.status !== 'active') {
                                  return true;
                                }
                                return false;
                              }}
                              error={!isEmpty(get(errors, `elements.${index}`))}
                              helperText={has(errors, `elements[${index}].message`) && get(errors, `elements.${index}.message`)}
                            />
                            <ThemeSwitch
                              checked={!!controllingElement && controllingElement === elementValues[index]}
                              onChange={(event) => handleControllElementChange(event, index)}
                              width="90%"
                              name="controllingElementID"
                              disabled={!elementValues[index]}
                            />
                            <IconButton disabled={fields?.length <= 1} onClick={() => remove(index)} sx={{ mt: 0 }}>
                              <CancelIcon color="error" />
                            </IconButton>
                          </Stack>
                        </Stack>
                      ))}
                    </>
                  )}
                </Box>

                {/* <Typography>{errors?.elements && errors.elements?.message}</Typography> */}
              </Grid>
              <Grid item lg={12} sm={12} xs={12} md={12}>
                <Stack direction="row" width="100%" justifyContent="flex-end">
                  <ThemeButton onClick={() => append('')}>Add Element</ThemeButton>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </form>
      </ModalComponent>
      <ModalComponent
        persist
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '1200px !important',
          },
        }}
        title={elementRowToEdit ? 'Edit Element' : 'Create Element'}
        open={isElementModalopen}
        onClose={closeElementModal}
      >
        <form onSubmit={form2.handleSubmit(onElementSubmit)}>
          <Grid rowSpacing={1} columnSpacing={3} container>
            <Grid className="left-side" item xs={12} md={6} lg={6}>
              <Grid rowSpacing={1} columnSpacing={1} container>
                <Grid sx={{ '& .MuiFormControl-root': { marginBottom: 0 } }} className="name" item xs={12} md={12} lg={12}>
                  <FormInput
                    width="90%"
                    label="Name"
                    placeholder="e.g Greet Customer"
                    disabled={elementRowToEdit}
                    InputProps={{
                      ...register2('name'),
                    }}
                    error={!!errors2?.name}
                    helperText={errors2?.name && errors2?.name?.message}
                  />
                </Grid>
                <Grid className="type" item xs={12} md={12}>
                  <Box
                    sx={{
                      // '& .css-dmmspl-MuiFormGroup-root': { mt: 0.5 },
                      '& .css-14r9679-MuiFormLabel-root.Mui-focused': { color: '#212B36' },
                      '& .css-14r9679-MuiFormLabel-root': { color: '#212B36', fontWeight: '600' },
                      '& .MuiFormLabel-root .Mui-error ': { color: '#212B36', fontWeight: '600' },
                      mt: 1,
                    }}
                  >
                    <GroupCheckbox error={!!errors2?.type} helperText={errors2?.type && errors2?.type?.message} label="Type">
                      <FormRadioGroup row fontSize={18} options={typeOptions} control={control2} name="type" />
                    </GroupCheckbox>
                  </Box>
                </Grid>
                <Grid className="study-types" item xs={12} md={12}>
                  <CheckboxGroup
                    row
                    control={control2}
                    label="Study Types"
                    name="studyTypes"
                    options={studyTypeOptions.filter((op) => op.value !== 1)}
                    config={studyTypeConfig}
                  />
                </Grid>
                <Grid className="rating" item xs={12} md={12}>
                  <Box
                    sx={{
                      '& .css-dmmspl-MuiFormGroup-root': { mt: 0.5 },
                      '& .css-14r9679-MuiFormLabel-root.Mui-focused': { color: '#212B36' },
                      '& .css-14r9679-MuiFormLabel-root': { color: '#212B36', fontWeight: '600' },
                      '& .MuiFormLabel-root .Mui-error ': { color: '#212B36', fontWeight: '600' },
                      width: '80%',
                    }}
                  >
                    <GroupCheckbox
                      error={!!errors2?.rating}
                      helperText={errors2?.rating && errors2?.rating?.message}
                      label="Default Rating"
                    >
                      <FormRadioGroup fontSize={18} options={ratingOptions} control={control2} name="rating" />
                    </GroupCheckbox>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="right-side" item xs={12} md={6} lg={6}>
              <Grid rowSpacing={2} columnSpacing={1} container>
                <Grid className="count" item xs={12} md={12}>
                  <ThemeSwitch
                    description="Relevant only on Role and Activity studies"
                    label="Count"
                    control={control2}
                    name="count"
                    width="90%"
                  />
                </Grid>
                <Grid className="unit-of-measures" item xs={12} md={12}>
                  <FormInput
                    label="Unit of Measure"
                    placeholder="e.g Per item"
                    // fontSize="0.9375rem"
                    // fontWeight={600}
                    InputProps={{
                      ...register2('unitOfMeasure'),
                    }}
                    width="90%"
                    error={!!errors2?.unitOfMeasure}
                    helperText={errors2?.unitOfMeasure && errors2?.unitOfMeasure?.message}
                  />
                </Grid>
                <Grid className="category" item lg={12} sm={12} xs={12} md={12}>
                  <label id="category-select" style={{ fontSize: '0.8600rem' }}>
                    Category
                  </label>
                  <CustomSelect
                    width="95%"
                    name="categoryID"
                    // InputProps={{ ...register2('customerID') }}
                    control={control2}
                    options={categoryOptions}
                    label="Select a category"
                    // value={watch('customerID')}
                    error={!!errors2?.categoryID}
                    helperText={errors2?.categoryID && errors2?.categoryID?.message}
                    // placeholder=Chooseexpiration date"
                  />
                </Grid>
                <Grid item xs={12} lg={6} md={6}>
                  <FormInput
                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                    type="number"
                    label="Relaxation Allowance"
                    placeholder="e.g 1"
                    // fontSize="0.9375rem"
                    // fontWeight={600}
                    InputProps={{
                      ...register2('relaxationAllowance'),
                    }}
                    width="90%"
                    error={!!errors2?.relaxationAllowance}
                    helperText={errors2?.relaxationAllowance && errors2?.relaxationAllowance?.message}
                  />
                </Grid>
                <Grid item xs={12} lg={6} md={6}>
                  <FormInput
                    type="number"
                    label="Contingency Allowance"
                    placeholder="e.g 1"
                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                    // fontSize="0.9375rem"
                    // fontWeight={600}
                    InputProps={{
                      ...register2('contingencyAllowance'),
                    }}
                    width="90%"
                    error={!!errors2?.contingencyAllowance}
                    helperText={errors2?.contingencyAllowance && errors2?.contingencyAllowance?.message}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <DialogActions sx={{ mt: 2, px: 0, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <ThemeButton type="button" onClick={closeElementModal} color="error" variant="outlined">
              Cancel
            </ThemeButton>
            <ThemeButton loading={addElementLoading} type="submit" variant="contained">
              {elementRowToEdit ? 'Save Changes' : 'Create Element'}
            </ThemeButton>
          </DialogActions>
        </form>
      </ModalComponent>
      <DataTable
        headCells={headCells}
        rows={tasksRows}
        nestedRowKey={Types.nestedRowTypes.TASKS}
        onRowStatusChange={onRowStatusChange}
        // handleSelectionClick={handleSelectionClick}
        // maxHeight={550}
        onEditClick={onEditClick}
        isLoading={isLoading}
        onRowDelete={onDelete}
        filterKeys={['name']}
        customToolbar={Types.toolbarTypes.TASKS}
        toolbarProps={toolbarProps}
        nestedRowProps={nestedRowProps}
        handleDragEnd={handleDragEnd}
        singleNestedRow
        defaultRowsPerPage={10}
      />
      <DevTool control={control} />
    </Box>
  );
};

export default TasksListPage;
TasksListPage.propTypes = {
  projectID: PropTypes.string,
  customerID: PropTypes.string,
};
