/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable arrow-body-style */
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress, Grid, IconButton, Stack, TableCell, TableRow, Typography } from '@mui/material';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import FormInput from '../../components/formComponents/FormInput';
import Iconify from '../../components/Iconify';
import CustomSelect from '../../components/select';
import { BootstrapTooltip } from '../../components/ThemeTooltip';
import { notesSchema, studiesSchema } from '../../utils/schema';
// import { updateObservation, updateStudy } from './studiesSlice';
import { setSnackbar } from '../../components/snackbar/snackbarSlice';
import { updateObservation } from '../studiesPage/studiesSlice';

const NotesNestedRow = ({
  row,
  closeNestedRow,
  setNestedCollapsedRows,
  handleOpenBackdrop,
  handleCloseBackdrop,
  nestedRowProps,
}) => {
  const { updateStudyLoading } = useSelector((state) => state.studies);
  const dispatch = useDispatch();
  const form = useForm({
    defaultValues: {
      areaID: get(row, 'areaID', '') || '',
      elementID: get(row, 'elementID', '') || '',
      taskID: get(row, 'taskID', '') || '',
      roleID: get(row, 'roleID', '') || '',
      rating: get(row, 'rating', null),
      frequency: get(row, 'frequency', null),
      notes: get(row, 'notes', '') || '',
      duration: get(row, 'duration', 0) || 0,
    },
    resolver: yupResolver(notesSchema),
  });
  const { register, watch, handleSubmit, formState, reset, getValues, setValue, control } = form;
  const { errors } = formState;
  const { id: projectID } = useParams();
  const onSubmit = (values) => {
    const { areaID, elementID, taskID, roleID, ...rest } = values;
    const payload = { ...rest };
    if (row?.studyType && row?.studyType === 1 && row?.roundID) {
      payload.roundID = row?.roundID;
      payload.duration = row?.duration;
      payload.roundDuration = row?.roundDuration;
    }
    if (row?.studyType && row?.studyType === 1 && (values?.duration === 0 || row?.duration === 0)) {
      payload.duration = 0;
    }
    if (row?.studyType && row?.studyType === 1 && (values?.roundDuration === 0 || row?.roundDuration === 0)) {
      payload.roundDuration = 0;
    }
    if (areaID) {
      payload.areaID = areaID;
    }
    if (elementID) {
      payload.elementID = elementID;
    }
    if (taskID) {
      payload.taskID = taskID;
    }
    if (roleID) {
      payload.roleID = roleID;
    }
    if (get(row, '_id')) {
      dispatch(
        updateObservation({
          _id: get(row, '_id'),
          studyType: get(row, 'studyType'),
          studyID: get(row, 'studyID'),
          projectID,
          ...payload,
        })
      )
        .then((response) => {
          if (response.payload?.success) {
            setNestedCollapsedRows([]);
            // nestedRowProps?.fetchStudies();
            nestedRowProps?.fetchNotes();

            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'success',
                snackbarMessage: get(response, 'payload.message', 'Observation Updated Successfully'),
              })
            );
          } else if (get(response, 'payload.message')) {
            const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'error',
                snackbarMessage: errorMessage,
              })
            );
          }
        })
        .catch((error) => {
          console.log('error==> ', error);
        });
    }
  };

  if (row?._id) {
    return (
      <TableRow sx={{ bgcolor: '#f9fafb' }}>
        {/* <TableCell padding="checkbox" /> */}
        <TableCell colSpan={nestedRowProps?.nestedType === 'singleStudy' ? 12 : 13} sx={{ verticalAlign: 'top' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DevTool control={control} />
            <Stack gap={4} direction="row">
              <Stack gap={2} width="70%" direction="row" justifyContent="space-between">
                <Grid container columnSpacing={8} px={8}>
                  <Grid item xs={12} md={4}>
                    <FormInput
                      label="Notes"
                      placeholder="Write your notes"
                      InputProps={{ ...register('notes') }}
                      error={!!errors?.notes}
                      helperText={errors?.notes && errors?.notes?.message}
                    />
                  </Grid>
                </Grid>
              </Stack>
              <Stack alignItems="center" justifyContent="center" direction="row">
                <BootstrapTooltip enterDelay={500} title="Save">
                  {updateStudyLoading ? (
                    <CircularProgress size="1rem" />
                  ) : (
                    <IconButton type="submit" sx={{ p: 0.5, color: 'primary.main' }}>
                      <Iconify icon="tabler:check" />
                    </IconButton>
                  )}
                </BootstrapTooltip>
                <BootstrapTooltip enterDelay={500} title="Cancel">
                  <IconButton onClick={() => closeNestedRow(row)} sx={{ p: 0.5, color: 'error.main' }}>
                    <Iconify icon="tabler:x" />
                  </IconButton>
                </BootstrapTooltip>
              </Stack>
            </Stack>
          </form>
        </TableCell>
      </TableRow>
    );
  }
  return (
    <TableRow sx={{ bgcolor: '#f9fafb' }}>
      <TableCell colSpan={12}>
        <Typography sx={{ ml: 5, color: '#4444448f' }} variant="subtitle2">
          No Elements found
        </Typography>
      </TableCell>
    </TableRow>
  );
};

NotesNestedRow.propTypes = {
  row: PropTypes.any.isRequired,
  closeNestedRow: PropTypes.func,
  handleCloseBackdrop: PropTypes.func,
  handleOpenBackdrop: PropTypes.func,
  setNestedCollapsedRows: PropTypes.func,
  nestedRowProps: PropTypes.object,
};

export default NotesNestedRow;
