import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import axiosInstance from '../../../utils/axiosInstance';
// import { projects } from './data';

const initialState = {
  isLoading: false,
  addAreaLoading: false,
  areas: [],
  isAreaDownloading: false,
};
const getResponseMessage = (successCount, rejectedCount) => {
  if (rejectedCount <= 0) {
    return 'All the areas are successfully updated';
  }
  if (successCount <= 0) {
    return 'Unfortunately, all areas failed to update';
  }
  return `${successCount} areas were successfully edited, while ${rejectedCount} were not accepted`;
};
export const getAreas = createAsyncThunk('areas/getAreas', async (payload, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'areas/get',
      method: 'POST',
      data: payload,
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
export const massEditAreas = createAsyncThunk('areas/massEditAreas ', async (payload, { rejectWithValue }) => {
  const baseUrl = 'areas/update/';
  let successCount = 0;
  let rejectedCount = 0;
  let responseMessage = '';

  const responses = await Promise.allSettled(
    payload.map((data) => {
      const { _id, ...rest } = data;
      return axiosInstance({
        url: `${baseUrl}${data?._id}`,
        method: 'PATCH',
        data: rest,
      });
    })
  );

  responses.forEach((res) => {
    if (res.status === 'fulfilled') {
      if (res.value?.status === 200) {
        successCount += 1;
      } else {
        rejectedCount += 1; // Increment for non-200 responses
      }
    } else {
      // Handle the rejected promise here
      rejectedCount += 1; // Increment for failed requests
      console.log('Error:', res.reason); // Log the reason for rejection
    }
  });

  responseMessage = getResponseMessage(successCount, rejectedCount);
  return { successCount, rejectedCount, message: responseMessage };
});
export const addArea = createAsyncThunk('areas/addArea', async (data, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'areas/add',
      method: 'POST',
      data,
    });
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
export const downloadArea = createAsyncThunk('areas/downloadArea', async (data, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'areas/download',
      method: 'POST',
      data,
    });
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
export const updateArea = createAsyncThunk('area/updateArea', async (data, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: `areas/update/${data?._id}`,
      method: 'PATCH',
      data,
    });
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});

export const areasSlice = createSlice({
  name: 'areas',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAreas.pending, (state, action) => {
      state.isLoading = true;
      state.areas = [];
    });
    builder.addCase(getAreas.fulfilled, (state, action) => {
      state.isLoading = false;
      state.areas = action.payload?.data;
    });
    builder.addCase(getAreas.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(addArea.pending, (state, action) => {
      state.addAreaLoading = true;
    });
    builder.addCase(addArea.fulfilled, (state, action) => {
      state.addAreaLoading = false;
    });
    builder.addCase(addArea.rejected, (state, action) => {
      state.addAreaLoading = false;
    });
    builder.addCase(updateArea.pending, (state, action) => {
      state.addAreaLoading = true;
    });
    builder.addCase(updateArea.fulfilled, (state, action) => {
      state.addAreaLoading = false;
    });
    builder.addCase(updateArea.rejected, (state, action) => {
      state.addAreaLoading = false;
    });
    builder.addCase(downloadArea.pending, (state, action) => {
      state.isAreaDownloading = true;
    });
    builder.addCase(downloadArea.fulfilled, (state, action) => {
      state.isAreaDownloading = false;
    });
    builder.addCase(downloadArea.rejected, (state, action) => {
      state.isAreaDownloading = false;
    });
  },
});

export default areasSlice.reducer;
