import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import axiosInstance from '../../utils/axiosInstance';

const initialState = {
  tasks: [],
  isLoading: false,
  isAdding: false,
  isReorderLoding: false,
  isTaskDownloading: false,
};
const getResponseMessage = (successCount, rejectedCount) => {
  if (rejectedCount <= 0) {
    return 'All the Tasks are successfully updated';
  }
  if (successCount <= 0) {
    return 'Unfortunately, all tasks failed to update';
  }
  return `${successCount} Tasks were successfully edited, while ${rejectedCount} were not accepted`;
};
export const getTasks = createAsyncThunk('tasks/getTasks', async (payload, { rejectWithValue }) => {
  try {
    const url = get(payload, 'status') ? `tasks/get?status=${get(payload, 'status', '')}` : 'tasks/get';
    const response = await axiosInstance({
      url,
      method: 'POST',
      data: payload,
    });
    const data = await response?.data;
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response?.data);
  }
});
export const massEditTasks = createAsyncThunk('tasks/massEditTasks ', async (payload, { rejectWithValue }) => {
  const baseUrl = 'tasks/update/';
  let successCount = 0;
  let rejectedCount = 0;
  let responseMessage = '';

  const responses = await Promise.allSettled(
    payload.map((data) => {
      const { _id, ...rest } = data;
      return axiosInstance({
        url: `${baseUrl}${data?._id}`,
        method: 'PATCH',
        data: rest,
      });
    })
  );

  responses.forEach((res) => {
    if (res.status === 'fulfilled') {
      if (res.value?.status === 200) {
        successCount += 1;
      } else {
        rejectedCount += 1; // Increment for non-200 responses
      }
    } else {
      // Handle the rejected promise here
      rejectedCount += 1; // Increment for failed requests
      console.log('Error:', res.reason); // Log the reason for rejection
    }
  });

  responseMessage = getResponseMessage(successCount, rejectedCount);
  return { successCount, rejectedCount, message: responseMessage };
});
export const downloadTask = createAsyncThunk('tasks/downloadTask', async (data, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'tasks/download',
      method: 'POST',
      data,
    });
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
export const addTask = createAsyncThunk('tasks/addTask', async (data, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'tasks/add',
      method: 'POST',
      data,
    });
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
export const updateTask = createAsyncThunk('tasks/updateTask', async (data, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: `tasks/update/${data?._id}`,
      method: 'PATCH',
      data,
    });
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
export const reorderTasks = createAsyncThunk('tasks/reorder', async (data, { rejectWithValue }) => {
  try {
    const response = await axiosInstance({
      url: 'tasks/reorder',
      method: 'POST',
      data,
    });
    const responseData = await response.data;
    return responseData;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    return rejectWithValue(error.response.data);
  }
});
const taskslice = createSlice({
  name: 'tasks',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getTasks.pending, (state, actions) => {
      state.isLoading = true;
      state.tasks = [];
    });
    builder.addCase(getTasks.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.data && action.payload.data.length > 0) {
        state.tasks = action.payload.data;
      } else {
        state.tasks = [];
      }
    });
    builder.addCase(getTasks.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(reorderTasks.pending, (state, actions) => {
      state.isReorderLoding = true;
    });
    builder.addCase(reorderTasks.fulfilled, (state, action) => {
      state.isReorderLoding = false;
    });
    builder.addCase(reorderTasks.rejected, (state, action) => {
      state.isReorderLoding = false;
    });
    builder.addCase(addTask.pending, (state, actions) => {
      state.isAdding = true;
    });
    builder.addCase(addTask.fulfilled, (state, action) => {
      state.isAdding = false;
    });
    builder.addCase(addTask.rejected, (state, action) => {
      state.isAdding = false;
    });
    builder.addCase(updateTask.pending, (state, actions) => {
      state.isAdding = true;
    });
    builder.addCase(updateTask.fulfilled, (state, action) => {
      state.isAdding = false;
    });
    builder.addCase(updateTask.rejected, (state, action) => {
      state.isAdding = false;
    });
    builder.addCase(downloadTask.pending, (state, actions) => {
      state.isTaskDownloading = true;
    });
    builder.addCase(downloadTask.fulfilled, (state, action) => {
      state.isTaskDownloading = false;
    });
    builder.addCase(downloadTask.rejected, (state, action) => {
      state.isTaskDownloading = false;
    });
  },
  reducers: {
    updateTasks: (state, action) => {
      if (action.payload && action.payload?.length > 0) {
        state.tasks = action.payload;
      }
    },
  },
});
export const { updateTasks } = taskslice.actions;
export default taskslice.reducer;
