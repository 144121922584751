import styled from '@emotion/styled';
import { OutlinedInput, alpha } from '@mui/material';
import dayjs from 'dayjs';
import { get, has } from 'lodash';

export const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
};

export const emptyRows = (page, rowsPerPage, arrayLength) => (page ? Math.max(0, (1 + page) * rowsPerPage - arrayLength) : 0);

export const descendingComparator = (a, b, orderBy, filterConfig) => {
  const aValue = a[orderBy];
  const bValue = b[orderBy];

  // Handle null values
  if (aValue === null) {
    return 1;
  }

  if (b[orderBy] === null) {
    return -1;
  }

  // Handle case-insensitive string comparison
  if (typeof aValue === 'string' && typeof bValue === 'string') {
    if (filterConfig && filterConfig.sortType === 'date') {
      const dateA = dayjs(aValue, 'DD/MM/YYYY');
      const dateB = dayjs(bValue, 'DD/MM/YYYY');
      return dateB - dateA;
    }

    const aLower = aValue.toLowerCase();
    const bLower = bValue.toLowerCase();

    if (bLower < aLower) {
      return -1;
    }
    if (bLower > aLower) {
      return 1;
    }
  } else {
    // Compare non-string values directly
    if (bValue < aValue) {
      return -1;
    }
    if (bValue > aValue) {
      return 1;
    }
  }

  return 0;
};
export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};
export const getComparator = (order, orderBy, filterConfig) =>
  order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy, filterConfig)
    : (a, b) => -descendingComparator(a, b, orderBy, filterConfig);

export const applyFilter = ({
  inputData,
  comparator,
  filterName,
  filterCallback,
  filterKeys,
  customerFilters,
  filterColumns,
  filterBy,
  sortBy,
  sortOrder,
  fromDate,
  toDate,
  filterConfig,
}) => {
  const { Industry = '', Sector = '' } = customerFilters || {};
  const stabilizedThis = inputData.map((el, index) => [el, index]);
  if (sortBy && sortOrder) {
    comparator = getComparator(sortOrder, sortBy, filterConfig);
  }
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  inputData = stabilizedThis.map((el) => el[0]);
  if (toDate || fromDate) {
    const selectedFrom = dayjs(fromDate);
    const selectedTo = dayjs(toDate);
    const filteredByDateData = inputData.reduce((acc, project) => {
      const startDate = project?.startDate && dayjs(project.startDate);
      const endDate = project?.endDate && dayjs(project.endDate);
      if (toDate && fromDate) {
        // if (startDate?.isAfter(selectedFrom) && endDate?.isBefore(selectedTo)) {
        if (startDate?.isAfter(selectedFrom) && endDate?.isBefore(selectedTo)) {
          acc.push(project);
        }
      } else if (!toDate) {
        if (startDate?.isAfter(selectedFrom)) {
          acc.push(project);
        }
      } else if (!fromDate) {
        if (endDate?.isBefore(selectedTo)) {
          acc.push(project);
        }
      }
      return acc;
    }, []);
    inputData = filteredByDateData;
  }

  if (filterName || filterName === '') {
    if (filterKeys?.length > 0) {
      inputData = inputData?.filter((user) =>
        filterKeys.some((filt) =>
          get(user, filt) ? get(user, filt)?.toString().toLowerCase().indexOf(filterName.toLowerCase()) !== -1 : filterName === ''
        )
      );
    } else if (filterCallback) {
      inputData = inputData.filter((user) => filterCallback(user, filterName));
    } else {
      console.warn('For Developer => No filterkeys or filterCallback has been passed');
    }
  }
  if (Sector) {
    inputData = inputData.filter(
      // eslint-disable-next-line eqeqeq
      (user) => user.sector == Sector
    );
  }
  if (Industry) {
    inputData = inputData.filter((user) => user.industry === Industry);
  }

  const doesContains = (columns, key) => {
    if (key && typeof key === 'object') {
      return key.some((element) => columns.includes(element));
    }
    return columns.includes(key);
  };
  const compareValues = (rowValue, filterValue) => {
    if (typeof rowValue === 'string') {
      // Apply toLowerCase for string comparison
      // return rowValue.toLowerCase().includes(typeof filterValue === 'string' ? filterValue.toLowerCase() : filterValue);
      return rowValue === filterValue;
    }
    if (typeof rowValue === 'number') {
      // Compare directly for numeric values
      return rowValue === filterValue; // Assumes filterValue is a string that represents a number
    }
    // Direct comparison for other types (boolean, date, etc.)
    return rowValue === filterValue;
  };
  // const isActiveFilter = (column) => {
  //   if(filterConfig?.filters)
  // }
  const filterWise = (filterValue, key) => {
    if (key && typeof key === 'object') {
      return key.some((element) => compareValues(element, filterValue));
    }
    return compareValues(key, filterValue);
    // return key.toString().toLowerCase().includes(filterValue.toLowerCase());

    // row[column].toString().toLowerCase().includes(filterValue.toLowerCase())
  };

  // if (filterBy && inputData?.length) {
  //   inputData =
  //     filterColumns?.length > 0
  //       ? inputData.filter((user) =>
  //           Object.prototype.hasOwnProperty.call(user, filterBy) ? doesContains(filterColumns, user[filterBy]) : true
  //         )
  //       : inputData;
  // }
  if (filterConfig && filterConfig?.filters && Object.keys(filterConfig?.filters).length > 0) {
    inputData = inputData.filter((row) =>
      Object.keys(filterConfig.filters).every((column) => {
        const filterValues = filterConfig.filters[column];
        if (!filterValues || filterValues.length === 0) return true;

        // Check if the row value matches any of the filter values
        return filterValues.some((filterValue) =>
          row[column] || row[column] === 0 ? filterWise(filterValue, row[column]) : false
        );
      })
    );
  }
  return inputData;
};
// row[column].toString().toLowerCase().includes(filterValue.toLowerCase())
export const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  height: 46,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));
