/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable arrow-body-style */
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Chip, DialogActions, Grid, IconButton, InputAdornment, Typography } from '@mui/material';
import { get, startCase } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ConfirmDialog from '../../components/ConfirmDialog';
import Iconify from '../../components/Iconify';
import ModalComponent from '../../components/ModalComponent';
import { ThemeButton } from '../../components/button';
import GroupCheckbox from '../../components/formComponents/FormCheckbox';
import FormInput from '../../components/formComponents/FormInput';
import FormRadioGroup from '../../components/formComponents/formRadioGroup';
import CustomSelect from '../../components/select';
import { setSnackbar } from '../../components/snackbar/snackbarSlice';
import StatusIndicator from '../../components/status';
import DataTable from '../../components/table';
import { Types } from '../../types';
import { getUserPermissionLabel } from '../../utils';
import axiosInstance from '../../utils/axiosInstance';
import { addAdminUserSchema, editAdminUserSchema } from '../../utils/schema';
import { addAdminUser, editAdminUser } from '../user/admin/adminUserSlice';

const headCells = [
  {
    id: 'fullName',
    label: 'Name',
    // disablePadding: true,
    align: 'left',
    // pl: 4,
    minWidth: 200,
    titleCase: true,
  },
  {
    id: 'email',
    label: 'Email',
    width: '20%',
  },
  {
    id: 'userPermission',
    label: 'User Permissions ',
  },
  {
    id: 'status',
    label: 'Status',
    renderElement: (cell) => <StatusIndicator cell={cell} />,
    width: '15%',
  },
];

const AdminUsers = ({ adminUsers, isLoading, fetchAdminUsers, isModalopen, setisModalopen, customerOptions }) => {
  const params = useParams();
  const [rowToEdit, setRowToEdit] = useState('');
  const { isUserAdding } = useSelector((state) => state.adminUser);
  const form = useForm({
    defaultValues: {
      name: '',
      lastname: '',
      email: '',
      password: '',
      internalEmployee: false,
      canBeUsedByCustomers: [],
      customerID: params?.customerID,
      permission: 1,
    },
    resolver: rowToEdit ? yupResolver(editAdminUserSchema) : yupResolver(addAdminUserSchema),
  });
  const { register, watch, handleSubmit, formState, reset, getValues, setValue, control } = form;
  const { errors } = formState;

  // const [isModalopen, setisModalopen] = useState(false);
  const regularUsers = useMemo(
    () =>
      adminUsers &&
      adminUsers?.reduce(
        (acc, user) => {
          const fullUser = {
            ...user,
            fullName: `${startCase(user?.name)} ${startCase(user?.lastname)}`,
            userPermission: get(user, 'permission') && getUserPermissionLabel(user?.permission),
          };
          if (user?.status === 'active') {
            acc.active.push(fullUser);
          } else if (user?.status === 'archive') {
            // acc.archieved.push(customer);
            acc.archive.push(fullUser);
          }
          acc.default.push(fullUser);
          return acc;
        },
        { active: [], archive: [], default: [] }
      ),
    [adminUsers]
  );
  const { DEFAULT, ACTIVE, ARCHIVED } = Types;
  const [Status, setStatus] = useState(ACTIVE);
  const [showPassword, setShowPassword] = useState(false);
  const [allStatusModelOpened, setAllStatusModelOpened] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [statusToChange, setStatusToChange] = useState('');
  const [userRows, setUserRows] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (regularUsers) {
      if (Status === DEFAULT) {
        setUserRows(regularUsers?.default);
      } else if (Status === ARCHIVED) {
        setUserRows(regularUsers?.archive);
      } else if (Status === ACTIVE) {
        setUserRows(regularUsers?.active);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularUsers]);

  const onStatusChange = (value) => {
    if (value) {
      setStatus(value);
      if (value === DEFAULT) {
        setUserRows(regularUsers.default);
      } else if (value === ACTIVE) {
        setUserRows(regularUsers.active);
      } else if (value === ARCHIVED) {
        setUserRows(regularUsers.archive);
      }
    }
  };
  const getStatusPayload = (selectedIds, status) => {
    const fetchUrl = 'users/update/status';
    const payload = {
      userIDs: selectedIds,
      status,
    };
    return {
      fetchUrl,
      payload,
    };
  };
  const getRemovePayload = (selectedIds) => {
    const deleteUrl = `users/delete/${selectedIds[0]}`;
    const deletePayload = {
      userIDs: selectedIds,
    };
    return {
      deleteUrl,
      deletePayload,
    };
  };
  const fetchData = async () => fetchAdminUsers();
  const toolbarProps = {
    status: Status,
    onStatusChange,
    placeholder: 'Search user name',
    getStatusPayload,
    fetchData,
    getRemovePayload,
  };
  const openModal = () => setisModalopen(true);
  const closeModal = () => {
    setisModalopen(() => false);
    // setisModalopen(false);
    reset();
    reset({ permission: '' });
    reset({ canBeUsedByCustomers: [] });
    reset({ customerID: '' });
    setShowPassword(false);
    if (rowToEdit) {
      setTimeout(() => {
        setRowToEdit('');
      }, 200);
    }
  };
  const onSubmit = (data) => {
    if (data) {
      const { canBeUsedByCustomers, ...rest } = data;
      const payload = { ...rest };
      if (isRetimeCustomer && !!canBeUsedByCustomers?.length) {
        payload.canBeUsedByCustomers = canBeUsedByCustomers;
      } else {
        payload.canBeUsedByCustomers = [];
      }
      // role: 1, status: Types.ACTIVE
      if (rowToEdit) {
        dispatch(editAdminUser({ ...payload, role: 2, _id: rowToEdit })).then((response) => {
          if (response.payload?.success) {
            closeModal();
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'success',
                snackbarMessage: get(response, 'payload.message' || 'User Updated Successfully'),
              })
            );
            fetchAdminUsers();
          } else {
            const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'error',
                snackbarMessage: errorMessage,
              })
            );
          }
        });
      } else {
        dispatch(addAdminUser({ ...payload, role: 2 }))
          .then((response) => {
            if (response.payload?.success) {
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'success',
                  snackbarMessage: get(response, 'payload.message', 'User Added Successfully'),
                })
              );
              fetchAdminUsers();
              closeModal();
            } else if (get(response, 'payload.message')) {
              const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'error',
                  snackbarMessage: errorMessage,
                })
              );
            }
          })
          .catch((error) => {
            console.log('error==> ', error);
          });
      }
    }
  };

  // useEffect(() => {
  //   fetchAdminUsers();
  // }, [dispatch]);
  const onEditClick = async (event, row) => {
    // setRowToEdit((row?._id));
    if (row?._id) {
      setRowToEdit((prevRow) => row?._id);
      openModal();
      try {
        const response = await axiosInstance({
          url: `users/get/${row?._id}`,
          method: 'GET',
          data: { id: row?._id },
        });
        if (get(response, 'data.data', '')) {
          setValue('name', get(response, 'data.data.name', ''));
          setValue('lastname', get(response, 'data.data.lastname', ''));
          setValue('email', get(response, 'data.data.email', ''));
          setValue('status', get(response, 'data.data.status', ''));
          setValue('customerID', get(response, 'data.data.customerID', ''));
          setValue('permission', get(response, 'data.data.permission', ''));
          setValue('canBeUsedByCustomers', get(response, 'data.data.canBeUsedByCustomers', []));
          setValue('internalEmployee', get(response, 'data.data.internalEmployee', ''));
        }
      } catch (error) {
        console.log('error -> getUsersById');
      }
    } else {
      console.warn('No Row Id found');
    }
  };

  const onRowStatusChange = async (row, status, startLoading, closeLoading) => {
    if (!status) {
      // also check if status is archive or active then only make api call
      console.log('No status provided');
      return;
    }
    if (row?._id) {
      startLoading();
      try {
        const response = await axiosInstance({
          // url: `users/updatestatus/${row?._id}/${status}`,
          url: 'users/update/status',
          method: 'PATCH',
          data: { userIDs: [row?._id], status },
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: `Successfully ${status === 'active' ? 'Activated' : 'Archived'} User`,
            })
          );
          fetchAdminUsers();
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        closeLoading();
      }
    } else {
      console.warn('No Row Id found');
    }
  };

  const onDelete = async (row, startLoading, closeLoading) => {
    if (row?._id) {
      startLoading();
      try {
        const response = await axiosInstance({
          url: `users/delete/${row?._id}`,
          method: 'DELETE',
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'data.message'),
            })
          );
          fetchAdminUsers();
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        closeLoading();
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  const onConfirmStatusChange = () => {};
  const handleSelectionClick = (selected, type, setSelectedField) => {
    if (selected && !!selected?.length && type) {
      setSelectedUsers(selected);
      setStatusToChange(type);
      setAllStatusModelOpened(() => true);
    }
  };
  const options = [
    { label: 'Project Manager', value: 1 },
    { label: 'View Only/Field User', value: 2 },
    // { label: 'View Only', value: 3 },
  ];
  const internalEmployeeOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
  const customer = watch('customerID');
  const isRetimeCustomer = useMemo(() => customer === '5d4adde9fd1a797f3bee16d6', [customer]);
  useEffect(() => {
    if (isRetimeCustomer) {
      setValue('internalEmployee', false);
    } else {
      setValue('canBeUsedByCustomers', []);
    }
  }, [isRetimeCustomer, setValue]);
  return (
    <div>
      <ConfirmDialog
        title="Change Status"
        open={allStatusModelOpened}
        setOpen={setAllStatusModelOpened}
        confirmText="Change Status"
        onConfirm={onConfirmStatusChange}
      >
        <Typography variant="body1">
          Are you sure you want to <b>{statusToChange === 'active' ? 'activate' : 'archive'}</b> this?
        </Typography>
      </ConfirmDialog>
      <ModalComponent
        persist
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '500px !important',
          },
        }}
        title={rowToEdit ? 'Edit User' : 'Create Admin User'}
        open={isModalopen}
        onClose={closeModal}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mt={1}>
            <Grid columnSpacing={2} container>
              <Grid item lg={6} sm={12} xs={12} md={6}>
                <FormInput
                  label="First Name"
                  placeholder="Enter First Name"
                  InputProps={{
                    ...register('name'),
                  }}
                  error={!!errors?.name}
                  helperText={errors?.name && errors?.name?.message}
                />
              </Grid>
              <Grid item lg={6} sm={12} xs={12} md={6}>
                <FormInput
                  label="Last Name"
                  placeholder="Enter Last Name"
                  InputProps={{ ...register('lastname') }}
                  error={!!errors?.lastname}
                  helperText={errors?.lastname && errors?.lastname?.message}
                />
              </Grid>
              <Grid item lg={6} sm={12} xs={12} md={6}>
                <FormInput
                  label="Email Address"
                  placeholder="Enter Email Address"
                  InputProps={{ ...register('email'), autoComplete: 'off' }}
                  error={!!errors?.email}
                  helperText={errors?.email && errors?.email?.message}
                />
              </Grid>
              <Grid item lg={6} sm={12} xs={12} md={6}>
                <FormInput
                  label={rowToEdit ? 'Change Password' : 'Password'}
                  placeholder="Enter Password"
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    ...register('password'),
                    // type: 'password',
                    autoComplete: 'off',
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                          edge="end"
                        >
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={!!errors?.password}
                  helperText={errors?.password && errors?.password?.message}
                />
              </Grid>
              <Grid item lg={12} sm={12} xs={12} md={12}>
                <label style={{ fontSize: '0.8600rem' }}>Customer</label>
                <CustomSelect
                  name="customerID"
                  control={control}
                  options={customerOptions}
                  label="Select a Customer"
                  error={!!errors?.customerID}
                  helperText={errors?.customerID && errors?.customerID?.message}
                />
              </Grid>
              {isRetimeCustomer && (
                <Grid mt={2} item lg={12} sm={12} xs={12} md={12}>
                  <label style={{ fontSize: '0.8600rem' }}>Can be used by these customers as well: </label>
                  <CustomSelect
                    multiple
                    name="canBeUsedByCustomers"
                    displayEmpty
                    control={control}
                    options={customerOptions}
                    renderValue={(selected) => {
                      if (selected?.length === 0) {
                        return <em>All Customers</em>;
                      }
                      return (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected?.length > 0 &&
                            selected.map((value) => {
                              const renderLabel = customerOptions?.find((opt) => opt?.value === value)?.label;
                              if (renderLabel) {
                                return <Chip key={value} label={renderLabel} />;
                              }
                              return null;
                            })}
                        </Box>
                      );
                    }}
                    label="Select one or more Customer"
                    error={!!errors?.customerID}
                    helperText={errors?.customerID && errors?.customerID?.message}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
          <DialogActions sx={{ mt: 2, px: 0, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <ThemeButton type="button" onClick={closeModal} color="error" variant="outlined">
              Cancel
            </ThemeButton>
            <ThemeButton loading={isUserAdding} type="submit" variant="contained">
              {rowToEdit ? 'Save Changes' : 'Create Admin User'}
            </ThemeButton>
          </DialogActions>
        </form>
      </ModalComponent>
      <DataTable
        isLoading={isLoading}
        headCells={headCells}
        onEditClick={onEditClick}
        // handleArchieve={onConfirmArchieved}
        onRowStatusChange={onRowStatusChange}
        defaultRowsPerPage={5}
        maxHeight={653}
        // rows={groupWiseProject}
        filterKeys={['fullName', 'email']}
        // rows={regularProjects.default}
        customToolbar={Types.toolbarTypes.SUPER_USER}
        toolbarProps={toolbarProps}
        handleSelectionClick={handleSelectionClick}
        rows={userRows}
        stickyHeader
        onRowDelete={onDelete}
      />
      <DevTool control={control} />
    </div>
  );
};

AdminUsers.propTypes = {
  adminUsers: PropTypes.array,
  customerOptions: PropTypes.array,
  isLoading: PropTypes.bool,
  fetchAdminUsers: PropTypes.func,
  isModalopen: PropTypes.bool,
  setisModalopen: PropTypes.func,
};
export default AdminUsers;
