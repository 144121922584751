/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Backdrop,
  Box,
  Card,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  LinearProgress,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  alpha,
} from '@mui/material';
import { jwtDecode } from 'jwt-decode';
import { get, has, startCase } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { TableVirtuoso } from 'react-virtuoso';
import Cookies from 'universal-cookie';
import ConfirmDialog from '../../components/ConfirmDialog';
import Iconify from '../../components/Iconify';
import ProjectToolbar from '../../components/table/projectToolbar';
import { applyFilter, getComparator, visuallyHidden } from '../../components/table/utils';
import { BootstrapTooltip } from '../../components/ThemeTooltip';
import { Types } from '../../types';

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};
const VirtuosoTableComponents = {
  Scroller: React.forwardRef(({ context: { maxHeight, rowsLength }, ...props }, ref) => (
    <TableContainer
      sx={{ maxHeight: maxHeight && maxHeight, minHeight: !(rowsLength > 0) && 100 }}
      component={Paper}
      {...props}
      ref={ref}
    />
  )),
  Table: ({ item: row, context: { dense }, ...props }) => (
    <Table {...props} size={dense ? 'small' : 'medium'} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
  ),
  TableHead,
  TableRow: ({ item: row, context: { isSelected, calculateHeight, handleRowClick, onRowClick }, ...props }) => (
    <TableRow
      {...props}
      hover
      // style={{
      //   height: calculateHeight?.(),
      // }}
      onClick={(event) => handleRowClick && onRowClick(event, row)}
      // onClick={(event) =>
      //   handleRowClick ? handleRowClick(event, row) : nestedRowKey && nestedRowClick(event, row)
      // }
      // role="checkbox"
      // aria-checked={isItemSelected}
      tabIndex={-1}
      key={row?._id}
      // key={`${row?._id} ${index}`}
      selected={isSelected(row?._id)}
      sx={{
        bgcolor: (theme) => Boolean(row?.status === Types.ARCHIVED) && alpha(theme.palette.error.main, 0.05),
        cursor: 'pointer',
        '&.Mui-selected': {
          bgcolor: (theme) => alpha(theme.palette.primary.light, 0.2),
        },
      }}
    />
  ),
  TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
  EmptyPlaceholder: ({ context: { maxHeight, rowsLength, filterName }, ...props }) => (
    <TableRow>
      <TableCell sx={{ borderBottom: '1px solid transparent' }} colSpan={12}>
        {' '}
        <Box alignItems="center" display="flex" justifyContent="center" sx={{ height: 100, width: '100%' }}>
          <Typography variant="subtitle1" textAlign="center">
            {`No data found ${filterName && 'for '} `} <b>{filterName && filterName}</b>
          </Typography>
        </Box>
      </TableCell>
    </TableRow>
  ),
};
const ProjectTable = (props) => {
  const {
    childKey,
    isLoading,
    groupHeadCells,
    headCells,
    rows,
    minWidth,
    // handleSelectionClick,
    filterCallback,
    filterKeys,
    customToolbar,
    nestedRowKey,
    toolbarProps = {},
    maxHeight,
    stickyHeader,
    handleDragEnd,
    defaultRowsPerPage = 5,
    handleRowClick,
    onEditClick,
    onRowStatusChange,
    onRowDelete,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
  } = props;
  const [order, setOrder] = useState('asc');
  const [filterName, setFilterName] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [selected, setSelected] = useState([]);
  const [selectedParent, setSelectedParent] = useState([]);
  const [allStatusModelOpened, setAllStatusModelOpened] = useState(false);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
  const [CollapsedRows, setCollapsedRows] = useState([]);
  const [nestedCollapsedRows, setNestedCollapsedRows] = useState([]);
  const [selectedRowToChangeStatus, setSelectedFieldToChangeStatus] = useState(null);
  const [selectedRowToDelete, setSelectedFieldToDelete] = useState(null);
  const [isStatusModelOpen, setStatusModelOpen] = useState(false);
  const [isDeleteModelOpen, setDeleteModelOpen] = useState(false);
  const [statusType, setStatusType] = useState('');
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [statusToChange, setStatusToChange] = useState('');
  const { isGroupViewToggled } = useSelector((state) => state.project);
  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };
  const dataFiltered = applyFilter({
    inputData: rows || [],
    comparator: getComparator(order, orderBy),
    filterName,
    filterCallback,
    filterKeys,
    fromDate,
    toDate,
    // customerFilters: {
    //   Industry,
    //   Sector
    // }
  });
  const handleStatusButtonClick = (event, row, type) => {
    if (row && row?._id && onRowStatusChange) {
      setStatusType(type);
      setSelectedFieldToChangeStatus(row);
      setStatusModelOpen(true);
    }
  };
  const handleSelectionClick = (selectedUsers, type, setSelectedField) => {
    if (selected && !!selected?.length && type) {
      setStatusToChange(type);
      setAllStatusModelOpened(() => true);
    }
  };
  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };
  const handleDeleteClick = (event, row) => {
    if (row && row?._id && onRowDelete) {
      setSelectedFieldToDelete(row);
      setDeleteModelOpen(true);
    }
  };
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };
  const handleOpenBackdrop = () => {
    setOpenBackdrop(true);
  };
  const onConfirmStatusChange = (selectedField) => {
    if (statusType) {
      // setFilterName('');
      onRowStatusChange(selectedField, statusType, handleOpenBackdrop, handleCloseBackdrop);
      setStatusModelOpen(false);
    }
  };
  const onConfirmDelete = (selectedField) => {
    if (selectedField) {
      onRowDelete(selectedField, handleOpenBackdrop, handleCloseBackdrop);
      setDeleteModelOpen(false);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    if (property !== '') {
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    }
  };

  const visibleRows = useMemo(
    () =>
      // stableSort(rows, getComparator(order, orderBy)).slice(
      rowsPerPage === -1
        ? stableSort(dataFiltered, getComparator(order, orderBy))
        : stableSort(dataFiltered, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rowsPerPage, dataFiltered, order, orderBy, page]
  );
  const rowCount = useMemo(() => rows?.length, [rows]);
  useEffect(() => {
    if (!groupHeadCells || !isGroupViewToggled) {
      if (selected?.length > 0 && visibleRows.every((element) => selected.includes(element?._id))) {
        setIsAllChecked(true);
      } else {
        setIsAllChecked(false);
      }
    } else if (
      selected?.length > 0 &&
      visibleRows.every((groupedElement) =>
        groupedElement?.projects?.length > 0 ? groupedElement?.projects.every((element) => selected.includes(element?._id)) : true
      )
    ) {
      setIsAllChecked(true);
    } else {
      setIsAllChecked(false);
    }
  }, [groupHeadCells, isGroupViewToggled, selected, visibleRows]);

  const handleSelectAllClick = useCallback(
    (event) => {
      if (event.target.checked) {
        if (!rows[0]?._id) {
          return null;
        }
        if (!groupHeadCells || !isGroupViewToggled) {
          const newSelected = rows.map((n) => n._id);
          setSelected(newSelected);
        } else {
          const newSelectedParentRows = [];
          const newSelected = [];
          rows.forEach((row, index) => {
            // row?.projects && !!row?.projects?.length && row?.projects?.map((n) => newSelected.push(n?._id));
            // newSelectedParentRows.push(row?._id);
            if (row && row.projects && row.projects.length) {
              row.projects.forEach((n) => {
                if (n && n._id) {
                  newSelected.push(n._id);
                }
              });
            }
            if (row && row._id && row?.projects && row?.projects?.length > 0) {
              newSelectedParentRows.push(row._id);
            }
            // console.log('newSelectedParentRows--> ', { newSelectedParentRows, index, row });
          });
          setSelected(newSelected);
          setSelectedParent(newSelectedParentRows);
          // setCollapsedRows(newSelectedParentRows);
        }
        return '';
      }
      setSelected([]);
      setSelectedParent([]);
      setCollapsedRows([]);
      return '';
    },
    [groupHeadCells, isGroupViewToggled, rows]
  );
  const handleClearSelection = () => {
    setSelected([]);
    setSelectedParent([]);
    setCollapsedRows([]);
  };
  const handleClick = (event, id, childRows) => {
    event.stopPropagation();
    if (!id) {
      return null;
    }
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (childRows) {
      if (selectedParent.indexOf(id) === -1) {
        setSelectedParent([...selectedParent, id]);
        setCollapsedRows([...CollapsedRows, id]);
        childRows.forEach((childRow, childIndex) => {
          if (selected.indexOf(childRow?._id) === -1) {
            newSelected.push(childRow?._id);
          }
        });
        setSelected([...selected, ...newSelected]);
      } else {
        setCollapsedRows(CollapsedRows.filter((collapsedRow) => collapsedRow !== id));
        setSelectedParent(selectedParent.filter((parentRow) => parentRow !== id));
        setSelected(selected.filter((rowId) => !childRows.some((childRow) => childRow?._id === rowId)));
      }
    } else {
      if (selectedIndex === -1 && !childRows) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0 && !childRows) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1 && !childRows) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0 && !childRows) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      }

      setSelected(newSelected);
    }
    return null;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;
  const isRowCollapsed = (id) => CollapsedRows.indexOf(id) !== -1;
  const isNestedRowCollapsed = (id) => nestedCollapsedRows.indexOf(id) !== -1;
  const emptyRows = useMemo(
    // I am assuming if rowPerPage is -1 then this is all case
    () => (rowsPerPage && !!visibleRows?.length ? Math.max(0, rowsPerPage - visibleRows.length) : rowsPerPage),
    [rowsPerPage, visibleRows]
  );
  const parentRowClick = (event, row) => {
    if (isRowCollapsed(row?._id)) {
      setCollapsedRows(CollapsedRows.filter((collapsedRow) => collapsedRow !== row?._id));
    } else {
      setCollapsedRows((prevRows) => [...prevRows, row?._id]);
    }
    // handleClick(event, row?._id)
  };
  const onRowClick = (event, row) => {
    if (!event.target.closest('button') && !event.target.closest('input')) {
      handleRowClick(event, row);
    }
  };

  const onConfirmAllStatusChange = () => {
    handleClearSelection();
    setAllStatusModelOpened(false);
  };
  const cookies = new Cookies();
  const jwtToken = cookies.get('token');
  const decoded = jwtToken && jwtDecode(jwtToken);
  const role = decoded?.role || '0';
  const isPaginationDisable = useMemo(() => {
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!dataFiltered?.length) {
      if (dataFiltered?.length <= rowsPerPage) {
        // return true;
        return false;
      }
      return false;
    }
    return true;
  }, [dataFiltered, rowsPerPage]);
  const totalRowsHeight = rowsPerPage && rowsPerPage * (dense ? 41 : 61) + (dense ? 37.56 : 57.56); // +1
  const visibleRowsHeight = !!visibleRows?.length && visibleRows.length * (dense ? 41 : 61) + (dense ? 37.56 : 57.56);
  const calculateHeight = () => {
    if (rowsPerPage === -1) {
      // case when ALL option is selected
      if (!!rows?.length && rows.length * (dense ? 41 : 61) > maxHeight) {
        // case when total rows height is greater than maxHeight
        if (visibleRowsHeight < maxHeight) {
          // case when visibleRowsHeight is less than maxHeight (no scrollbar)
          if (visibleRowsHeight > 0) {
            // case for filtered rows and empty spaces
            return maxHeight - visibleRowsHeight;
          }
          // case for no data found
          return maxHeight - visibleRowsHeight - (dense ? 113 : 133) - (dense ? 41 : 61);
        }
        return 0;
      }
      if (visibleRowsHeight > 0) {
        // case for filtered rows and empty spaces
        return maxHeight - visibleRowsHeight;
      }
      // case for no data found
      return maxHeight - visibleRowsHeight - (dense ? 113 : 133) - (dense ? 41 : 61);
      // return maxHeight - visibleRowsHeight; // make codnditions as well
    }
    if (maxHeight && visibleRows?.length <= 0) {
      // case for no data found
      if (totalRowsHeight > maxHeight) {
        // case for no data found and when total rows height is greater than max height
        return maxHeight - visibleRowsHeight - (dense ? 113 : 133) - (dense ? 37.56 : 57.56);
      }
      return rowsPerPage * (dense ? 41 : 61) - (dense ? 113 : 133);
    }
    if (!maxHeight || visibleRowsHeight > totalRowsHeight) {
      return 0;
    }
    if (totalRowsHeight > maxHeight) {
      if (maxHeight && visibleRowsHeight < maxHeight) {
        return maxHeight - visibleRowsHeight;
      }
      return 0;
    }
    // return 0;
    return rowsPerPage * (dense ? 41 : 61) - visibleRows.length * (dense ? 41 : 61);
  };
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  const fixedHeaderContent = () => (
    <TableRow>
      {(role === 1 || role === 2) && (
        <TableCell sx={{ backgroundColor: '#f9fafb' }} padding="checkbox">
          <Checkbox
            size="small"
            color="primary"
            // indeterminate={numSelected > 0 && numSelected < rowCount}
            // checked={rowCount > 0 && numSelected === rowCount}
            checked={isAllChecked}
            onChange={handleSelectAllClick}
            // onClick={(event) => onSelectAllClick(event)}
            disabled={rowCount <= 0}
          />
        </TableCell>
      )}
      {headCells.map((headCell, headIndex) => (
        <TableCell
          sx={{ backgroundColor: '#f9fafb' }}
          style={{ width: headCell.width }}
          key={`${headCell?.id} ${headIndex}`}
          sortDirection={orderBy === headCell.id ? order : false}
        >
          <TableSortLabel
            active={orderBy === headCell?.id}
            direction={orderBy === headCell?.id ? order : 'asc'}
            onClick={createSortHandler(headCell?.id)}
          >
            {headCell?.label || ''}
            {orderBy === headCell?.id ? (
              <Box component="span" sx={visuallyHidden && visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </Box>
            ) : null}
          </TableSortLabel>
        </TableCell>
      ))}
      {(role === 1 || role === 2) && <TableCell sx={{ backgroundColor: '#f9fafb' }}>Actions</TableCell>}
    </TableRow>
  );
  const rowContent = (index, row) => {
    const isItemSelected = isSelected(row?._id);
    const isParentRowSelected = selectedParent.indexOf(row?._id) !== -1;
    const isCollapsed = isRowCollapsed(row?._id);
    const labelId = `enhanced-table-checkbox-${index}`;
    const isArchieved = row?.status ? Boolean(row?.status === Types.ARCHIVED) : false;
    return (
      <>
        {(role === 1 || role === 2) && (
          <TableCell padding="checkbox">
            <Checkbox onClick={(event) => handleClick(event, row?._id)} size="small" color="primary" checked={isItemSelected} />
          </TableCell>
        )}
        {headCells.map((cell, cellIndex) => {
          if (cell?.renderElement) {
            return <TableCell colSpan={cell?.colSpan}>{cell?.renderElement(row)}</TableCell>;
          }
          return (
            <TableCell
              // colSpan={cell?.colSpan}
              // padding={cellIndex === 0 ? 'none' : 'inherit'}
              align={cell.align || 'left'}
            >
              <Stack direction="row">
                {cellIndex === 0 && nestedRowKey && (
                  <ChevronRightIcon
                    className={`side-icon ${isNestedRowCollapsed(row?._id) && 'rotate-icon'}`}
                    fontSize="small"
                    sx={{
                      mr: 2,
                      color: '#212B36',
                    }}
                  />
                )}
                <Typography sx={{ fontSize: '0.9375rem', fontWeight: 400 }} variant="captions">
                  {has(row, `${cell.id}`) ? (cell?.titleCase ? startCase(get(row, `${cell.id}`)) : get(row, `${cell.id}`)) : ''}
                </Typography>
              </Stack>
              {/* {get(row, `${cell.id}`) || row[`${cell.id}`]} */}
            </TableCell>
          );
        })}
        {(role === 1 || role === 2) && (
          <TableCell>
            <Stack alignItems="center" justifyContent="center" direction="row">
              <BootstrapTooltip enterDelay={500} title="Edit">
                <IconButton sx={{ p: 0.5 }} onClick={(event) => !!row && onEditClick && onEditClick(event, row)}>
                  <Iconify icon="iconamoon:edit-duotone" />
                </IconButton>
              </BootstrapTooltip>
              {row?.status === Types.ACTIVE ? (
                <BootstrapTooltip enterDelay={500} title="Archive">
                  <IconButton sx={{ p: 0.5 }} onClick={(event) => handleStatusButtonClick(event, row, 'archive')}>
                    <Iconify icon="tabler:archive" />
                  </IconButton>
                </BootstrapTooltip>
              ) : (
                <BootstrapTooltip enterDelay={500} title="Activate">
                  <IconButton
                    sx={{ p: 0.5, color: 'success.darker' }}
                    onClick={(event) => handleStatusButtonClick(event, row, 'active')}
                  >
                    <Iconify icon="ph:play-duotone" />
                  </IconButton>
                </BootstrapTooltip>
              )}
              {row?.status === Types.ARCHIVED && (
                <BootstrapTooltip enterDelay={500} title="Remove">
                  <IconButton sx={{ p: 0.5, color: 'error.main' }} onClick={(event) => handleDeleteClick(event, row)}>
                    <Iconify icon="ant-design:delete-twotone" />
                  </IconButton>
                </BootstrapTooltip>
              )}
            </Stack>
          </TableCell>
        )}
      </>
    );
  };
  const handleCheckboxClick = useCallback(
    (event, rowId, childRows) => {
      event.stopPropagation();
      if (!rowId) {
        return null;
      }
      const selectedIndex = selected.indexOf(rowId);
      let newSelected = [];
      if (childRows) {
        if (selectedParent.indexOf(rowId) === -1) {
          setSelectedParent([...selectedParent, rowId]);
          setCollapsedRows([...CollapsedRows, rowId]);
          childRows.forEach((childRow, childIndex) => {
            if (selected.indexOf(childRow?._id) === -1) {
              newSelected.push(childRow?._id);
            }
          });
          setSelected([...selected, ...newSelected]);
        } else {
          setCollapsedRows(CollapsedRows.filter((collapsedRow) => collapsedRow !== rowId));
          setSelectedParent(selectedParent.filter((parentRow) => parentRow !== rowId));
          setSelected(selected.filter((id) => !childRows.some((childRow) => childRow?._id === id)));
        }
      } else {
        if (selectedIndex === -1 && !childRows) {
          newSelected = newSelected.concat(selected, rowId);
        } else if (selectedIndex === 0 && !childRows) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1 && !childRows) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0 && !childRows) {
          newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
      }
      return null;
    },
    [CollapsedRows, selected, selectedParent]
  );
  const getPageCounts = useMemo(() => {
    if (isGroupViewToggled) {
      if (dataFiltered?.length > 0) {
        const filteredElement = dataFiltered?.filter((field) => field?.projectCount > 0);
        return filteredElement?.length;
      }
      return 0;
    }
    return dataFiltered?.length;
  }, [dataFiltered, isGroupViewToggled]);
  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 100 }}
        open={openBackdrop}
        onClick={handleOpenBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ConfirmDialog
        title="Change Status"
        open={allStatusModelOpened}
        setOpen={setAllStatusModelOpened}
        confirmText="Change Status"
        onConfirm={onConfirmAllStatusChange}
      >
        <Typography variant="body1">
          Are you sure you want to <b>{statusToChange === 'active' ? 'activate' : 'archive'}</b> this?
        </Typography>
      </ConfirmDialog>
      <Box sx={{ width: '100%' }}>
        <Card>
          <ConfirmDialog
            title="Change Status"
            open={isStatusModelOpen}
            setOpen={setStatusModelOpen}
            confirmText="Change Status"
            selectedField={selectedRowToChangeStatus}
            onConfirm={onConfirmStatusChange}
          >
            <Typography variant="body1">
              Are you sure you want to <b>{statusType === 'active' ? 'activate' : 'archive'}</b> this?
            </Typography>
          </ConfirmDialog>
          <ConfirmDialog
            title="Delete"
            open={isDeleteModelOpen}
            setOpen={setDeleteModelOpen}
            selectedField={selectedRowToDelete}
            onConfirm={onConfirmDelete}
            confirmText="Delete"
          >
            <Typography variant="body1">
              Are you sure you want to <b>delete</b> this?
            </Typography>
          </ConfirmDialog>

          <ProjectToolbar
            handleClearSelection={handleClearSelection}
            selected={selected}
            filterName={filterName}
            onFilterName={handleFilterByName}
            toolbarProps={toolbarProps}
            setPage={setPage}
            fromDate={fromDate}
            setFromDate={setFromDate}
            toDate={toDate}
            setToDate={setToDate}
            searchLable="Search Project"
            handleOpenBackdrop={handleOpenBackdrop}
            handleCloseBackdrop={handleCloseBackdrop}
          />
          {isLoading && <LinearProgress />}
          {!isGroupViewToggled ? (
            <TableVirtuoso
              context={{
                isSelected,
                dense,
                calculateHeight,
                maxHeight,
                rowsLength: visibleRows.length,
                onRowClick,
                handleRowClick,
                filterName,
              }}
              data={visibleRows}
              components={VirtuosoTableComponents}
              fixedHeaderContent={fixedHeaderContent}
              itemContent={rowContent}
              style={{
                // height: rowsPerPage === -1 ? maxHeight : 55 * rowsPerPage + 57.56,
                height: rowsPerPage === -1 ? maxHeight : (dense ? 41 : 61) * rowsPerPage + (dense ? 41 : 61),
                maxHeight,
              }}
              // style={{ maxHeight: maxHeight && maxHeight, minHeight: !(visibleRows?.length > 0) && 100 }}
            />
          ) : (
            <TableContainer sx={{ maxHeight }}>
              {/* <Table stickyHeader sx={{ minWidth: 800 }}> */}
              <Table size={dense ? 'small' : 'medium'} stickyHeader>
                <TableHead>
                  <TableRow>
                    {(role === 1 || role === 2) && (
                      <TableCell padding="checkbox">
                        <Checkbox size="small" color="primary" onChange={handleSelectAllClick} checked={isAllChecked} />
                      </TableCell>
                    )}
                    {headCells.map((headCell, headIndex) => (
                      <TableCell key={`${headCell?.id} ${headIndex}`} sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                          active={orderBy === headCell?.sortBy || orderBy === headCell?.id}
                          direction={orderBy === headCell?.sortBy || orderBy === headCell?.id ? order : 'asc'}
                          onClick={createSortHandler(headCell?.sortBy || headCell?.id)}
                        >
                          {headCell?.label || ''}
                          {orderBy === headCell?.id ? (
                            <Box component="span" sx={visuallyHidden && visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    {/* <TableCell>Name</TableCell>
                          <TableCell>Customer</TableCell>
                          <TableCell>Start Date</TableCell>
                          <TableCell>End Date</TableCell>
                          <TableCell>Status</TableCell> */}
                    {(role === 1 || role === 2) && <TableCell>Actions</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {visibleRows.map((row, index) => {
                    const isItemSelected = isSelected(row?._id);
                    const isParentRowSelected = selectedParent.indexOf(row?._id) !== -1;
                    const isCollapsed = isRowCollapsed(row?._id);
                    // const labelId = `enhanced-table-checkbox-${index}`;
                    const isArchieved = row?.status ? Boolean(row?.status === Types.ARCHIVED) : false;
                    return (
                      <>
                        {!!row[childKey]?.length && (
                          <TableRow
                            hover
                            // onClick={(event) => handleClick(event, row?._id)}
                            sx={{ cursor: 'pointer' }}
                            onClick={(event) => parentRowClick(event, row)}
                            role="checkbox"
                            aria-checked={isParentRowSelected}
                            tabIndex={-1}
                            key={`${index} ${row?._id} `}
                            selected={isParentRowSelected}
                            // sx={{
                            //   bgcolor: isArchieved && ((theme) => alpha(theme.palette.error.main, 0.07)),
                            //   cursor: 'pointer',
                            //   '&.Mui-selected': {
                            //     bgcolor: (theme) => alpha(theme.palette.primary.light, 0.2),
                            //   },
                            // }}
                          >
                            {(role === 1 || role === 2) && (
                              <TableCell padding="checkbox">
                                <Checkbox
                                  size="small"
                                  color="primary"
                                  checked={isParentRowSelected}
                                  onChange={(event) => handleCheckboxClick(event, row?._id, row[childKey] || null)}
                                  disabled={rowCount <= 0}
                                />
                              </TableCell>
                            )}
                            {groupHeadCells?.map((cell, cellIndex) => {
                              if (cell?.renderElement) {
                                return <TableCell key={`${cell?.id} ${cellIndex}`}>{cell?.renderElement(row)}</TableCell>;
                              }
                              return (
                                <TableCell
                                  colSpan={cell?.colSpan && cell?.colSpan}
                                  sx={{
                                    pl: cellIndex === 0 ? 3 : 'none',
                                    bgcolor: cell?.colSpan && 'red',
                                  }}
                                  // padding={cellIndex === 0 ? 'none' : 'inherit'}
                                  align={cell.align || 'left'}
                                  key={`${cell?.id} ${cellIndex}`}
                                >
                                  <Stack direction="row" alignItems="center">
                                    {cellIndex === 0 && (
                                      <ChevronRightIcon
                                        className={`side-icon ${isCollapsed && 'rotate-icon'}`}
                                        fontSize="small"
                                        sx={{
                                          mr: 2,
                                          color: '#212B36',
                                        }}
                                      />
                                    )}
                                    {row[`${cell?.id}`]}
                                  </Stack>
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        )}
                        {isCollapsed &&
                          childKey &&
                          (row[childKey]?.length > 0
                            ? row[childKey]?.map((cellRow, rowIndex) => (
                                <TableRow
                                  hover
                                  // role="checkbox"
                                  isItemSelected
                                  tabIndex={-1}
                                  key={`${rowIndex} ${cellRow?._id} `}
                                  selected={isSelected(cellRow?._id)}
                                  onClick={(event) => handleRowClick && onRowClick(event, cellRow)}
                                  sx={{ bgcolor: '#eceeef61', cursor: 'pointer' }}
                                  // sx={{
                                  //   bgcolor:
                                  //     cellRow?.status && cellRow?.status === Types.ARCHIVED
                                  //       ? (theme) => alpha(theme.palette.error.main, 0.07)
                                  //       : (theme) => alpha(theme.palette.action.active, 0.06),
                                  //   cursor: 'pointer',
                                  //   '&.Mui-selected': {
                                  //     bgcolor: (theme) => alpha(theme.palette.primary.light, 0.2),
                                  //   },
                                  //   // display: isCollapsed ? 'visible' : 'none',
                                  //   // visibility: 0
                                  // }}
                                >
                                  {(role === 1 || role === 2) && (
                                    <TableCell padding="checkbox">
                                      <Checkbox
                                        size="small"
                                        color="primary"
                                        checked={isSelected(cellRow?._id)}
                                        onChange={(event) => handleCheckboxClick(event, cellRow?._id)}
                                        disabled={rowCount <= 0}
                                      />
                                    </TableCell>
                                  )}
                                  {headCells.map((cell, cellIndex) => {
                                    if (cell?.renderElement) {
                                      return <TableCell key={cell?.id}>{cell?.renderElement(cellRow)}</TableCell>;
                                    }
                                    return (
                                      <TableCell
                                        sx={{
                                          pl: cellIndex === 0 ? 5 : 'none',
                                        }}
                                        padding={cellIndex === 0 ? 'none' : 'inherit'}
                                        align={cell.align || 'left'}
                                        key={cell?.id}
                                      >
                                        {cellRow[`${cell.id}`]}
                                      </TableCell>
                                    );
                                  })}
                                  {(role === 1 || role === 2) && (
                                    <TableCell>
                                      <Stack direction="row">
                                        <IconButton
                                          sx={{ p: 0.5 }}
                                          onClick={(event) => !!row && onEditClick && onEditClick(event, cellRow)}
                                        >
                                          <Iconify icon="iconamoon:edit-duotone" />
                                        </IconButton>
                                        {cellRow?.status &&
                                          (cellRow?.status === Types.ACTIVE ? (
                                            <BootstrapTooltip enterDelay={500} title="Archive">
                                              <IconButton
                                                sx={{ p: 0.5 }}
                                                onClick={(event) => handleStatusButtonClick(event, cellRow, 'archive')}
                                              >
                                                <Iconify icon="tabler:archive" />
                                              </IconButton>
                                            </BootstrapTooltip>
                                          ) : (
                                            <BootstrapTooltip enterDelay={500} title="Activate">
                                              <IconButton
                                                sx={{ p: 0.5, color: 'success.darker' }}
                                                onClick={(event) => handleStatusButtonClick(event, cellRow, 'active')}
                                              >
                                                <Iconify icon="ph:play-duotone" />
                                              </IconButton>
                                            </BootstrapTooltip>
                                          ))}
                                        {cellRow?.status === Types.ARCHIVED && (
                                          <BootstrapTooltip enterDelay={500} title="Remove">
                                            <IconButton
                                              sx={{ p: 0.5, color: 'error.main' }}
                                              onClick={(event) => handleDeleteClick(event, cellRow)}
                                            >
                                              <Iconify icon="ant-design:delete-twotone" />
                                            </IconButton>
                                          </BootstrapTooltip>
                                        )}
                                        {/* <IconButton
                                      sx={{ p: 0.5 }}
                                      onClick={(event) => handleStatusButtonClick(event, cellRow, 'archive')}
                                    >
                                      <Iconify icon="tabler:archive" />
                                    </IconButton> */}
                                      </Stack>
                                    </TableCell>
                                  )}
                                </TableRow>
                              ))
                            : null)}
                      </>
                    );
                    // );
                  })}
                  {visibleRows?.length <= 0 && (
                    <TableRow>
                      <TableCell sx={{ borderBottom: '1px solid transparent' }} colSpan={12}>
                        {' '}
                        <Box alignItems="center" display="flex" justifyContent="center" sx={{ height: 100, width: '100%' }}>
                          {!isLoading && !visibleRows?.length && (
                            <Typography variant="subtitle1" textAlign="center">
                              {`No data found ${filterName && 'for '} `} <b>{filterName && filterName}</b>
                            </Typography>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
                  {maxHeight && (
                    <TableRow
                      sx={{ '& .MuiTableCell-root': { p: 0 } }}
                      style={{
                        height: calculateHeight(),
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                  {/* {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <TablePagination
            sx={{
              backgroundClip: 'red',
              boxShadow:
                '-webkit-box-shadow: -1px -1px 34px -18px rgba(0,0,0,0.27);-moz-box-shadow: -1px -1px 34px -18px rgba(0,0,0,0.27);box-shadow: -1px -1px 34px -18px rgba(0,0,0,0.27);',
            }}
            rowsPerPageOptions={[5, 10, 15, 18, 25, { label: 'All', value: -1 }]}
            component="div"
            // count={rows?.length}
            count={getPageCounts}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            disabled={isPaginationDisable}
          />
        </Card>
        <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Compact View" />
      </Box>
    </>
  );
};

ProjectTable.propTypes = {};

export default ProjectTable;
