/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { yupResolver } from '@hookform/resolvers/yup';
import CancelIcon from '@mui/icons-material/Cancel';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DownloadIcon from '@mui/icons-material/Download';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Card,
  Chip,
  Collapse,
  DialogActions,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { capitalize, get, has } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ModalComponent from '../../components/ModalComponent';
import { getStudyColor, getStudyLabel } from '../../components/StudiesComponent';
import { ThemeButton } from '../../components/button';
import CheckboxGroup from '../../components/checkbox/CheckboxGroup';
import GroupCheckbox from '../../components/formComponents/FormCheckbox';
import FormInput from '../../components/formComponents/FormInput';
import FormRadioGroup from '../../components/formComponents/formRadioGroup';
import { ReusableTabs } from '../../components/reusableTabs';
import CustomSelect from '../../components/select';
import { setSnackbar } from '../../components/snackbar/snackbarSlice';
import ThemeSwitch from '../../components/switch';
import DataTable from '../../components/table/VitrulisedTable';
import { Types } from '../../types';
import { filterEmptyValues } from '../../utils';
import axiosInstance from '../../utils/axiosInstance';
import { addElementInProjectSchema, addToObservationSchema } from '../../utils/schema';
import FilterModal from '../admin/element/FilterModal';
import { getAreas } from '../projectList/areas/areaSlice';
import { getCategories } from '../projectList/category/projectCategoriesSlice';
import { addElement, getElements } from '../projectList/elements/elementsSlice';
import { getRoles } from '../projectList/roles/rolesSlice';
import { SortableElement } from '../tasksPage/TasksNestedRow';
import { getTasks, updateTask } from '../tasksPage/taskSlice';
import {
  addEc,
  addToObservation,
  downloadStudyPhotos,
  getSingleStudyData,
  markStudyReviewed,
  updateSingleStudy,
} from './studiesSlice';

const duration = require('dayjs/plugin/duration');
const timezone = require('dayjs/plugin/timezone');
const utc = require('dayjs/plugin/utc'); // For handling UTC dates

dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(timezone);

const defaultStudyPageColumnSettings = {
  rasStudy: {
    // 'Study Name': true,
    startTime: false,
    roleName: false,
    areaName: false,
    elementName: true,
    categoryName: true,
    rating: true,
    bms: true,
    frequency: true,
    bmsPerUom: true,
    notes: true,
    image: true,
  },
  dotsStudy: {
    observationStartTime: true,
    areaName: true,
    taskName: true,
    elementName: true,
    categoryName: true,
    rating: true,
    bms: true,
    frequency: true,
    bmsPerUom: true,
    notes: true,
    image: true,
  },
  diloStudy: {
    observationStartTime: true,
    areaName: true,
    roleName: true,
    taskName: true,
    elementName: true,
    categoryName: true,
    rating: true,
    bms: true,
    frequency: true,
    bmsPerUom: true,
    notes: true,
    image: true,
  },
};
const SingleStudy = ({ study, projectID, customerID }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isAnswerShowed, setIsAnswerShowed] = useState(false);
  const [isModalopen, setisModalopen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isObsModalOpen, setIsObsModalOpen] = useState(false);
  const [isColumnsModalOpened, setColumnsModal] = useState(false);
  const [isMissingModalOpened, setMissingModal] = useState(false);
  const [elementRating, setElementRating] = useState(false);
  const [collapsedRow, setCollapsedRow] = useState('');
  const [originalTasks, setOriginalTasks] = useState([]);
  const [elementCount, setElementCount] = useState(false);
  const [selectedTab, setSelectedTab] = useState('beforeRound');
  const [selectedElementTab, setSelectedElementTab] = useState('createElement');
  const [currentMissingRound, setCurrentMissingRound] = useState(null);
  const [currentTask, setCurrentTask] = useState('');
  const [afterRounRows, setAfterRoundRows] = useState([]);
  const [beforeRoundRows, setBeforeRoundRows] = useState([]);
  const [rolesOptions, setRolesOptions] = useState([]);
  const [isPhotoDownloading, setPhotosDownloading] = useState([]);
  const [photoDownloadingId, setPhotosDownloadingId] = useState([]);
  const [sortBy, setSortBy] = useState('');
  const [filterBy, setFilterBy] = useState('');
  const [filterOptions, setFilterOptions] = useState([]);
  const [filteredColSettings, setFilteredColSettings] = useState(defaultStudyPageColumnSettings);
  const { singleProjectData } = useSelector((state) => state.project);

  useEffect(() => {
    const studyPageColumnSettings = JSON.parse(localStorage.getItem('studyPageColumnSettings'));
    if (
      !studyPageColumnSettings ||
      !studyPageColumnSettings?.rasStudy ||
      !studyPageColumnSettings?.dotsStudy ||
      !studyPageColumnSettings?.diloStudy
    ) {
      localStorage.setItem('studyPageColumnSettings', JSON.stringify(defaultStudyPageColumnSettings));
    } else {
      setFilteredColSettings(studyPageColumnSettings);
    }
  }, []);

  const [filterConfig, setFilterConfig] = useState({
    filters: {}, // Stores filters for each column
    sortOrder: '',
    sortBy: '',
  });
  const openFilter = (key, orderBy, isLabelNumeric) => {
    setIsFilterOpen(true);
    setFilterBy(key);
    if (orderBy) {
      setSortBy(orderBy);
    }
    if (isLabelNumeric) {
      setFilterConfig((prevConfig) => ({ ...prevConfig, isLabelNumeric: true }));
    } else {
      setFilterConfig((prevConfig) => ({ ...prevConfig, isLabelNumeric: false }));
    }
  };
  const { roles } = useSelector((state) => state?.roles);
  const onEditClick = (event, row, nestedCollapsedRows, setNestedCollapsedRows) => {
    if (nestedCollapsedRows.indexOf(row?._id) !== -1) {
      setNestedCollapsedRows(nestedCollapsedRows.filter((collapseRow) => collapseRow !== row?._id));
    } else {
      setNestedCollapsedRows((prevRows) => [row?._id]);
    }
  };
  const closeFilter = () => setIsFilterOpen(false);
  const closeElementModal = () => {
    setIsObsModalOpen((prevState) => false);
    reset();
    // form2.reset();
  };
  const closeMissingModal = () => {
    setMissingModal((prevState) => false);
    setAfterRoundRows([]);
    setBeforeRoundRows([]);
    // form2.reset();
  };
  const getPhotoCount = (cell) => {
    if (cell?.photo) {
      return cell?.photo?.split(',')?.length;
    }
    return '';
  };
  const downloadPhoto = async (cell) => {
    // Check if cell has a photo value
    if (cell?.photo) {
      try {
        // Split the photo URLs by comma, if there are multiple
        const photoUrls = cell.photo.split(',').map((url) => url.trim()); // Trim each URL
        setPhotosDownloading(true);
        setPhotosDownloadingId(() => cell?._id);
        // Use forEach to iterate through the URLs
        await Promise.all(
          photoUrls.map(async (url) => {
            try {
              // Make the API call for each photo URL
              const response = await axiosInstance({
                url: 'downloads/file',
                method: 'POST',
                data: {
                  filekey: url, // Use the URL in the API call
                  type: 'photo',
                },
                responseType: 'blob',
              });

              if (response.status !== 200) {
                console.log('Failed to fetch file for URL:', url);
                return; // Skip this photo and continue with the next one
              }

              // Create a temporary URL for the file
              const fileUrl = window.URL.createObjectURL(response.data);

              // Create an invisible <a> tag to trigger the download
              const link = document.createElement('a');
              link.href = fileUrl;
              link.download = url.split('/').pop(); // Use the filename from the URL as the download name
              document.body.appendChild(link);
              link.click();

              // Clean up: remove the <a> tag after the download
              document.body.removeChild(link);
              window.URL.revokeObjectURL(fileUrl); // Free up memory by revoking the object URL
            } catch (error) {
              console.log('Error downloading photo:', error);
            }
          })
        );
      } catch (error) {
        console.log('Error in processing photos:', error);
      } finally {
        setPhotosDownloading(false);
        setPhotosDownloadingId('');
      }
    }
  };
  const headCells = useMemo(
    () => [
      {
        id: 'observationStartTime',
        numeric: false,
        label: 'Time',
      },
      {
        id: 'isEc',
        // maxWidth: 36,
        align: 'left',
        renderElement: (cell) =>
          cell?.isEc ? (
            <Typography
              sx={{
                backgroundColor: 'primary.main',
                color: '#fff',
                ml: '15px',
                padding: '3px 6px',
                borderRadius: '21px',
                width: '27px',
              }}
              fontSize="small"
            >
              EC
            </Typography>
          ) : null,
      },
      {
        id: 'roleName',
        numeric: false,
        label: 'Role',
        // minWidth: 170,
        onClick: () => openFilter('roleName', 'roleName'),
      },
      {
        id: 'areaName',
        numeric: false,
        label: 'Area',
        // minWidth: 170,
        onClick: () => openFilter('areaName', 'areaName'),
      },
      {
        id: 'taskName',
        disablePadding: false,
        label: 'Task',
        onClick: () => openFilter('taskName', 'taskName'),
        // renderElement: (cell) => <StudiesComponent studyTypes={cell?.studyTypes} />,
      },
      {
        id: 'elementName',
        numeric: false,
        label: 'Element Name',
        // minWidth: 120,
        onClick: () => openFilter('elementName', 'elementName'),
      },
      {
        id: 'categoryName',
        numeric: false,
        label: 'Category',
        minWidth: 120,
        onClick: () => openFilter('categoryName', 'categoryName'),
      },
      {
        id: 'rating',
        numeric: false,
        label: 'Rating',
        onClick: () => openFilter('rating', 'rating', true),
      },
      {
        id: 'bms',
        numeric: false,
        label: 'BMS',
        onClick: () => openFilter('bms', 'bms', true),
        renderElement: (cell) =>
          cell?.bms && (
            <Stack alignItems="center" justifyContent="center" sx={{ pr: 2 }}>
              {/* <Chip size="small" sx={{ minWidth: 70, bgcolor: cell?.bmsFormatting || '' }} label={cell?.bms} /> */}
              <Chip size="small" sx={{ minWidth: 70 }} label={cell?.bms} />
            </Stack>
          ),
        align: 'center',
        minWidth: 150,
      },
      {
        id: 'frequency',
        numeric: false,
        label: 'Freq',
        onClick: () => openFilter('frequency', 'frequency', true),
      },
      {
        id: 'bmsPerUom',
        numeric: false,
        label: 'BMS/UOM',
        onClick: () => openFilter('bmsPerUom', 'bmsPerUom', true),
      },
      {
        id: 'notes',
        numeric: false,
        label: 'Notes',
        onClick: () => openFilter('notes', 'notes'),
        // minWidth: 250,
      },
      {
        id: 'image',
        numeric: false,
        label: 'Images',
        renderElement: (cell) =>
          cell?.photo ? (
            <ThemeButton
              loading={isPhotoDownloading && photoDownloadingId === cell?._id}
              onClick={() => downloadPhoto(cell)}
              variant="outlined"
              startIcon={<DownloadIcon />}
            >
              {getPhotoCount(cell)}
            </ThemeButton>
          ) : null,
      },
    ],
    [isPhotoDownloading, photoDownloadingId]
  );
  const rasHeadCells = useMemo(
    () => [
      {
        id: 'startTime',
        numeric: false,
        label: 'Rnd',
        onClick: () => openFilter('startTime', 'startTime', true),
      },
      {
        id: 'roleName',
        numeric: false,
        label: 'Role',
        onClick: () => openFilter('roleName', 'roleName'),
      },
      {
        id: 'areaName',
        numeric: false,
        label: 'Area',
        // minWidth: 170,
        onClick: () => openFilter('areaName', 'areaName'),
      },
      {
        id: 'elementName',
        numeric: false,
        label: 'Element Name',
        // minWidth: 190,
        onClick: () => openFilter('elementName', 'elementName'),
      },
      {
        id: 'categoryName',
        numeric: false,
        label: 'Category',
        // minWidth: 180,
        onClick: () => openFilter('categoryName', 'categoryName'),
      },
      {
        id: 'rating',
        numeric: false,
        label: 'Rating',
        onClick: () => openFilter('rating', 'rating', true),
      },
      {
        id: 'bms',
        numeric: false,
        label: 'BMS',
        onClick: () => openFilter('bms', 'bms', true),
        renderElement: (cell) =>
          cell?.bms && (
            <Stack alignItems="center" justifyContent="center" sx={{ pr: 2 }}>
              {/* <Chip size="small" sx={{ minWidth: 70, bgcolor: cell?.bmsFormatting || '' }} label={cell?.bms} /> */}
              <Chip size="small" sx={{ minWidth: 70 }} label={cell?.bms} />
            </Stack>
          ),
        align: 'center',
        minWidth: 150,
      },
      {
        id: 'frequency',
        numeric: false,
        label: 'Freq',
        onClick: () => openFilter('frequency', 'frequency', true),
      },
      {
        id: 'bmsPerUom',
        numeric: false,
        label: 'BMS/UOM',
        onClick: () => openFilter('bmsPerUom', 'bmsPerUom', true),
      },
      {
        id: 'notes',
        numeric: false,
        label: 'Notes',
        onClick: () => openFilter('notes', 'notes'),
        // minWidth: 250,
      },
      {
        id: 'image',
        numeric: false,
        label: 'Images',
        renderElement: (cell) =>
          cell?.photo ? (
            <ThemeButton
              loading={isPhotoDownloading && photoDownloadingId === cell?._id}
              onClick={() => downloadPhoto(cell)}
              variant="outlined"
              startIcon={<DownloadIcon />}
            >
              {getPhotoCount(cell)}
            </ThemeButton>
          ) : null,
      },
    ],
    [isPhotoDownloading, photoDownloadingId]
  );
  const addObsHeadCells = useMemo(
    () => [
      {
        id: 'studyName',
        numeric: false,
        label: 'Study Name',
      },
      {
        id: 'startTime',
        numeric: false,
        label: 'Rnd',
      },
      {
        id: 'roleName',
        numeric: false,
        label: 'Role',
      },
      {
        id: 'areaName',
        numeric: false,
        label: 'Area',
        // minWidth: 170,
      },
      {
        id: 'elementName',
        numeric: false,
        label: 'Element Name',
        // minWidth: 190,
      },
      {
        id: 'categoryName',
        numeric: false,
        label: 'Category',
        // minWidth: 180,
      },
      {
        id: 'rating',
        numeric: false,
        label: 'Rating',
      },
      {
        id: 'frequency',
        numeric: false,
        label: 'Freq',
      },
      {
        id: 'notes',
        numeric: false,
        label: 'Notes',
        // minWidth: 250,
      },
    ],
    []
  );
  const invalidHeadCells = [
    {
      id: 'studyName',
      numeric: false,
      label: 'Study Name',
    },
    {
      id: 'startTime',
      numeric: false,
      label: 'Rnd',
    },
    {
      id: 'roleName',
      numeric: false,
      label: 'Role',
    },
    {
      id: 'areaName',
      numeric: false,
      label: 'Area',
      // minWidth: 170,
    },
    {
      id: 'elementName',
      numeric: false,
      label: 'Element Name',
      // minWidth: 190,
    },
    {
      id: 'categoryName',
      numeric: false,
      label: 'Category',
      // minWidth: 180,
    },
    {
      id: 'rating',
      numeric: false,
      label: 'Rating',
    },
    {
      id: 'frequency',
      numeric: false,
      label: 'Freq',
    },
    {
      id: 'notes',
      numeric: false,
      label: 'Notes',
      // minWidth: 250,
    },
  ];
  const form = useForm({
    defaultValues: {
      studyID: '',
      roundID: '',
      addAtObservationID: '',
      projectID,
      areaID: '',
      elementID: '',
      roleID: '',
      rating: '',
      frequency: null,
      notes: '',
      currentElement: '',
    },
    resolver: yupResolver(addToObservationSchema),
  });
  const { register, watch, handleSubmit, formState, reset, getValues, setValue, control } = form;
  const { errors } = formState;
  const openModal = () => {
    setisModalopen(true);
    form2.setValue('studyTypes', [2, 3]);
    if (currentTask) {
      handleSelectTask('', currentTask);
    }
  };

  const filteredRasHeadCells = useMemo(() => {
    const studyPageColumnSettings = JSON.parse(localStorage.getItem('studyPageColumnSettings'));
    if (!studyPageColumnSettings) {
      localStorage.setItem('studyPageColumnSettings', JSON.stringify(defaultStudyPageColumnSettings));
      return rasHeadCells;
    }
    if (filteredColSettings && filteredColSettings?.rasStudy) {
      return rasHeadCells.filter((cell) => filteredColSettings?.rasStudy?.[cell?.id]);
    }
    return rasHeadCells;
  }, [filteredColSettings, rasHeadCells]);
  const filteredDotsHeadCells = useMemo(() => {
    const studyPageColumnSettings = JSON.parse(localStorage.getItem('studyPageColumnSettings'));
    if (!studyPageColumnSettings) {
      localStorage.setItem('studyPageColumnSettings', JSON.stringify(defaultStudyPageColumnSettings));
      return headCells.filter((cell) => cell?.id !== 'roleName');
    }
    if (filteredColSettings && filteredColSettings?.dotsStudy) {
      return headCells.filter(
        (cell) => cell?.id !== 'roleName' && (cell?.id === 'isEc' || filteredColSettings?.dotsStudy?.[cell?.id])
      );
    }
    return headCells.filter((cell) => cell?.id !== 'roleName');
  }, [filteredColSettings, headCells]);
  const filteredDilosHeadCells = useMemo(() => {
    const studyPageColumnSettings = JSON.parse(localStorage.getItem('studyPageColumnSettings'));
    if (!studyPageColumnSettings) {
      localStorage.setItem('studyPageColumnSettings', JSON.stringify(defaultStudyPageColumnSettings));
      return headCells;
    }
    if (filteredColSettings && filteredColSettings?.diloStudy) {
      return headCells.filter((cell) => cell?.id === 'isEc' || filteredColSettings?.diloStudy?.[cell?.id]);
    }
    return headCells;
  }, [filteredColSettings, headCells]);

  const dispatch = useDispatch();
  const { singleStudyData, singleStudyLoading, addObservationLoading, markStudyLoading } = useSelector((state) => state.studies);
  const { areas } = useSelector((state) => state.areas);
  const { elements, addElementLoading, isLoading: isElementLoading } = useSelector((state) => state.projectElements);
  const { tasks, isLoading: isTaskLoading } = useSelector((state) => state.tasks);
  const { projectCategories: categories } = useSelector((state) => state.projectCategories);

  useEffect(() => {
    if (projectID) {
      dispatch(getRoles({ projectID }));
      dispatch(getAreas({ projectID }));
      dispatch(getElements({ projectID, defaultEntry: false }));
      dispatch(getTasks({ projectID }));
      dispatch(getCategories({ projectID, defaultEntry: false }));
    }
  }, [dispatch, projectID]);
  const fetchStudy = () => {
    if (study?._id) {
      dispatch(getSingleStudyData({ _id: study?._id, studyType: study?.studyType, projectID }));
    }
  };
  useEffect(() => {
    if (study?._id) {
      dispatch(getSingleStudyData({ _id: study?._id, studyType: study?.studyType, projectID }));
    }
  }, [dispatch, projectID, study?._id, study?.studyType]);
  const rolesSelectOptions = useMemo(() => {
    if (roles && roles.length > 0) {
      return roles
        .filter(
          (role) => role?.projectSetting?.studyTypes?.includes(study.studyType) && role?.projectSetting?.status === 'active'
        )
        .map((role) => ({ label: role?.name, value: role?._id }));
    }
    return [];
  }, [roles, study.studyType]);

  const areasOptions = useMemo(() => {
    if (areas && areas.length > 0) {
      return areas
        .filter(
          (area) => area?.projectSetting?.studyTypes?.includes(study.studyType) && area?.projectSetting?.status === 'active'
        )
        .map((area) => ({ label: area?.name, value: area?._id }));
    }
    return [];
  }, [areas, study.studyType]);

  const defaultRolesOptions = useMemo(() => {
    if (roles && roles.length > 0) {
      return roles
        .filter(
          (role) => role?.projectSetting?.studyTypes?.includes(study.studyType) && role?.projectSetting?.status === 'active'
        )
        .map((role) => ({ label: role?.name, value: role?._id }));
    }
    return [];
  }, [roles, study.studyType]);

  const elementSelectOptions = useMemo(() => {
    if (elements && elements.length > 0) {
      return elements
        .filter(
          (element) =>
            element?.projectSetting?.studyTypes?.includes(study.studyType) && element?.projectSetting?.status === 'active'
        )
        .map((element) => ({ label: element?.name, value: element?._id }));
    }
    return [];
  }, [elements, study.studyType]);
  const taskSelectOptions = useMemo(() => {
    if (tasks && !!tasks?.length) {
      return tasks
        .filter((task) => task?.projectSetting?.status === 'active')
        .map((task) => ({ label: task?.name, value: task?._id }));
    }
    return [];
  }, [tasks]);
  useEffect(() => {
    if (roles && !!roles?.length) {
      const optionsRoles = roles
        .filter(
          (role) => role?.projectSetting?.studyTypes?.includes(study.studyType) && role?.projectSetting?.status === 'active'
        )
        .map((role) => ({ label: role?.name, value: role?._id }));
      setRolesOptions(() => optionsRoles);
    } else {
      setRolesOptions(() => []);
    }
  }, [roles, study?.studyType]);

  useEffect(() => {
    const handlePopstate = () => {
      // Your custom code to handle the back button press
      // For example, you can update the state here
      // setState(newValue);
      dispatch(updateSingleStudy(null));
    };
    // Add the event listener when the component mounts
    window.addEventListener('popstate', handlePopstate);
    window.history.pushState(null, document.title, window.location.href);
    window.history.go(1);
    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, [dispatch]);
  const onDelete = async (row, startLoading, closeLoading) => {
    if (row?._id && projectID) {
      startLoading();
      try {
        const response = await axiosInstance({
          url: 'studies/delete/obs',
          method: 'DELETE',
          data: {
            // projectID,
            studyID: row?.studyID,
            roundID: row?.roundID,
            observationIDs: [row?._id],
            studyType: row?.studyType,
          },
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'data.message'),
            })
          );
          dispatch(getRoles({ projectID }));
          fetchStudy();
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        closeLoading();
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  const missingRounds = useMemo(() => {
    const rounds = [];
    if (!singleStudyData?.rounds || !singleStudyData?.rounds?.length) {
      return [];
    }
    singleStudyData?.rounds.forEach((round, index) => {
      if (!round?.data || round?.data?.length <= 0) {
        const time = dayjs(round?.roundStartTime);
        // const formattedStartTime = time.format('HH:mm');

        let finalTimeZonedTime = '';
        const formattedTime = time.format('HH:mm');
        let timeZonedTime = formattedTime;
        const tz = study?.timezone || study?.timeZone;
        if (tz) {
          timeZonedTime = time.tz(tz)?.format('HH:mm');
        } else if (singleProjectData?.timezone) {
          timeZonedTime = time.tz(singleProjectData?.timezone)?.format('HH:mm');
        } else {
          timeZonedTime = time.tz('Europe/London')?.format('HH:mm');
        }
        finalTimeZonedTime = timeZonedTime;

        const roundObj = {
          roundID: get(round, 'roundID'),
          formattedStartTime: finalTimeZonedTime,
          roundStartTime: get(round, 'roundStartTime'),
          roundEndTime: get(round, 'roundEndTime'),
        };
        rounds.push(roundObj);
      }
    });
    return rounds;
  }, [singleProjectData?.timezone, singleStudyData?.rounds, study?.timeZone, study?.timezone]);

  const singleStudyRows = useMemo(() => {
    if (!study?.studyType || !singleStudyData) {
      return [];
    }
    if (study?.studyType === 1 && singleStudyData?.rounds && singleStudyData?.rounds?.length) {
      const combinedObservations = singleStudyData?.rounds.flatMap((round) => {
        // Extract roundStartTime
        const { roundStartTime } = round;
        // Map over each observation in round.data and set the startTime to roundStartTime
        return round.data?.length > 0
          ? round.data.map((observation) => {
              // let formattedTime = '';
              let finalTimeZonedTime = '';
              if (roundStartTime) {
                const dateTime = dayjs(roundStartTime); // Ensure it's interpreted as UTC
                // formattedTime = dateTime.format('HH:mm');
                const formattedTime = dateTime.format('HH:mm');
                let timeZonedTime = formattedTime;
                const tz = study?.timezone || study?.timeZone;
                if (tz) {
                  timeZonedTime = dateTime.tz(tz)?.format('HH:mm');
                } else if (singleProjectData?.timezone) {
                  timeZonedTime = dateTime.tz(singleProjectData?.timezone)?.format('HH:mm');
                } else {
                  timeZonedTime = dateTime.tz('Europe/London')?.format('HH:mm');
                }
                finalTimeZonedTime = timeZonedTime;
              }
              return {
                ...observation,
                studyType: study?.studyType,
                roundID: round?.roundID,
                _id: observation?.observationID,
                // bms: get(observation, 'bms')?.toString(),
                // bmsPerUom: get(observation, 'bmsPerUom')?.toString(),
                bms: get(observation, 'bms') && parseFloat(get(observation, 'bms').toFixed(2)),
                bmsPerUom: get(observation, 'bmsPerUom') && parseFloat(get(observation, 'bmsPerUom').toFixed(2)),
                frequency: get(observation, 'frequency')?.toString(),
                duration: get(observation, 'duration')?.toString(),
                startTime: finalTimeZonedTime,
              };
            })
          : [];
      });
      return combinedObservations;
    }
    if ((study?.studyType === 2 || study?.studyType === 3) && singleStudyData?.data && singleStudyData?.data?.length > 0) {
      return singleStudyData?.data.map((studyData) => {
        const studyObject = {
          ...studyData,
          _id: studyData?.observationID,
          studyType: study?.studyType,
          // bms: get(studyData, 'bms')?.toString(),
          // bmsPerUom: get(studyData, 'bmsPerUom')?.toString(),
          bms: get(studyData, 'bms') && parseFloat(get(studyData, 'bms').toFixed(2)),
          bmsPerUom: get(studyData, 'bmsPerUom') && parseFloat(get(studyData, 'bmsPerUom').toFixed(2)),
          duration: get(studyData, 'duration')?.toString(),
          frequency: get(studyData, 'frequency')?.toString(),
          isEc: get(studyData, 'originalValues') && Object.keys(get(studyData, 'originalValues')).length > 0,
        };
        if (study?.studyType === 2 && singleStudyData?.roleName) {
          studyObject.roleName = singleStudyData?.roleName;
        }
        const observationStartTime = get(studyData, 'startTime', '');
        if (observationStartTime) {
          const dateTime = dayjs(observationStartTime); // Ensure it's interpreted as UTC
          // Format the time to hours and minutes
          const formattedTime = dateTime.format('HH:mm');
          let timeZonedTime = formattedTime;
          const tz = study?.timezone || study?.timeZone;
          if (tz) {
            timeZonedTime = dateTime.tz(tz)?.format('HH:mm');
          } else if (singleProjectData?.timezone) {
            timeZonedTime = dateTime.tz(singleProjectData?.timezone)?.format('HH:mm');
          } else {
            timeZonedTime = dateTime.tz('Europe/London')?.format('HH:mm');
          }

          studyObject.observationStartTime = timeZonedTime;
        } else {
          studyObject.observationStartTime = '';
        }

        return studyObject;
      });
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleStudyData]);
  const startTimeOptions = useMemo(() => {
    if (singleStudyRows && singleStudyRows?.length) {
      const uniqueStartTime = [...new Set(singleStudyRows.map((studyRow) => studyRow.startTime))];
      return uniqueStartTime.map((startTime) => ({ label: startTime, value: startTime }));
    }
    return [];
  }, [singleStudyRows]);
  const roleOptions = useMemo(() => {
    if (singleStudyRows && singleStudyRows?.length) {
      const uniqueRoleNames = [...new Set(singleStudyRows.map((studyRow) => studyRow.roleName))];
      return uniqueRoleNames.sort((a, b) => a.localeCompare(b)).map((roleName) => ({ label: roleName, value: roleName }));
    }
    return [];
  }, [singleStudyRows]);
  const taskOptions = useMemo(() => {
    if (singleStudyRows && singleStudyRows?.length) {
      const uniqueTaskNames = [...new Set(singleStudyRows.map((studyRow) => studyRow.taskName))];
      return uniqueTaskNames.sort((a, b) => a.localeCompare(b)).map((taskName) => ({ label: taskName, value: taskName }));
    }
    return [];
  }, [singleStudyRows]);
  const areaOptions = useMemo(() => {
    if (singleStudyRows && singleStudyRows?.length) {
      const uniqueAreaNames = [...new Set(singleStudyRows.map((studyRow) => studyRow.areaName))];
      return uniqueAreaNames.sort((a, b) => a.localeCompare(b)).map((areaName) => ({ label: areaName, value: areaName }));
    }
    return [];
  }, [singleStudyRows]);
  const notesOptions = useMemo(() => {
    if (singleStudyRows && singleStudyRows?.length) {
      const uniqueAreaNames = [...new Set(singleStudyRows.map((studyRow) => studyRow.notes))];
      return uniqueAreaNames
        .filter((note) => !!note)
        .sort((a, b) => a.localeCompare(b))
        .map((note) => ({ label: note, value: note }));
    }
    return [];
  }, [singleStudyRows]);
  const elementOptions = useMemo(() => {
    if (singleStudyRows && singleStudyRows?.length) {
      const uniqueElements = [...new Set(singleStudyRows.map((studyRow) => studyRow.elementName))];
      return uniqueElements.sort((a, b) => a.localeCompare(b)).map((elementName) => ({ label: elementName, value: elementName }));
    }
    return [];
  }, [singleStudyRows]);
  const categoryOptions = useMemo(() => {
    if (singleStudyRows && singleStudyRows?.length) {
      const ctegoryElements = [...new Set(singleStudyRows.map((studyRow) => studyRow.categoryName))];
      return ctegoryElements
        .sort((a, b) => a.localeCompare(b))
        .map((categoryName) => ({ label: categoryName, value: categoryName }));
    }
    return [];
  }, [singleStudyRows]);
  const categoryFieldOptions = useMemo(
    () =>
      categories && categories?.length > 0
        ? categories
            .filter((category) => has(category, 'projectSetting.status') && category.projectSetting.status === 'active')
            .map((category) => ({ label: category.name, value: category._id }))
        : [],
    [categories]
  );
  // );
  const ratingOptions = useMemo(() => {
    if (singleStudyRows && singleStudyRows?.length) {
      const ratingElements = [...new Set(singleStudyRows.map((studyRow) => studyRow.rating))];
      return ratingElements.map((rating) => ({ label: rating, value: rating }));
    }
    return [];
  }, [singleStudyRows]);
  const ratingsOptions = useMemo(
    () => [
      {
        label: 'Not Rated',
        description: 'No rating screen in app, SMV not shown in CMS or in any values. Only in data summary export',
        value: 1,
      },
      { label: '100', description: 'No rating screen in app, element defaults to 100 rating', value: 2 },
      { label: 'Field User Input', description: 'Show rating screen in the app for the element', value: 3 },
    ],
    []
  );
  const bmsOptions = useMemo(() => {
    if (singleStudyRows && singleStudyRows?.length) {
      const bmsElements = [...new Set(singleStudyRows.map((studyRow) => studyRow.bms))];
      return bmsElements.sort((a, b) => a - b).map((bms) => ({ label: bms, value: bms }));
    }
    return [];
  }, [singleStudyRows]);
  const bmsPerUomOptions = useMemo(() => {
    if (singleStudyRows && singleStudyRows?.length) {
      const bmsElements = [...new Set(singleStudyRows.map((studyRow) => studyRow.bmsPerUom))];
      return bmsElements.sort((a, b) => a - b).map((bmsPerUom) => ({ label: bmsPerUom, value: bmsPerUom }));
    }
    return [];
  }, [singleStudyRows]);
  const frequencyOptions = useMemo(() => {
    if (singleStudyRows && singleStudyRows?.length) {
      const frequencyElements = [...new Set(singleStudyRows.map((studyRow) => studyRow.frequency))];
      return frequencyElements.sort((a, b) => a - b).map((frequency) => ({ label: frequency, value: frequency }));
    }
    return [];
  }, [singleStudyRows]);
  const obsRatingOptions = useMemo(() => {
    const array = [];
    for (let i = 60; i <= 120; i += 5) {
      array.push({ label: i.toString(), value: i.toString() });
    }
    array.push({ label: 'Not Rated', value: 'Not Rated' });
    return array;
  }, []);
  useEffect(() => {
    switch (filterBy) {
      case 'startTime':
        setFilterOptions(startTimeOptions);
        break;
      case 'roleName':
        setFilterOptions(roleOptions);
        break;
      case 'areaName':
        setFilterOptions(areaOptions);
        break;
      case 'taskName':
        setFilterOptions(taskOptions);
        break;
      case 'elementName':
        setFilterOptions(elementOptions);
        break;
      case 'categoryName':
        setFilterOptions(categoryOptions);
        break;
      case 'rating':
        setFilterOptions(obsRatingOptions);
        break;
      case 'bms':
        setFilterOptions(bmsOptions);
        break;
      case 'bmsPerUom':
        setFilterOptions(bmsPerUomOptions);
        break;
      case 'frequency':
        setFilterOptions(frequencyOptions);
        break;
      case 'notes':
        setFilterOptions(notesOptions);
        break;
      default:
        setFilterOptions([]);
        break;
    }
  }, [
    areaOptions,
    bmsOptions,
    bmsPerUomOptions,
    categoryOptions,
    elementOptions,
    filterBy,
    frequencyOptions,
    notesOptions,
    obsRatingOptions,
    ratingOptions,
    roleOptions,
    startTimeOptions,
    taskOptions,
  ]);
  const nestedRowProps = {
    rolesSelectOptions,
    areasOptions,
    elementSelectOptions,
    taskSelectOptions,
    fetchStudy,
    nestedType: 'singleStudy',
    setisModalopen,
    setCurrentTask,
    openModal,
  };
  const handleEcElement = (selected, visibleRows, handleClearSelection) => {
    if (selected?.length > 1) {
      const firstElement = visibleRows.find((row) => row?._id === selected[0]);
      const payload = {
        studyID: firstElement?.studyID,
        studyType: firstElement?.studyType,
        observationIDs: selected,
      };
      dispatch(addEc(payload))
        .then((response) => {
          if (response.payload?.success) {
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'success',
                snackbarMessage: get(response, 'payload.message', 'EC Added Successfully'),
              })
            );
            handleClearSelection();
            fetchStudy();
          } else if (get(response, 'payload.message')) {
            const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'error',
                snackbarMessage: errorMessage,
              })
            );
          }
        })
        .catch((error) => {
          console.log('error==> ', error);
        });
    }
  };
  const isEcValid = (selected, visibleRows) => {
    // if length 0, if study ras
    if (selected?.length <= 1) {
      return false;
    }
    const firstElement = visibleRows.find((row) => row?._id === selected[0]);
    return selected.every((selectedID, index) => {
      const element = visibleRows.find((row) => row?._id === selectedID);
      if (element && element?.studyType === 1) {
        return false;
      }
      return (
        element?.studyID === firstElement?.studyID &&
        element?.studyType === firstElement?.studyType &&
        element?.elementID === firstElement?.elementID &&
        element?.areaID === firstElement?.areaID &&
        element?.taskID === firstElement?.taskID
      );
    });
  };
  const isRemoveValid = (selected, visibleRows, studyType) => {
    // if length 0, if study ras
    if (selected?.length <= 1) {
      return true;
    }
    const firstElement = visibleRows.find((row) => row?._id === selected[0]);
    return selected.every((selectedID, index) => {
      const element = visibleRows.find((row) => row?._id === selectedID);
      if (studyType === 1) {
        return (
          element?.studyID === firstElement?.studyID &&
          element?.studyType === firstElement?.studyType &&
          element?.roundID === firstElement?.roundID
        );
      }
      return element?.studyID === firstElement?.studyID && element?.studyType === firstElement?.studyType;
    });
  };
  const getSingleStudiesPayload = (selectedRows, studyType) => {
    if (selectedRows && selectedRows.length > 0) {
      const studyRow = singleStudyRows.find((row) => row?._id === selectedRows[0]);
      const payload = {
        studyID: studyRow?.studyID,
        studyType,
        observationIDs: selectedRows,
      };
      if (studyType === 1) {
        payload.roundID = studyRow?.roundID;
      }
      return payload;
    }
    return null; // Return empty array if no rows are selected
  };
  const toolbarProps = {
    setFilterConfig,
    handleEcElement,
    isEcValid: study?.studyType === 1 ? null : isEcValid,
    isRemoveValid,
    taskSelectOptions,
    studyType: study?.studyType,
    rolesSelectOptions,
    areasOptions,
    ratingOptions,
    elementSelectOptions,
    fetchStudy,
    getSingleStudiesPayload,
  };
  const handleColumnChange = (event, cell) => {
    if (study?.studyType === 1) {
      const newColSettings = {
        ...filteredColSettings,
        rasStudy: { ...filteredColSettings?.rasStudy, [cell?.id]: event.target.checked },
      };
      setFilteredColSettings(newColSettings);
      localStorage.setItem('studyPageColumnSettings', JSON.stringify(newColSettings));
    } else if (study?.studyType === 2) {
      const newColSettings = {
        ...filteredColSettings,
        diloStudy: { ...filteredColSettings?.diloStudy, [cell?.id]: event.target.checked },
      };
      setFilteredColSettings(newColSettings);
      localStorage.setItem('studyPageColumnSettings', JSON.stringify(newColSettings));
    } else if (study?.studyType === 3) {
      const newColSettings = {
        ...filteredColSettings,
        dotsStudy: { ...filteredColSettings?.dotsStudy, [cell?.id]: event.target.checked },
      };
      setFilteredColSettings(newColSettings);
      localStorage.setItem('studyPageColumnSettings', JSON.stringify(newColSettings));
    }
  };
  const isCellVisible = (cell) => {
    if (study?.studyType === 1) {
      return filteredColSettings?.rasStudy ? filteredColSettings?.rasStudy[cell?.id] : false;
    }
    if (study?.studyType === 2) {
      return filteredColSettings?.diloStudy ? filteredColSettings?.diloStudy[cell?.id] : false;
    }
    return filteredColSettings?.dotsStudy ? filteredColSettings?.dotsStudy[cell?.id] : false;
  };
  const finalHeadCells = useMemo(() => {
    if (study?.studyType === 1) {
      return filteredRasHeadCells;
    }
    if (study?.studyType === 2) {
      return filteredDilosHeadCells;
    }
    if (study?.studyType === 3) {
      return filteredDotsHeadCells;
    }
    return [];
  }, [filteredDilosHeadCells, filteredDotsHeadCells, filteredRasHeadCells, study?.studyType]);
  const addObservation = (cell) => {
    setValue('studyID', get(cell, 'studyID', ''));
    setValue('areaID', '');
    setValue('roundID', get(cell, 'roundID', ''));
    setValue('addAtObservationID', get(cell, 'observationID', ''));
    setValue('elementID', '');
    setValue('roleID', '');
    setValue('rating', '');
    setValue('frequency', '');
    setValue('notes', '');
    setIsObsModalOpen(true);
    const currentRound = singleStudyData?.rounds && singleStudyData?.rounds?.find((round) => round?.roundID === cell?.roundID);
    if (currentRound && currentRound?.data?.length > 0) {
      const filteredRolesOptions = defaultRolesOptions.filter(
        (roleOption) => !currentRound?.data?.some((round) => round?.roleID === roleOption?.value)
      );
      if (filteredRolesOptions && !!filteredRolesOptions?.length) {
        setRolesOptions(filteredRolesOptions);
      } else {
        setRolesOptions([]);
      }
    }
  };
  const frequencyInputFields = register('frequency');
  const frequencyValue = watch('frequency');
  const closeObsModal = () => {
    setIsObsModalOpen((prevState) => false);
    setElementCount(true);
    reset();
  };
  const onSubmit = (values) => {
    if (selectedElementTab === 'createElement') {
      const filteredPayload = filterEmptyValues(values);
      dispatch(addToObservation(filteredPayload))
        .then((response) => {
          if (response.payload?.success) {
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'success',
                snackbarMessage: get(response, 'payload.message', 'Observation Added Successfully'),
              })
            );
            fetchStudy();
            closeObsModal();
          } else if (get(response, 'payload.message')) {
            const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'error',
                snackbarMessage: errorMessage,
              })
            );
          }
        })
        .catch((error) => {
          console.log('error==> ', error);
        });
    }
  };
  const handleMissingRoundClicked = (round) => {
    if (round) {
      setMissingModal(true);
      setCurrentMissingRound(round);
      const currentTime = dayjs(round?.roundStartTime);
      // const endTime = dayjs(round?.roundEndTime);
      const time = dayjs(singleStudyData?.studyStartTime);
      const endTime = dayjs(singleStudyData?.studyEndTime);
      const durationDifference = dayjs.duration(singleStudyData?.roundDuration, 'millisecond');
      let dateBefore = null;
      let dateAfter = null;
      for (
        let i = currentTime.subtract(durationDifference);
        i.isSame(time) || i.isAfter(time);
        i = i.subtract(durationDifference)
      ) {
        const isBefore = singleStudyData?.rounds?.find(
          (currRound) =>
            dayjs(currRound?.roundStartTime)?.format('HH:mm') === i.format('HH:mm') &&
            currRound?.data &&
            currRound?.data?.length > 0
        );
        if (isBefore) {
          dateBefore = isBefore;
          break; // Exit the loop when the condition is met
        }
      }
      for (let i = currentTime.add(durationDifference); i.isSame(endTime) || i.isBefore(endTime); i = i.add(durationDifference)) {
        const isAfter = singleStudyData?.rounds?.find(
          (currRound) =>
            dayjs(currRound?.roundStartTime)?.format('HH:mm') === i.format('HH:mm') &&
            currRound?.data &&
            currRound?.data?.length > 0
        );
        if (isAfter) {
          dateAfter = isAfter;
          break; // Exit the loop when the condition is met
        }
      }

      if (dateAfter) {
        const { roundStartTime, roundID } = dateAfter;
        const afterRows = dateAfter?.data?.map((curRound) => {
          let formattedTime = '';
          if (roundStartTime) {
            const dateTime = dayjs(roundStartTime); // Ensure it's interpreted as UTC
            formattedTime = dateTime.format('HH:mm');
          }
          return {
            ...curRound,
            studyName: study?.name,
            studyType: study?.studyType,
            _id: curRound?.observationID,
            bms: get(curRound, 'bms')?.toString(),
            frequency: get(curRound, 'frequency')?.toString(),
            bmsPerUom: get(curRound, 'bmsPerUom')?.toString(),
            duration: get(curRound, 'duration')?.toString(),
            startTime: formattedTime,
          };
        });
        setAfterRoundRows(afterRows);
      }
      if (dateBefore) {
        const { roundStartTime } = dateBefore;
        const beforeRows = dateBefore?.data?.map((curRound) => {
          let formattedTime = '';
          if (roundStartTime) {
            const dateTime = dayjs(roundStartTime); // Ensure it's interpreted as UTC
            formattedTime = dateTime.format('HH:mm');
          }
          return {
            ...curRound,
            studyName: study?.name,
            studyType: study?.studyType,
            _id: curRound?.observationID,
            bms: get(curRound, 'bms')?.toString(),
            frequency: get(curRound, 'frequency')?.toString(),
            bmsPerUom: get(curRound, 'bmsPerUom')?.toString(),
            duration: get(curRound, 'duration')?.toString(),
            startTime: formattedTime,
          };
        });
        setBeforeRoundRows(beforeRows);
      }
    }
  };

  const tabs = [
    { label: 'Before Round', value: 'beforeRound' },
    { label: 'After Round', value: 'afterRound' },
  ];
  const addElementTabs = [
    { label: 'Create Element', value: 'createElement' },
    { label: 'Add Existing Element', value: 'addElement' },
  ];
  const createElementTabs = [{ label: 'Create Element', value: 'createElement' }];
  const onTabChange = (event, newValue) => setSelectedTab(newValue);
  const onElementTabChange = (event, newValue) => setSelectedElementTab(newValue);
  const handleObservationClick = (selectedRows) => {
    // console.log('selectedRows', selectedRows);
  };
  const obsToolbarProps = {
    handleObservationClick,
    setFilterConfig,
    handleEcElement,
    isEcValid,
    currentMissingRound,
    closeMissingModal,
    fetchStudy,
  };
  const aftObsToolbarProps = {
    handleObservationClick,
    setFilterConfig,
    handleEcElement,
    isEcValid,
    currentMissingRound,
    closeMissingModal,
    fetchStudy,
  };
  const elementID = watch('elementID');
  const form2 = useForm({
    defaultValues: {
      // defaultEntry: true,
      name: '',
      unitOfMeasure: '',
      studyTypes: [],
      tasks: [],
      rating: 3,
      type: 2,
      count: false,
      contingencyAllowance: null,
      relaxationAllowance: null,
      categoryID: '',
      currentElement: '',
    },
    resolver: selectedElementTab === 'createElement' && yupResolver(addElementInProjectSchema),
  });
  // const { register, watch, handleSubmit, formState, reset, getValues, setValue, control } = form2x
  // const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
  const {
    append,
    fields: fields2,
    remove,
  } = useFieldArray({
    control: form2?.control, // control props comes from useForm (optional: if you are using FormProvider)
    name: 'tasks', // unique name for your Field Array
  });

  const studyTypesValues = form2?.watch('studyTypes');
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const closeModal = () => {
    setisModalopen((prevState) => !prevState);
    form2?.reset();
    form2?.setValue('tasks', []);
    setOriginalTasks([]);
    setSelectedElementTab('createElement');
  };
  useEffect(() => {
    if (elementID) {
      const element = elements.find((curElement) => curElement?._id === elementID);
      if (element && element?.projectSetting?.rating) {
        setElementRating(element?.projectSetting?.rating);
      } else {
        setElementRating(null);
      }
      if (get(element, 'projectSetting.count') !== undefined && get(element, 'projectSetting.count') === false) {
        setValue('frequency', 1);
      }
      setElementCount(get(element, 'projectSetting.count', true));
    } else {
      setElementRating(null);
      setElementCount(true);
    }
  }, [elementID, elements, setValue]);
  useEffect(() => {
    if (elementRating) {
      if (elementRating === 1) {
        setValue('rating', 'Not Rated');
      } else if (elementRating === 2) {
        setValue('rating', 100);
      }
    }
  }, [elementRating, setValue]);
  const typeOptions = useMemo(
    () => [
      { label: 'Fixed', value: 1 },
      { label: 'Variable', value: 2 },
    ],
    []
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentElementname, setCurrentElementName] = useState('');
  const open = Boolean(anchorEl);
  const addTaskClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const tempID = useMemo(() => 'tempID', []);

  const handleSelectTask = (event, value) => {
    const selectedTask = tasks.find((task) => task?._id === value);
    if (selectedTask) {
      const newTask = {
        taskID: selectedTask?._id,
        taskName: selectedTask?.name,
        elements: [tempID, ...get(selectedTask, 'projectSetting.elements', [])],
      };
      append(newTask);
    }
    handleClose();
  };
  const studyTypeConfig = { label: 'label', value: 'value' };
  const studyTypeOptions = [
    // { label: 'Efficiency Study', value: 1, color: '#f59d0f' },
    { label: 'Role Study', value: 2, color: '#784fc2' },
    { label: 'Activity Study', value: 3, color: '#0ba0ef' },
  ];
  const tasksValues = form2?.watch('tasks');
  const handleDragEnd = (event, field, index) => {
    const { active, over } = event;
    const currentElements = field?.elements;
    const activeIndex = currentElements?.findIndex((element) => element === active.id);
    const overIndex = currentElements?.findIndex((element) => element === over.id);
    const sortedTempArray = arrayMove(currentElements, activeIndex, overIndex);
    const taskValToUpdate = [...tasksValues];
    taskValToUpdate[index].elements = sortedTempArray;
    form2?.setValue('tasks', taskValToUpdate);
  };
  const tasksOptions = useMemo(
    () => (tasks?.length > 0 ? tasks?.map((taskElement) => ({ label: taskElement?.name, value: taskElement?._id })) : []),
    [tasks]
  );
  const rowClicked = (element) => {
    if (collapsedRow === element) {
      setCollapsedRow('');
    } else {
      setCollapsedRow(element);
    }
  };
  const onElementSubmit = (values) => {
    if (selectedElementTab === 'createElement') {
      const { tasks: taskValues, ...rest } = values;
      const payload = { ...rest };
      if ((values.studyTypes.includes(2) || values.studyTypes.includes(3)) && taskValues && taskValues.length > 0) {
        const newTaskValues = taskValues.map((task) => ({
          ...task,
          elements: task.elements?.length > 0 ? task.elements.map((el) => (el === tempID ? values?.name : el)) : task.elements,
        }));
        payload.tasks = newTaskValues;
      }
      if (get(values, 'rating')) {
        payload.rating = parseInt(values.rating, 10);
      }
      if (get(values, 'type')) {
        payload.type = parseInt(values.type, 10);
      }

      if (customerID && projectID) {
        dispatch(addElement({ ...payload, defaultEntry: false, customerID, projectID }))
          .then((response) => {
            if (response.payload?.success) {
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'success',
                  snackbarMessage: get(response, 'payload.message', 'Element Added Successfully'),
                })
              );
              dispatch(getElements({ projectID, defaultEntry: false }));
              dispatch(getTasks({ projectID }));
              closeModal();
            } else if (get(response, 'payload.message')) {
              const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'error',
                  snackbarMessage: errorMessage,
                })
              );
            }
          })
          .catch((error) => {
            console.log('error==> ', error);
          });
      }
    } else {
      // const demo = {
      //   name: 'Belted Till Transaction',
      //   controllingElementID: '654e1584a44292fc20f5a32c',
      //   elements: [],
      //   studyTypes: [3, 2],
      //   customerID: '5d108dd9f6bbda74fd2c043b',
      //   projectID: '661e5350925699c6011494fc',
      //   _id: '654e0b37c5a7470f6159ea12',
      // };
      const { currentElement, ...rest } = values;
      const selectedTask = currentTask && tasks && tasks?.length > 0 ? tasks.find((task) => task?._id === currentTask) : null;
      if (selectedTask && currentElement) {
        const taskPayload = {
          name: get(selectedTask, 'name'),
          controllingElementID: get(selectedTask, 'projectSetting.controllingElementID'),
          studyTypes: get(selectedTask, 'projectSetting.studyTypes'),
          customerID: get(selectedTask, 'projectSetting.customerID'),
          projectID: get(selectedTask, 'projectSetting.projectID'),
          _id: get(selectedTask, '_id'),
          elements: get(selectedTask, 'projectSetting.elements')
            ? [...get(selectedTask, 'projectSetting.elements'), currentElement]
            : [currentElement],
        };
        dispatch(updateTask(taskPayload))
          .then((response) => {
            if (response.payload?.success) {
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'success',
                  snackbarMessage: get(response, 'payload.message', 'Task Updated Successfully'),
                })
              );
              dispatch(getTasks({ projectID }));
              dispatch(getElements({ projectID, defaultEntry: false }));
              closeModal();
            } else if (get(response, 'payload.message')) {
              const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'error',
                  snackbarMessage: errorMessage,
                })
              );
            }
          })
          .catch((error) => {
            console.log('error==> ', error);
          });
      }
    }
  };
  const downloadAllPhotos = async () => {
    const payload = {
      studyType: study?.studyType,
      studyID: study?._id,
      projectID,
      customerID,
      projectName: get(singleProjectData, 'name'),
      customerName: get(singleProjectData, 'customerName'),
    };

    dispatch(downloadStudyPhotos(payload))
      .then((response) => {
        if (response.payload?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'payload.message', 'Element Added Successfully'),
            })
          );
        } else if (get(response, 'payload.message')) {
          const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'error',
              snackbarMessage: errorMessage,
            })
          );
        }
      })
      .catch((error) => {
        console.log('error==> ', error);
      });
  };
  // if (Object.entries(row).some(([key, value]) => value === 'Removed From HUB' || value === 'Removed From Project')) {
  //   return (theme) => alpha(theme.palette.error.main, 0.07);
  // }
  const checkRowCondition = (row) => {
    if (row) {
      if (Object.entries(row).some(([key, value]) => value === 'Removed From HUB' || value === 'Removed From Project')) {
        return false;
      }
      return true;
    }
    return false;
  };
  const getTitledWord = (word) => {
    if (typeof word === 'string') {
      return word?.replace(/\w+/g, capitalize);
    }
    return '';
  };
  const handleMarkReview = async () => {
    if (study) {
      const payload = {
        projectID,
        studyID: get(study, '_id'),
        studyType: get(study, 'studyType'),
      };
      dispatch(markStudyReviewed({ studiesData: [payload] }))
        .then((response) => {
          if (response.payload?.success) {
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'success',
                snackbarMessage: get(response, 'payload.message', 'EC Added Successfully'),
              })
            );
          } else if (get(response, 'payload.message')) {
            const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'error',
                snackbarMessage: errorMessage,
              })
            );
          }
        })
        .catch((error) => {
          console.log('error==> ', error);
        });
    }
  };
  return (
    <>
      <ModalComponent
        persist
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '1400px !important',
            minHeight: '92.9vh',
          },
        }}
        title="Create Element"
        open={isModalopen}
        onClose={closeModal}
        DialogActions={
          <DialogActions sx={{ m: 2, px: 2, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <ThemeButton type="button" onClick={closeModal} color="error" variant="outlined">
              Cancel
            </ThemeButton>
            <ThemeButton
              onClick={form2?.handleSubmit(onElementSubmit)}
              type="submit"
              loading={addElementLoading}
              variant="contained"
            >
              Save Changes
            </ThemeButton>
          </DialogActions>
        }
      >
        <form onSubmit={form2?.handleSubmit(onElementSubmit)}>
          {study?.studyType !== 1 && (
            <ReusableTabs
              tabs={study?.studyType === 1 ? createElementTabs : addElementTabs}
              selectedTab={selectedElementTab}
              handleChange={onElementTabChange}
            />
          )}
          {selectedElementTab === 'createElement' ? (
            <Grid mt rowSpacing={1} columnSpacing={3} container>
              <Grid className="left-side" item xs={12} md={6} lg={6}>
                <Grid rowSpacing={1} columnSpacing={1} container>
                  <Grid sx={{ '& .MuiFormControl-root': { marginBottom: 0 } }} className="name" item xs={12} md={12} lg={12}>
                    <FormInput
                      width="90%"
                      label="Name"
                      placeholder="e.g Greet Customer"
                      InputProps={{
                        ...form2?.register('name'),
                        onBlur: (event) => setCurrentElementName(event.target.value),
                      }}
                      error={!!form2?.formState?.errors?.name}
                      helperText={form2?.formState?.errors?.name && form2?.formState?.errors?.name?.message}
                    />
                  </Grid>
                  <Grid className="unit-of-measures" item xs={12} md={6}>
                    <FormInput
                      label="Unit of Measure"
                      placeholder="e.g Per item"
                      // fontSize="0.9375rem"
                      // fontWeight={600}
                      InputProps={{
                        ...form2?.register('unitOfMeasure'),
                      }}
                      width="90%"
                      error={!!form2?.formState?.errors?.unitOfMeasure}
                      helperText={form2?.formState?.errors?.unitOfMeasure && form2?.formState?.errors?.unitOfMeasure?.message}
                    />
                  </Grid>
                  <Grid className="category" item xs={12} md={6}>
                    <label id="category-select" style={{ fontSize: '0.8600rem' }}>
                      Category
                    </label>
                    <CustomSelect
                      width="90%"
                      name="categoryID"
                      // InputProps={{ ...register('customerID') }}
                      control={form2?.control}
                      options={categoryFieldOptions}
                      label="Select a category"
                      // value={watch('customerID')}
                      error={!!form2?.formState?.errors?.categoryID}
                      helperText={form2?.formState?.errors?.categoryID && form2?.formState?.errors?.categoryID?.message}
                      // placeholder=Chooseexpiration date"
                    />
                  </Grid>
                  <Grid className="type" item xs={12} md={4}>
                    <Box
                      sx={{
                        // '& .css-dmmspl-MuiFormGroup-root': { mt: 0.5 },
                        '& .css-14r9679-MuiFormLabel-root.Mui-focused': { color: '#212B36' },
                        '& .css-14r9679-MuiFormLabel-root': { color: '#212B36', fontWeight: '600' },
                        '& .MuiFormLabel-root .Mui-error ': { color: '#212B36', fontWeight: '600' },
                        mt: 1,
                      }}
                    >
                      <GroupCheckbox
                        error={!!form2?.formState?.errors?.type}
                        helperText={form2?.formState?.errors?.type && form2?.formState?.errors?.type?.message}
                        label="Type"
                      >
                        <FormRadioGroup row fontSize={18} options={typeOptions} control={form2.control} name="type" />
                      </GroupCheckbox>
                    </Box>
                  </Grid>
                  <Grid mt={1} item xs={12} md={4}>
                    <FormInput
                      endAdornment={<InputAdornment position="end">%</InputAdornment>}
                      type="number"
                      label="Relaxation Allowance"
                      placeholder="e.g 1"
                      // fontSize="0.9375rem"
                      // fontWeight={600}
                      InputProps={{
                        ...form2?.register('relaxationAllowance'),
                      }}
                      width="18ch"
                      error={!!form2?.formState?.errors?.relaxationAllowance}
                      helperText={
                        form2?.formState?.errors?.relaxationAllowance && form2?.formState?.errors?.relaxationAllowance?.message
                      }
                    />
                  </Grid>
                  <Grid mt={1} item xs={12} md={4}>
                    <FormInput
                      type="number"
                      label="Contingency Allowance"
                      placeholder="e.g 1"
                      endAdornment={<InputAdornment position="end">%</InputAdornment>}
                      // fontSize="0.9375rem"
                      // fontWeight={600}
                      InputProps={{
                        ...form2?.register('contingencyAllowance'),
                      }}
                      width="18ch"
                      error={!!form2?.formState?.errors?.contingencyAllowance}
                      helperText={
                        form2?.formState?.errors?.contingencyAllowance && form2?.formState?.errors?.contingencyAllowance?.message
                      }
                    />
                  </Grid>

                  <Grid className="count" item xs={12} md={12}>
                    <ThemeSwitch
                      description="Relevant only on Role and Activity studies"
                      label="Count"
                      control={form2?.control}
                      name="count"
                      width="90%"
                    />
                  </Grid>
                  <Grid className="study-types" item xs={12} md={12}>
                    <CheckboxGroup
                      row
                      control={form2?.control}
                      label="Study Types"
                      name="studyTypes"
                      options={studyTypeOptions}
                      config={studyTypeConfig}
                    />
                  </Grid>
                  <Grid className="rating" item xs={12} md={12}>
                    <Box
                      sx={{
                        '& .css-dmmspl-MuiFormGroup-root': { mt: 0.5 },
                        '& .css-14r9679-MuiFormLabel-root.Mui-focused': { color: '#212B36' },
                        '& .css-14r9679-MuiFormLabel-root': { color: '#212B36', fontWeight: '600' },
                        '& .MuiFormLabel-root .Mui-error ': { color: '#212B36', fontWeight: '600' },
                        width: '80%',
                      }}
                    >
                      <GroupCheckbox
                        error={!!form2?.formState?.errors?.rating}
                        helperText={form2?.formState?.errors?.rating && form2?.formState?.errors?.rating?.message}
                        label="Default Rating"
                      >
                        <FormRadioGroup fontSize={18} options={ratingsOptions} control={form2?.control} name="rating" />
                      </GroupCheckbox>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid className="right-side" item xs={12} md={6} lg={6}>
                <Grid rowSpacing={1} columnSpacing={1} container>
                  {studyTypesValues && (studyTypesValues.includes(2) || studyTypesValues.includes(3)) && (
                    <Grid item xs={12} lg={12} md={12}>
                      {/* <FormHelperText style={{ fontSize: '0.8600rem', marginBottom: 8, fontWeight: 'unset' }}>Tasks</FormHelperText> */}
                      <Stack mb={2} justifyContent="space-between" direction="row">
                        <Stack width="100%" direction="column">
                          <Typography variant="subtitle2">Tasks</Typography>
                          {get(errors, 'elements.root.message') && (
                            <Typography sx={{ color: 'error.main' }} variant="caption">
                              {get(errors, 'elements.root.message')}
                            </Typography>
                          )}
                        </Stack>
                      </Stack>
                      {fields2?.length > 0 ? (
                        <Stack>
                          {fields2.map((field, index) => (
                            <Stack key={field?.id} width="100%" direction="column" gap mb={1}>
                              <Stack gap direction="row" width="100%">
                                <Box width="100%">
                                  <Card
                                    onClick={() => rowClicked(field?.taskID)}
                                    sx={{
                                      cursor: 'pointer',
                                      p: 2,
                                      bgcolor: '#f5f5f5f0',
                                      '&:hover': {
                                        backgroundColor: '#ebe9e9f0',
                                      },
                                    }}
                                  >
                                    <Stack width="100%" alignItems="center" justifyContent="space-between" direction="row">
                                      <Typography fontWeight="bold" variant="caption">
                                        {field?.taskName}
                                      </Typography>
                                      {collapsedRow === field?.taskID ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    </Stack>
                                  </Card>
                                </Box>
                                <IconButton onClick={() => remove(index)} sx={{ mt: 0 }}>
                                  <CancelIcon color="error" />
                                </IconButton>
                              </Stack>
                              <Collapse in={collapsedRow === field?.taskID}>
                                <DndContext
                                  modifiers={[restrictToFirstScrollableAncestor, restrictToVerticalAxis]}
                                  // autoScrol={false}
                                  sensors={sensors}
                                  collisionDetection={closestCenter}
                                  onDragEnd={(event) => handleDragEnd(event, field, index)}
                                >
                                  {get(tasksValues, `${index}.elements`) && (
                                    <SortableContext
                                      items={get(tasksValues, `${index}.elements`, [])}
                                      strategy={verticalListSortingStrategy}
                                    >
                                      <Box
                                        py={2}
                                        sx={{
                                          bgcolor: '#f5f5f5f0',
                                          overflowY: 'auto',
                                          maxHeight: 300,
                                          width: 'calc(100% - 50px)',
                                        }}
                                      >
                                        {get(tasksValues, `${index}.elements`)?.length > 0 &&
                                          tasksValues[index]?.elements?.map((element) => {
                                            const elementFound = elements.find((value) => value?._id === element);
                                            if (elementFound || element === tempID) {
                                              return (
                                                <SortableElement sx={{ cursor: 'grab !important' }} id={element} key={element}>
                                                  <Box
                                                    gap={2}
                                                    p={2}
                                                    my={1}
                                                    width={500}
                                                    height={20}
                                                    alignItems="center"
                                                    display="flex"
                                                  >
                                                    <DragIndicatorIcon sx={{ color: '#636c72ed' }} fontSize="small" />
                                                    <Typography
                                                      sx={{ color: element === tempID ? 'primary.main' : 'text.main' }}
                                                      variant="subtitle2"
                                                    >
                                                      {element === tempID
                                                        ? currentElementname || 'Current Element'
                                                        : elements.find((value) => value?._id === element)?.name}
                                                    </Typography>
                                                  </Box>
                                                </SortableElement>
                                              );
                                            }
                                            return null;
                                          })}
                                      </Box>
                                    </SortableContext>
                                  )}
                                </DndContext>
                              </Collapse>
                            </Stack>
                          ))}
                        </Stack>
                      ) : null}
                      <Stack direction="row" width="100%" justifyContent="flex-end">
                        <ThemeButton onClick={addTaskClick}>Add Task</ThemeButton>
                        <Menu
                          id="long-menu"
                          MenuListProps={{
                            'aria-labelledby': 'long-button',
                          }}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          // PaperProps={{
                          //   style: {
                          //     maxHeight: ITEM_HEIGHT * 4.5,
                          //     width: '20ch',
                          //   },
                          // }}
                        >
                          {tasksOptions && tasksOptions?.length > 0 ? (
                            tasksOptions.map((option) => (
                              <MenuItem
                                value={option?.value}
                                key={option?.value}
                                onClick={(event) => handleSelectTask(event, option?.value)}
                                disabled={tasksValues.some((task) => task?.taskID === option?.value)}
                              >
                                {option?.label}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem>
                              <i>No task available</i>
                            </MenuItem>
                          )}
                        </Menu>
                      </Stack>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Stack sx={{ width: { md: '50%', sm: '100%' } }}>
              <label htmlFor="elementID" style={{ fontSize: '0.8600rem' }}>
                Add Element
              </label>
              {/* <FormInput
                      endAdornment={<InputAdornment position="end">%</InputAdornment>}
                      type="number"
                      label="Relaxation Allowance"
                      placeholder="e.g 1"
                      // fontSize="0.9375rem"
                      // fontWeight={600}
                      InputProps={{
                        ...form2?.register('relaxationAllowance'),
                      }}
                      width="18ch"
                      error={!!form2?.formState?.errors?.relaxationAllowance}
                      helperText={
                        form2?.formState?.errors?.relaxationAllowance && form2?.formState?.errors?.relaxationAllowance?.message
                      }
                    /> */}
              <CustomSelect
                id="elementID"
                width="95%"
                control={form2?.control}
                name="currentElement"
                options={elementSelectOptions || []}
                label="Select an element"
                error={!!form2?.formState?.errors?.currentElement}
                helperText={form2?.formState?.errors?.currentElement && form2?.formState?.errors?.currentElement?.message}
              />
            </Stack>
          )}
        </form>
      </ModalComponent>
      <ModalComponent
        persist
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '600px !important',
            maxHeight: '700px !important',
          },
        }}
        title="Filter"
        open={isFilterOpen}
        onClose={setIsFilterOpen}
      >
        <FilterModal
          setFilterConfig={setFilterConfig}
          filterConfig={filterConfig}
          filterOptions={filterOptions}
          setFilterBy={setFilterBy}
          closeFilter={closeFilter}
          filterBy={filterBy}
          sortBy={sortBy}
        />
      </ModalComponent>
      <ModalComponent
        persist
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '500px !important',
            maxHeight: '700px !important',
          },
        }}
        title="Hide/Show Columns"
        open={isColumnsModalOpened}
        onClose={setColumnsModal}
        closeButton
      >
        <Box>
          {study?.studyType &&
            (study?.studyType === 1 ? rasHeadCells : headCells)?.map(
              (cell) =>
                cell?.id !== 'isEc' && (
                  <ThemeSwitch
                    label={cell?.label}
                    onChange={(event) => handleColumnChange(event, cell)}
                    checked={isCellVisible(cell)}
                  />
                )
            )}
        </Box>
      </ModalComponent>
      <ModalComponent
        persist
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '1200px !important',
          },
        }}
        title="Create Observation"
        open={isObsModalOpen}
        onClose={closeObsModal}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid rowSpacing={1} columnSpacing={3} container>
            <Grid sx={{ '& .MuiFormControl-root': { marginBottom: 0 } }} className="name" item xs={12} md={12} lg={6}>
              <label style={{ fontSize: '0.8600rem' }}>Role</label>
              <CustomSelect
                name="roleID"
                label="Select Role"
                control={control}
                options={rolesOptions}
                error={!!errors?.roleID}
                helperText={errors?.roleID && errors?.roleID?.message}
              />
            </Grid>
            <Grid sx={{ '& .MuiFormControl-root': { marginBottom: 0 } }} className="name" item xs={12} md={12} lg={6}>
              <label style={{ fontSize: '0.8600rem' }}>Area</label>
              <CustomSelect
                name="areaID"
                label="Select Area"
                control={control}
                options={areasOptions}
                error={!!errors?.areaID}
                helperText={errors?.areaID && errors?.areaID?.message}
              />
            </Grid>
            <Grid sx={{ '& .MuiFormControl-root': { marginBottom: 0 } }} className="name" item xs={12} md={12} lg={6}>
              <label style={{ fontSize: '0.8600rem' }}>Element</label>
              <CustomSelect
                name="elementID"
                label="Select Element"
                control={control}
                options={elementSelectOptions}
                error={!!errors?.elementID}
                helperText={errors?.elementID && errors?.elementID?.message}
              />
            </Grid>
            <Grid sx={{ '& .MuiFormControl-root': { marginBottom: 0 } }} className="name" item xs={12} md={12} lg={6}>
              <label style={{ fontSize: '0.8600rem' }}>Rating</label>
              <CustomSelect
                name="rating"
                label="Select Rating"
                control={control}
                options={obsRatingOptions}
                error={!!errors?.rating}
                helperText={errors?.rating && errors?.rating?.message}
                isDisabled={() => elementRating === 1 || elementRating === 2}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormInput
                label="Frequency"
                type="number"
                placeholder="Write frequency"
                disabled={!elementCount}
                InputProps={{
                  name: frequencyInputFields.name,
                  onblur: frequencyInputFields.onBlur,
                  ref: frequencyInputFields.ref,
                  value: frequencyValue,
                  onChange: (event) => {
                    const newValue = event.target.value;
                    if (newValue === '' || Number(newValue) >= 0) {
                      return frequencyInputFields.onChange(event);
                    }
                    return null;
                  },
                }}
                error={!!errors?.frequency}
                helperText={errors?.frequency && errors?.frequency?.message}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormInput
                label="Notes"
                placeholder="Write your notes"
                InputProps={{ ...register('notes') }}
                error={!!errors?.notes}
                helperText={errors?.notes && errors?.notes?.message}
              />
            </Grid>
          </Grid>

          <DialogActions sx={{ mt: 2, px: 0, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <ThemeButton type="button" onClick={closeElementModal} color="error" variant="outlined">
              Cancel
            </ThemeButton>
            <ThemeButton loading={addObservationLoading} type="submit" variant="contained">
              Create Observation
            </ThemeButton>
          </DialogActions>
        </form>
      </ModalComponent>
      <ModalComponent
        persist
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '1600px !important',
          },
        }}
        title={`Are you sure you want to add ${currentMissingRound?.formattedStartTime} missing round in the Observations list?`}
        open={isMissingModalOpened}
        onClose={closeMissingModal}
      >
        <ReusableTabs tabs={tabs} selectedTab={selectedTab} handleChange={onTabChange} />
        {selectedTab === 'beforeRound' ? (
          <DataTable
            key="beforeObs"
            filterKeys={['taskName', 'elementName', 'startTime']}
            customToolbar={Types.toolbarTypes.ADD_OBS}
            // headCells={study?.studyType === 1 ? filteredRasHeadCells : filteredDotsHeadCells}
            headCells={addObsHeadCells}
            rows={beforeRoundRows}
            disableActions
            toolbarProps={obsToolbarProps}
            hideCompactViewButton
            defaultRowsPerPage={10}
            checkRowCondition={checkRowCondition}
            enableCheckbox
            disableCheckboxIf={(row) =>
              Object.entries(row).some(([key, value]) => value === 'Removed From HUB' || value === 'Removed From Project')
            }
            // isLoading={singleStudyLoading}
          />
        ) : (
          <DataTable
            key="afterObs"
            filterKeys={['taskName', 'elementName', 'startTime']}
            customToolbar={Types.toolbarTypes.ADD_OBS_AFTER}
            // headCells={study?.studyType === 1 ? filteredRasHeadCells : filteredDotsHeadCells}
            headCells={addObsHeadCells}
            rows={afterRounRows}
            disableActions
            toolbarProps={aftObsToolbarProps}
            checkRowCondition={checkRowCondition}
            hideCompactViewButton
            defaultRowsPerPage={10}
            enableCheckbox
            disableCheckboxIf={(row) =>
              Object.entries(row).some(([key, value]) => value === 'Removed From HUB' || value === 'Removed From Project')
            }
            // isLoading={singleStudyLoading}
          />
        )}
        <DialogActions sx={{ mt: 2, px: 0, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <ThemeButton type="button" onClick={closeMissingModal} color="error" variant="outlined">
            Cancel
          </ThemeButton>
        </DialogActions>
      </ModalComponent>
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb>
        <Stack alignItems="center" gap direction="row">
          <Stack alignItems="center" direction="row">
            <Typography variant="subtitle1">Studies</Typography>
            <KeyboardArrowRightIcon fontSize="small" />
          </Stack>
          <Typography variant="body1">{study?.name}</Typography>
          <Chip
            size="small"
            label={getStudyLabel(study?.studyType)}
            sx={{ bgcolor: getStudyColor(study?.studyType), color: 'white' }}
          />
          <Typography sx={{ color: '#808285', fontWeight: '600' }}>
            {singleStudyRows && singleStudyRows?.length} Observations{' '}
            {singleStudyData?.roleName && (
              <>
                for <span style={{ fontWeight: 'bold', color: 'black' }}>{getTitledWord(singleStudyData?.roleName)}</span>
              </>
            )}
          </Typography>
        </Stack>
        <Stack alignItems="center" gap direction="row">
          <ThemeButton onClick={() => dispatch(updateSingleStudy(null))} variant="outlined" size="small">
            Back to Studies
          </ThemeButton>
          <ThemeButton onClick={handleMarkReview} loading={markStudyLoading} variant="outlined" size="small">
            Mark as Reviewed
          </ThemeButton>
          <ThemeButton onClick={downloadAllPhotos} variant="outlined" size="small">
            Download Photos
          </ThemeButton>
        </Stack>
      </Stack>
      {singleStudyData?.answers && singleStudyData?.answers?.length > 0 && (
        <Accordion
          sx={{
            '& .css-122oo7h-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded': {
              minHeight: 'unset',
            },
            '& .css-o4b71y-MuiAccordionSummary-content.Mui-expanded': {
              margin: '16px 0px',
            },
            '& .css-imub8g-MuiPaper-root-MuiAccordion-root.Mui-expanded ': {
              margin: '0px',
            },
            my: 1,
            mb: 2,
          }}
          expanded={isAnswerShowed}
          onChange={(event, isExpanded) => setIsAnswerShowed(isExpanded)}
        >
          <AccordionSummary
            sx={{ bgcolor: '#eceeef' }}
            expandIcon={<ExpandMoreIcon sx={{ color: 'primary.main' }} />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{ fontSize: '0.94rem', fontWeight: '600' }}>Study Answers</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {singleStudyData?.answers?.length > 0 &&
              singleStudyData?.answers?.map((questionObject) => (
                <Typography sx={{ fontSize: '0.86rem', display: 'flex', alignItems: 'center', gap: '16px' }}>
                  <b style={{ minWidth: '240px' }}>{questionObject?.question}</b>
                  <p>{` ${questionObject?.answer.replace(/,/g, ' ')}`}</p>
                </Typography>
              ))}
          </AccordionDetails>
        </Accordion>
      )}
      {missingRounds?.length > 0 && (
        <Stack>
          <Typography variant="body1">Missing Rounds</Typography>
          <Stack my={2} direction="row" gap={2}>
            {missingRounds?.map((round) => (
              // <Chip label={round} sx={{ bgcolor: '#ff53423d', fontWeight: 'bold', cursor: 'pointer' }} />
              <ThemeButton
                sx={{
                  bgcolor: '#FF474D',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                  color: '#fff',
                  '&:hover': { bgcolor: '#FF474D', outline: '1px solid #ff53428a' },
                }}
                size="small"
                onClick={() => handleMissingRoundClicked(round)}
              >
                {round?.formattedStartTime}
              </ThemeButton>
            ))}
          </Stack>
        </Stack>
      )}
      {/* {invalidDataRows && invalidDataRows?.length > 0 && (
        <Stack mb={2} p={2}>
          <DataTable
            filterKeys={['taskName', 'elementName', 'startTime', 'roleName']}
            noToolbar
            // customToolbar={Types.toolbarTypes.SINGLE_STUDY}
            // headCells={singleStudyData?.studyType === 1 ? filteredRasHeadCells : filteredDotsHeadCells}
            headCells={finalHeadCells}
            rows={invalidDataRows}
            filterColumns={filterConfig?.filterColumns}
            filterBy={filterConfig?.filterBy}
            sortOrder={filterConfig?.sortOrder}
            sortBy={filterConfig?.sortBy}
            onSettingsClick={() => setColumnsModal(true)}
            setElementFilterConfig={setFilterConfig}
            customColumnType="studyType"
            nestedRowKey={Types.nestedRowTypes.STUDY}
            nestedRowProps={nestedRowProps}
            onEditClick={onEditClick}
            disableNestedRowClick
            hideNestedArrow
            toolbarProps={toolbarProps}
            onRowDelete={onDelete}
            enableDelete
            hideCompactViewButton
            defaultRowsPerPage={5}
            isLoading={singleStudyLoading}
            addObservation={addObservation}
          />
        </Stack>
      )} */}
      {singleStudyRows && (
        <DataTable
          filterKeys={['taskName', 'elementName', 'startTime', 'roleName', 'areaName', 'categoryName']}
          customToolbar={Types.toolbarTypes.SINGLE_STUDY}
          // headCells={study?.studyType === 1 ? filteredRasHeadCells : filteredDotsHeadCells}
          headCells={finalHeadCells}
          filterConfig={filterConfig}
          enableActions
          rows={singleStudyRows}
          filterColumns={filterConfig?.filterColumns}
          filterBy={filterConfig?.filterBy}
          sortOrder={filterConfig?.sortOrder}
          sortBy={filterConfig?.sortBy}
          onSettingsClick={() => setColumnsModal(true)}
          setElementFilterConfig={setFilterConfig}
          customColumnType="studyType"
          nestedRowKey={Types.nestedRowTypes.STUDY}
          nestedRowProps={nestedRowProps}
          onEditClick={onEditClick}
          handleRowClick={onEditClick}
          disableNestedRowClick
          hideNestedArrow
          enableCheckbox
          toolbarProps={toolbarProps}
          onRowDelete={onDelete}
          enableDelete
          hideCompactViewButton
          defaultRowsPerPage={-1}
          maxHeight={500}
          isLoading={singleStudyLoading}
          addObservation={addObservation}
          disableAddObservation={study?.studyType !== 1}
        />
      )}
    </>
  );
};

SingleStudy.propTypes = {
  study: PropTypes.object,
  projectID: PropTypes.string,
  customerID: PropTypes.string,
};

export default SingleStudy;
