/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-extraneous-dependencies */
import { DndContext, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Backdrop,
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  InputAdornment,
  LinearProgress,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { restrictToFirstScrollableAncestor, restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import SettingsIcon from '@mui/icons-material/Settings';
import { visuallyHidden } from '@mui/utils';
import { jwtDecode } from 'jwt-decode';
import { capitalize, get, has } from 'lodash';
import { useSelector } from 'react-redux';
import { TableVirtuoso } from 'react-virtuoso';
import Cookies from 'universal-cookie';
import CustomerLocationsToolbar from '../../pages/customer/CustomerLocationsToolbar';
import CustomersToolbar from '../../pages/customer/CustomersToolbar';
import HoursMappingToolbar from '../../pages/data/HoursMappingToolbar';
import HoursNestedRow from '../../pages/data/HoursNestedRow';
import GroupsToolbar from '../../pages/groupRole/GroupsToolbar';
import NotesNestedRow from '../../pages/notes/NotesNestedRow';
import NotesToolbar from '../../pages/notes/NotesToolbar';
import AreasToolbar from '../../pages/projectList/areas/AreasToolbar';
import ElementToolbar from '../../pages/projectList/elements/ElementToolbar';
import LocationsToolbar from '../../pages/projectList/location/LocationsToolbar';
import RolesToolbar from '../../pages/projectList/roles/RolesToolbar';
import LogsNestedRow from '../../pages/projectLogs/logsNestedRow';
// eslint-disable-next-line import/no-cycle
import FieldUserNestedPage from '../../pages/user/field/FieldUserNestedPage';
import ProjectLogsToolbar from '../../pages/projectLogs/ProjectLogsToolbar';
import RemindersNestedRow from '../../pages/reminder/RemindersNestedRow';
import AddObsAfterToolbar from '../../pages/studiesPage/addObsAfterToolbar';
import AddObsToolbar from '../../pages/studiesPage/addObsToolbar';
import SingleStudyNestedRow from '../../pages/studiesPage/singleStudyNestedRow';
import SingleStudyToolbar from '../../pages/studiesPage/singleStudyToolbar';
import StudiesNestedRow from '../../pages/studiesPage/studiesNestedRow';
import StudiesToolbar from '../../pages/studiesPage/studiesToolbar';
import TasksNestedRow from '../../pages/tasksPage/TasksNestedRow';
import TasksToolbar from '../../pages/tasksPage/TasksToolbar';
import { Types } from '../../types';
import ConfirmDialog from '../ConfirmDialog';
import Iconify from '../Iconify';
import { BootstrapTooltip } from '../ThemeTooltip';
import FieldUSerToolbar from './FieldUSerToolbar';
import ProjectToolbar from './projectToolbar';
import ReorderTableBody from './ReorderTableBody';
import { StyledSearch, applyFilter } from './utils';
// eslint-disable-next-line import/no-cycle
import CategoriesToolbar from '../../pages/projectList/category/CategoriesToolbar';
import QuestionsToolbar from '../../pages/question/QuestionsToolbar';

const descendingComparator = (a, b, orderBy) => {
  const aValue = a[orderBy];
  const bValue = b[orderBy];
  if (typeof bValue === 'string' && typeof aValue === 'string') {
    // Compare as strings (case-insensitive)
    if (bValue.toLowerCase() < aValue.toLowerCase()) {
      return -1;
    }
    if (bValue.toLowerCase() > aValue.toLowerCase()) {
      return 1;
    }
  } else {
    // Directly compare values (no case change for non-strings)
    if (bValue < aValue) {
      return -1;
    }
    if (bValue > aValue) {
      return 1;
    }
  }
  return 0;
};
const getComparator = (order, orderBy) =>
  order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order = '',
    orderBy = '',
    numSelected = '',
    rowCount = '',
    onRequestSort = '',
    headCells = '',
    noActions = '',
    isAllChecked,
    disableCheckbox,
    enableCheckbox,
    enableActions,
    disableActions,
    setElementFilterConfig,
    setOrderBy,
    setOrder,
    customColumnType,
    onSettingsClick,
    role,
    filterConfig,
    ActionWidth,
  } = props;
  const createSortHandler = (property) => (event) => {
    if (setElementFilterConfig) {
      setElementFilterConfig((prev) => ({ ...prev, sortOrder: '', sortBy: '' }));
    }
    onRequestSort(event, property);
  };
  const isActiveFilter = (activeId) => {
    if (filterConfig && Object.keys(get(filterConfig, 'filters')).length > 0) {
      return (
        (filterConfig?.filterId && filterConfig?.filterId === activeId) ||
        Object.keys(get(filterConfig, 'filters')).some((keyName) => keyName === activeId)
      );
    }
    return false;
  };
  return (
    <TableHead>
      <TableRow>
        {!disableCheckbox && (role === 1 || role === 2 || enableCheckbox) && (
          <TableCell sx={{ bgcolor: '#F9FAFB' }} padding="checkbox">
            <Checkbox
              size="small"
              color="primary"
              // indeterminate={numSelected > 0 && numSelected < rowCount}
              // checked={rowCount > 0 && numSelected === rowCount}
              checked={isAllChecked}
              onChange={onSelectAllClick}
              onClick={(event) => onSelectAllClick(event)}
              disabled={rowCount <= 0}
            />
          </TableCell>
        )}
        {headCells?.map((headCell) => {
          let a;
          return (
            <TableCell
              key={headCell.id}
              // align={headCell.numeric ? 'right' : 'left'}
              align={headCell.align || 'left'}
              sx={{ pl: headCell.pl || 'none', bgcolor: '#F9FAFB', minWidth: headCell?.minWidth, maxWidth: headCell?.maxWidth }}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
              colSpan={headCell?.colSpan}
              style={{ width: headCell?.width, minWidth: headCell?.width, whiteSpace: 'nowrap' }}
            >
              <TableSortLabel
                active={orderBy === headCell?.id}
                direction={orderBy === headCell?.id ? order : 'asc'}
                // onClick={() => handleSortClick(headCell)}
                // onClick={headCell?.disableSort ? null : headCell?.onClick || createSortHandler(headCell?.id)}
                onClick={(event) => {
                  if (!headCell?.disableSort) {
                    // Check if there's a custom onClick function defined for this head cell
                    if (headCell?.onClick) {
                      setOrder('');
                      setOrderBy('');
                      headCell.onClick();
                    } else {
                      createSortHandler(headCell?.id)(event);
                    }
                  }
                }}
                sx={{ fontWeight: 'bold', fontSize: '14px' }}
              >
                {headCell?.label || ''} {isActiveFilter(headCell.id) && <Typography sx={{ color: 'red', pl: 0.5 }}>*</Typography>}
                {orderBy === headCell?.id ? (
                  <Box component="span" sx={visuallyHidden && visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
        {!noActions && (enableActions || role === 1 || role === 2) && (
          <TableCell
            align="center"
            sx={{
              bgcolor: '#F9FAFB',
              fontWeight: 'bold',
              // minWidth: 120,
              fontSize: '14px',
              width: ActionWidth || 'unset',
            }}
          >
            Actions{' '}
            {customColumnType && onSettingsClick && (
              <IconButton onClick={(event) => onSettingsClick(event)}>
                <SettingsIcon />
              </IconButton>
            )}
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCells: PropTypes.arrayOf(PropTypes.object),
  noActions: PropTypes.bool,
  isAllChecked: PropTypes.bool,
  disableCheckbox: PropTypes.bool,
  enableCheckbox: PropTypes.bool,
  setElementFilterConfig: PropTypes.func,
  setOrder: PropTypes.func,
  filterConfig: PropTypes.func,
  setOrderBy: PropTypes.func,
  enableActions: PropTypes.bool,
  disableActions: PropTypes.bool,
  customColumnType: PropTypes.string,
  ActionWidth: PropTypes.any,
  role: PropTypes.string,
  onSettingsClick: PropTypes.func,
};

const EnhancedTableToolbar = (props) => {
  const { selected = '', handleSelectionClick = null, filterName = '', onFilterName = '', searchLable = '' } = props;
  const numSelected = selected.length;
  return (
    <Toolbar
      sx={{
        height: 76,
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        display: 'flex',
        justifyContent: 'space-between',
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
      className="toolbar-wrapper"
    >
      {numSelected > 0 ? (
        <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
          {numSelected} {numSelected > 1 ? 'rows' : 'row'} selected
        </Typography>
      ) : null}

      {numSelected > 0 ? (
        // <Tooltip title="Delete">
        //   <IconButton>
        //     <DeleteIcon />
        //   </IconButton>
        // </Tooltip>
        <Stack gap direction="row" whiteSpace="nowrap">
          <Button variant="outlined" onClick={() => handleSelectionClick && handleSelectionClick(selected, 'archieve')}>
            Archieve All
          </Button>
          <Button variant="outlined" onClick={() => handleSelectionClick && handleSelectionClick(selected, 'activate')}>
            Activate All
          </Button>
        </Stack>
      ) : (
        // <Tooltip title="Filter list">
        //   <IconButton>
        //     <FilterListIcon />
        //   </IconButton>
        // </Tooltip>
        <StyledSearch
          value={filterName}
          onChange={onFilterName}
          placeholder={searchLable || 'Search user...'}
          startAdornment={
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          }
        />
      )}
    </Toolbar>
  );
};
EnhancedTableToolbar.propTypes = {
  selected: PropTypes.array.isRequired,
  handleSelectionClick: PropTypes.func,
  filterName: PropTypes.string.isRequired,
  searchLable: PropTypes.string.isRequired,
  onFilterName: PropTypes.func.isRequired,
};
const getRowColor = (row) => {
  if (row?.roleName && row?.roleName === 'Removed From HUB') {
    return (theme) => alpha(theme.palette.error.main, 0.07);
  }
  if (Object.entries(row).some(([key, value]) => value === 'Removed From HUB' || value === 'Removed From Project')) {
    return (theme) => alpha(theme.palette.error.main, 0.07);
  }
  if (row?.isAdded) {
    return (theme) => alpha(theme.palette.warning.dark, 0.1);
  }
  if (row?.isDuplicateStudy) {
    return (theme) => alpha(theme.palette.warning.main, 0.07);
  }
  if (row?.status && row?.status === Types.ARCHIVED) {
    return (theme) => alpha(theme.palette.error.main, 0.07);
  }
  return '';
};
const VirtuosoTableComponents = {
  Scroller: forwardRef(({ context: { maxHeight, rowsLength }, ...props }, ref) => (
    <TableContainer
      sx={{ maxHeight: maxHeight && maxHeight, minHeight: !(rowsLength > 0) && 100 }}
      component={Paper}
      {...props}
      ref={ref}
    />
  )),
  Table: ({ item: row, context: { dense }, ...props }) => (
    <Table {...props} size={dense ? 'small' : 'medium'} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
  ),
  TableHead,
  TableRow: ({
    item: row,
    context: {
      isSelected,
      nestedRowKey,
      disableNestedRowClick,
      nestedRowClick,
      handleRowClick,
      onRowClick,
      nestedCollapsedRows,
      renderNestedRow,
    },
    ...props
  }) => {
    const isItemSelected = isSelected(row?._id);
    const isNestedRowCollapsed = (id) => nestedCollapsedRows.indexOf(id) !== -1;
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        <TableRow
          {...props}
          hover
          // style={{
          //   height: calculateHeight?.(),
          // }}
          onClick={(event) =>
            handleRowClick ? onRowClick(event, row) : nestedRowKey && !disableNestedRowClick && nestedRowClick(event, row)
          }
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={row.id}
          selected={isItemSelected}
          sx={{
            bgcolor: getRowColor(row),
            cursor: 'pointer',
            '&.Mui-selected': {
              bgcolor: (theme) => alpha(theme.palette.primary.light, 0.2),
            },
          }}
        />
        {!!nestedRowKey && isNestedRowCollapsed(row?._id) && renderNestedRow({ rowType: nestedRowKey, row })}
      </>
    );
  },
  TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
  EmptyPlaceholder: ({ context: { maxHeight, rowsLength, filterName }, ...props }) => (
    <TableRow>
      <TableCell sx={{ borderBottom: '1px solid transparent' }} colSpan={12}>
        {' '}
        <Box alignItems="center" display="flex" justifyContent="center" sx={{ height: 100, width: '100%' }}>
          <Typography variant="subtitle1" textAlign="center">
            {`No data found ${filterName && 'for '} `} <b>{filterName && filterName}</b>
          </Typography>
        </Box>
      </TableCell>
    </TableRow>
  ),
};

const EnhancedTable = (props) => {
  const {
    disabbleNestedArrowIf,
    disableCheckboxIf,
    hideEditButton,
    statusUpdateUrl,
    isGlobalFilter,
    checkRowCondition,
    noToolbar,
    enableDelete,
    singleNestedRow,
    addObservation,
    sortOrder,
    sortBy,
    isLoading,
    disableActions,
    enableActions,
    hideCompactViewButton,
    childKey,
    groupHeadCells,
    headCells,
    rows,
    minWidth,
    filterCallback,
    filterKeys,
    customToolbar,
    nestedRowKey,
    toolbarProps = {},
    maxHeight,
    stickyHeader,
    handleDragEnd,
    defaultRowsPerPage = 5,
    handleRowClick,
    onEditClick,
    onRowStatusChange,
    onRowDelete,
    filterColumns,
    filterBy,
    disableNestedRowClick,
    hideNestedArrow,
    nestedRowProps,
    setElementFilterConfig,
    hideNestedFunc,
    disableCheckbox,
    enableCheckbox,
    customColumnType,
    onSettingsClick,
    disableAddObservation,
    downloadStudyRow,
    filterConfig,
    ActionWidth,
  } = props; // either filter by filterCallback or filterKeys

  const [order, setOrder] = useState('asc');
  const [filterName, setFilterName] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [selected, setSelected] = useState([]);
  const [selectedParent, setSelectedParent] = useState([]);
  const [allStatusModelOpened, setAllStatusModelOpened] = useState(false);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
  const [CollapsedRows, setCollapsedRows] = useState([]);
  const [nestedCollapsedRows, setNestedCollapsedRows] = useState([]);
  const [selectedRowToChangeStatus, setSelectedFieldToChangeStatus] = useState(null);
  const [selectedRowToDelete, setSelectedFieldToDelete] = useState(null);
  const [isStatusModelOpen, setStatusModelOpen] = useState(false);
  const [isDeleteModelOpen, setDeleteModelOpen] = useState(false);
  const [statusType, setStatusType] = useState('');
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [statusToChange, setStatusToChange] = useState('');
  const { isGroupViewToggled } = useSelector((state) => state.project);
  const { isSelectedStudyLoading } = useSelector((state) => state.studies);
  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };
  const cookies = new Cookies();
  const jwtToken = cookies.get('token');
  const decoded = jwtToken && jwtDecode(jwtToken);
  const role = decoded?.role || '0';
  const dataFiltered = applyFilter({
    inputData: rows || [],
    comparator: getComparator(order, orderBy),
    filterName,
    filterCallback,
    filterKeys,
    filterColumns,
    filterBy,
    sortBy,
    sortOrder,
    filterConfig,
    // customerFilters: {
    //   Industry,
    //   Sector
    // }
  });
  const handleStatusButtonClick = (event, row, type) => {
    if (row && row?._id && onRowStatusChange) {
      setStatusType(type);
      setSelectedFieldToChangeStatus(row);
      setStatusModelOpen(true);
    }
  };
  const handleSelectionClick = (selectedUsers, type, setSelectedField) => {
    if (selected && !!selected?.length && type) {
      setStatusToChange(type);
      setAllStatusModelOpened(() => true);
    }
  };
  const handleDeleteClick = (event, row) => {
    if (row && row?._id && onRowDelete) {
      setSelectedFieldToDelete(row);
      setDeleteModelOpen(true);
    }
  };
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };
  const handleOpenBackdrop = () => {
    setOpenBackdrop(true);
  };
  const onConfirmStatusChange = (selectedField) => {
    if (statusType) {
      // setFilterName('');
      onRowStatusChange(selectedField, statusType, handleOpenBackdrop, handleCloseBackdrop);
      setStatusModelOpen(false);
    }
  };
  const onConfirmDelete = (selectedField) => {
    if (selectedField) {
      onRowDelete(selectedField, handleOpenBackdrop, handleCloseBackdrop);
      setDeleteModelOpen(false);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    if (property !== '') {
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    }
  };
  const visibleRows = useMemo(
    () =>
      // stableSort(rows, getComparator(order, orderBy)).slice(
      rowsPerPage === -1
        ? stableSort(dataFiltered, getComparator(order, orderBy))
        : stableSort(dataFiltered, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rowsPerPage, dataFiltered, order, orderBy, page, sortBy, sortOrder]
  );

  useEffect(() => {
    if (selected?.length > 0) {
      // Filter visible rows to only those that pass the checkRowCondition (if available)
      const filteredVisibleRows = visibleRows.filter((row) => !checkRowCondition || checkRowCondition(row));

      // Check if selected includes all of the filtered visible rows' IDs
      if (filteredVisibleRows.every((row) => selected.includes(row?._id))) {
        setIsAllChecked(true);
      } else {
        setIsAllChecked(false);
      }
    } else {
      setIsAllChecked(false);
    }
  }, [checkRowCondition, selected, visibleRows]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      if (!visibleRows[0]?._id) {
        return null;
      }
      // check duplicacy
      if (!groupHeadCells || !isGroupViewToggled) {
        setSelected((currentSelected) => {
          const uniqueSelectedRows = checkRowCondition
            ? visibleRows.filter((row) => !selected.includes(row?._id) && checkRowCondition(row))
            : visibleRows.filter((row) => !selected.includes(row?._id));
          return [...selected, ...uniqueSelectedRows.map((row) => row?._id)];
        });
      } else {
        const newSelectedParentRows = [];
        let newSelected = [];
        visibleRows.forEach((row) => {
          const Ids = row[childKey]?.map((n) => n?._id);
          newSelected = [...newSelected, ...Ids];
          newSelectedParentRows.push(row?._id);
        });
        setSelected(newSelected);
        setSelectedParent(newSelectedParentRows);
        setCollapsedRows(newSelectedParentRows);
      }
      return '';
    }
    setSelected((currentSelected) => {
      const selectedRows = visibleRows.map((row) => row?._id);
      const filteredSelectedRows = selected.filter((row) => !selectedRows.includes(row));
      return [...filteredSelectedRows];
    });
    return '';
  };
  const handleClearSelection = () => {
    setSelected([]);
    setSelectedParent([]);
    setCollapsedRows([]);
  };
  const handleClick = (event, id, childRows) => {
    event.stopPropagation();
    if (!id) {
      return null;
    }
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (childRows) {
      if (selectedParent.indexOf(id) === -1) {
        setSelectedParent([...selectedParent, id]);
        setCollapsedRows([...CollapsedRows, id]);
        childRows.forEach((childRow, childIndex) => {
          if (selected.indexOf(childRow?._id) === -1) {
            newSelected.push(childRow?._id);
          }
        });
        setSelected([...selected, ...newSelected]);
      } else {
        setCollapsedRows(CollapsedRows.filter((collapsedRow) => collapsedRow !== id));
        setSelectedParent(selectedParent.filter((parentRow) => parentRow !== id));
        setSelected(selected.filter((rowId) => !childRows.some((childRow) => childRow?._id === rowId)));
      }
    } else {
      if (selectedIndex === -1 && !childRows) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0 && !childRows) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1 && !childRows) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0 && !childRows) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      }

      setSelected(newSelected);
    }
    return null;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderToolbar = (toolbarType) => {
    switch (toolbarType) {
      case 'project':
        return (
          <ProjectToolbar
            handleClearSelection={handleClearSelection}
            selected={selected}
            filterName={filterName}
            onFilterName={handleFilterByName}
            toolbarProps={toolbarProps}
            setPage={setPage}
            handleOpenBackdrop={handleOpenBackdrop}
            handleCloseBackdrop={handleCloseBackdrop}
          />
        );
      case 'fieldUser':
        return (
          <FieldUSerToolbar
            handleClearSelection={handleClearSelection}
            selected={selected}
            filterName={filterName}
            onFilterName={handleFilterByName}
            toolbarProps={toolbarProps}
            setPage={setPage}
            handleOpenBackdrop={handleOpenBackdrop}
            handleCloseBackdrop={handleCloseBackdrop}
          />
        );
      case Types.toolbarTypes.SINGLE_STUDY:
        return (
          <SingleStudyToolbar
            handleClearSelection={handleClearSelection}
            selected={selected}
            filterName={filterName}
            onFilterName={handleFilterByName}
            toolbarProps={toolbarProps}
            setPage={setPage}
            setFilterName={setFilterName}
            visibleRows={visibleRows}
            rows={rows}
            handleOpenBackdrop={handleOpenBackdrop}
            handleCloseBackdrop={handleCloseBackdrop}
          />
        );
      case Types.toolbarTypes.NOTES:
        return (
          <NotesToolbar
            handleClearSelection={handleClearSelection}
            selected={selected}
            filterName={filterName}
            onFilterName={handleFilterByName}
            toolbarProps={toolbarProps}
            setPage={setPage}
            setFilterName={setFilterName}
            visibleRows={visibleRows}
            handleOpenBackdrop={handleOpenBackdrop}
            handleCloseBackdrop={handleCloseBackdrop}
          />
        );
      case Types.toolbarTypes.ADD_OBS:
        return (
          <AddObsToolbar
            handleClearSelection={handleClearSelection}
            selected={selected}
            filterName={filterName}
            onFilterName={handleFilterByName}
            toolbarProps={toolbarProps}
            setPage={setPage}
            setFilterName={setFilterName}
            visibleRows={visibleRows}
            handleOpenBackdrop={handleOpenBackdrop}
            handleCloseBackdrop={handleCloseBackdrop}
          />
        );
      case Types.toolbarTypes.ADD_OBS_AFTER:
        return (
          <AddObsAfterToolbar
            handleClearSelection={handleClearSelection}
            selected={selected}
            filterName={filterName}
            onFilterName={handleFilterByName}
            toolbarProps={toolbarProps}
            setPage={setPage}
            setFilterName={setFilterName}
            visibleRows={visibleRows}
            handleOpenBackdrop={handleOpenBackdrop}
            handleCloseBackdrop={handleCloseBackdrop}
          />
        );
      case Types.toolbarTypes.STUDIES:
        return (
          <StudiesToolbar
            selected={selected}
            filterName={filterName}
            onFilterName={handleFilterByName}
            toolbarProps={toolbarProps}
            setPage={setPage}
            handleSelectionClick={handleSelectionClick}
            handleClearSelection={handleClearSelection}
            handleOpenBackdrop={handleOpenBackdrop}
            handleCloseBackdrop={handleCloseBackdrop}
            rows={rows}
          />
        );
      case Types.toolbarTypes.TASKS:
        return (
          <TasksToolbar
            selected={selected}
            filterName={filterName}
            onFilterName={handleFilterByName}
            toolbarProps={toolbarProps}
            setPage={setPage}
            handleSelectionClick={handleSelectionClick}
            handleClearSelection={handleClearSelection}
            handleOpenBackdrop={handleOpenBackdrop}
            handleCloseBackdrop={handleCloseBackdrop}
            rows={rows}
          />
        );
      case Types.toolbarTypes.PROJECT_LOGS:
        return (
          <ProjectLogsToolbar
            selected={selected}
            filterName={filterName}
            onFilterName={handleFilterByName}
            toolbarProps={toolbarProps}
            setPage={setPage}
            handleSelectionClick={handleSelectionClick}
            handleClearSelection={handleClearSelection}
            handleOpenBackdrop={handleOpenBackdrop}
            handleCloseBackdrop={handleCloseBackdrop}
          />
        );
      case Types.toolbarTypes.CUSTOMER_LOCATIONS_TOOLBAR:
        return (
          <CustomerLocationsToolbar
            selected={selected}
            filterName={filterName}
            onFilterName={handleFilterByName}
            toolbarProps={toolbarProps}
            setPage={setPage}
            handleClearSelection={handleClearSelection}
            handleSelectionClick={handleSelectionClick}
            handleOpenBackdrop={handleOpenBackdrop}
            handleCloseBackdrop={handleCloseBackdrop}
          />
        );
      case Types.toolbarTypes.SUPER_USER:
        return (
          <CustomerLocationsToolbar
            selected={selected}
            filterName={filterName}
            onFilterName={handleFilterByName}
            toolbarProps={toolbarProps}
            setPage={setPage}
            handleSelectionClick={handleSelectionClick}
            handleClearSelection={handleClearSelection}
            handleOpenBackdrop={handleOpenBackdrop}
            handleCloseBackdrop={handleCloseBackdrop}
          />
        );
      case Types.toolbarTypes.LOCATIONS:
        return (
          <LocationsToolbar
            selected={selected}
            filterName={filterName}
            onFilterName={handleFilterByName}
            toolbarProps={toolbarProps}
            setPage={setPage}
            handleSelectionClick={handleSelectionClick}
            handleClearSelection={handleClearSelection}
            handleOpenBackdrop={handleOpenBackdrop}
            handleCloseBackdrop={handleCloseBackdrop}
          />
        );
      case Types.toolbarTypes.AREAS:
        return (
          <AreasToolbar
            selected={selected}
            filterName={filterName}
            onFilterName={handleFilterByName}
            toolbarProps={toolbarProps}
            setPage={setPage}
            handleSelectionClick={handleSelectionClick}
            handleClearSelection={handleClearSelection}
            handleOpenBackdrop={handleOpenBackdrop}
            handleCloseBackdrop={handleCloseBackdrop}
            statusUpdateUrl={statusUpdateUrl}
            rows={rows}
          />
        );
      case Types.toolbarTypes.QUESTION:
        return (
          <QuestionsToolbar
            selected={selected}
            filterName={filterName}
            onFilterName={handleFilterByName}
            toolbarProps={toolbarProps}
            setPage={setPage}
            handleSelectionClick={handleSelectionClick}
            handleClearSelection={handleClearSelection}
            handleOpenBackdrop={handleOpenBackdrop}
            handleCloseBackdrop={handleCloseBackdrop}
            statusUpdateUrl={statusUpdateUrl}
            rows={rows}
          />
        );
      case Types.toolbarTypes.ROLES:
        return (
          <RolesToolbar
            selected={selected}
            filterName={filterName}
            onFilterName={handleFilterByName}
            toolbarProps={toolbarProps}
            setPage={setPage}
            handleSelectionClick={handleSelectionClick}
            handleClearSelection={handleClearSelection}
            handleOpenBackdrop={handleOpenBackdrop}
            handleCloseBackdrop={handleCloseBackdrop}
            statusUpdateUrl={statusUpdateUrl}
            rows={rows}
          />
        );
      case Types.toolbarTypes.CATEGORY:
        return (
          <CategoriesToolbar
            selected={selected}
            filterName={filterName}
            onFilterName={handleFilterByName}
            toolbarProps={toolbarProps}
            setPage={setPage}
            handleSelectionClick={handleSelectionClick}
            handleClearSelection={handleClearSelection}
            handleOpenBackdrop={handleOpenBackdrop}
            handleCloseBackdrop={handleCloseBackdrop}
            statusUpdateUrl={statusUpdateUrl}
            rows={rows}
          />
        );
      case Types.toolbarTypes.ELEMENT:
        return (
          <ElementToolbar
            selected={selected}
            filterName={filterName}
            onFilterName={handleFilterByName}
            toolbarProps={toolbarProps}
            setPage={setPage}
            handleSelectionClick={handleSelectionClick}
            handleClearSelection={handleClearSelection}
            handleOpenBackdrop={handleOpenBackdrop}
            handleCloseBackdrop={handleCloseBackdrop}
            statusUpdateUrl={statusUpdateUrl}
            rows={rows}
          />
        );
      case Types.toolbarTypes.GROUPS:
        return (
          <GroupsToolbar
            selected={selected}
            filterName={filterName}
            onFilterName={handleFilterByName}
            toolbarProps={toolbarProps}
            setPage={setPage}
            handleSelectionClick={handleSelectionClick}
            handleClearSelection={handleClearSelection}
            handleOpenBackdrop={handleOpenBackdrop}
            handleCloseBackdrop={handleCloseBackdrop}
            statusUpdateUrl={statusUpdateUrl}
          />
        );
      case Types.toolbarTypes.CUSTOMER:
        return (
          <CustomersToolbar
            selected={selected}
            filterName={filterName}
            onFilterName={handleFilterByName}
            toolbarProps={toolbarProps}
            setPage={setPage}
            handleSelectionClick={handleSelectionClick}
            handleClearSelection={handleClearSelection}
            handleOpenBackdrop={handleOpenBackdrop}
            handleCloseBackdrop={handleCloseBackdrop}
          />
        );
      case Types.toolbarTypes.HOURS_MAPPING:
        return (
          <HoursMappingToolbar
            selected={selected}
            filterName={filterName}
            onFilterName={handleFilterByName}
            toolbarProps={toolbarProps}
            setPage={setPage}
            handleClearSelection={handleClearSelection}
            handleSelectionClick={handleSelectionClick}
            handleOpenBackdrop={handleOpenBackdrop}
            handleCloseBackdrop={handleCloseBackdrop}
          />
        );
      default:
        return (
          <EnhancedTableToolbar
            handleClearSelection={handleClearSelection}
            selected={selected}
            handleSelectionClick={handleSelectionClick}
            filterName={filterName}
            handleOpenBackdrop={handleOpenBackdrop}
            handleCloseBackdrop={handleCloseBackdrop}
            onFilterName={handleFilterByName}
          />
        );
    }
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;
  const isRowCollapsed = (id) => CollapsedRows.indexOf(id) !== -1;
  const isNestedRowCollapsed = (id) => nestedCollapsedRows.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.

  const parentRowClick = (event, row) => {
    if (isRowCollapsed(row?._id)) {
      setCollapsedRows(CollapsedRows.filter((collapsedRow) => collapsedRow !== row?._id));
    } else {
      setCollapsedRows((prevRows) => [...prevRows, row?._id]);
    }
    // handleClick(event, row?._id)
  };
  const nestedRowClick = (event, row) => {
    if (disabbleNestedArrowIf && disabbleNestedArrowIf(row)) {
      return '';
    }
    if (!event.target.closest('button')) {
      if (isNestedRowCollapsed(row?._id)) {
        setNestedCollapsedRows(nestedCollapsedRows.filter((collapsedRow) => collapsedRow !== row?._id));
      } else if (singleNestedRow) {
        setNestedCollapsedRows((prevRows) => [row?._id]);
      } else {
        setNestedCollapsedRows((prevRows) => [...prevRows, row?._id]);
      }
    }
    return '';
    // handleClick(event, row?._id)
  };
  const onRowClick = (event, row) => {
    if (!event.target.closest('button')) {
      handleRowClick(event, row, nestedCollapsedRows, setNestedCollapsedRows);
    }
    return '';
  };
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const visibleReorderRow = visibleRows.map((row) => ({
    ...row,
    id: row?._id,
  }));
  const closeNestedRow = (rowToRemove) => {
    const filteredRows = nestedCollapsedRows.filter((row) => row !== rowToRemove?._id);
    setNestedCollapsedRows(filteredRows);
  };

  const renderNestedRow = ({ rowType, row }) => {
    switch (rowType) {
      case Types.nestedRowTypes.HOURS_MAPPING:
        return (
          <HoursNestedRow
            row={row}
            closeNestedRow={closeNestedRow}
            handleOpenBackdrop={handleOpenBackdrop}
            handleCloseBackdrop={handleCloseBackdrop}
          />
        );
      case Types.nestedRowTypes.STUDIES:
        return (
          <StudiesNestedRow
            row={row}
            closeNestedRow={closeNestedRow}
            handleOpenBackdrop={handleOpenBackdrop}
            handleCloseBackdrop={handleCloseBackdrop}
            nestedRowProps={nestedRowProps}
            setNestedCollapsedRows={setNestedCollapsedRows}
          />
        );
      case Types.nestedRowTypes.STUDY:
        return (
          <SingleStudyNestedRow
            row={row}
            closeNestedRow={closeNestedRow}
            handleOpenBackdrop={handleOpenBackdrop}
            handleCloseBackdrop={handleCloseBackdrop}
            nestedRowProps={nestedRowProps}
            setNestedCollapsedRows={setNestedCollapsedRows}
          />
        );
      case Types.nestedRowTypes.NEST_ROW:
        return (
          <NotesNestedRow
            row={row}
            closeNestedRow={closeNestedRow}
            handleOpenBackdrop={handleOpenBackdrop}
            handleCloseBackdrop={handleCloseBackdrop}
            nestedRowProps={nestedRowProps}
            setNestedCollapsedRows={setNestedCollapsedRows}
          />
        );
      case Types.nestedRowTypes.REMINDERS:
        return (
          <RemindersNestedRow
            row={row}
            closeNestedRow={closeNestedRow}
            handleOpenBackdrop={handleOpenBackdrop}
            handleCloseBackdrop={handleCloseBackdrop}
          />
        );
      // case Types?.nestedRowTypes?.GROUP_ROLES:
      //   return <NestedGroupRoleRow row={row} />;
      case Types?.nestedRowTypes?.TASKS:
        return <TasksNestedRow nestedRowProps={nestedRowProps} row={row} />;
      case Types?.nestedRowTypes?.LOGS:
        return <LogsNestedRow nestedRowProps={nestedRowProps} row={row} />;
      case Types?.nestedRowTypes.ADMIN_FIELD_USER:
        return <FieldUserNestedPage nestedRowProps={nestedRowProps} row={row} />;
      default:
        return null;
    }
  };
  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };
  const onConfirmAllStatusChange = () => {
    handleClearSelection();
    setAllStatusModelOpened(false);
  };
  const isPaginationDisable = useMemo(() => {
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!dataFiltered?.length) {
      if (dataFiltered?.length <= rowsPerPage) {
        // return true;
        return false;
      }
      return false;
    }
    return true;
  }, [dataFiltered, rowsPerPage]);
  const totalRowsHeight = rowsPerPage && rowsPerPage * (dense ? 41 : 61) + (dense ? 37.56 : 57.56); // +1
  const visibleRowsHeight = !!visibleRows?.length && visibleRows.length * (dense ? 41 : 61) + (dense ? 37.56 : 57.56);
  // const visibleRowsHeight = !!visibleRows?.length && visibleRows.length * (dense ? 41 : 61) + (dense ? 37.56 : 57.56);
  const calculateHeight = useCallback(() => {
    if (rowsPerPage === -1) {
      // case when ALL option is selected
      if (!!rows?.length && rows.length * (dense ? 41 : 61) > maxHeight) {
        // case when total rows height is greater than maxHeight
        if (visibleRowsHeight < maxHeight) {
          // case when visibleRowsHeight is less than maxHeight (no scrollbar)
          if (visibleRowsHeight > 0) {
            // case for filtered rows and empty spaces
            return maxHeight - visibleRowsHeight;
          }
          // case for no data found
          return maxHeight - visibleRowsHeight - (dense ? 113 : 133) - (dense ? 41 : 61);
        }
        return 0;
      }
      if (visibleRowsHeight > 0) {
        // case for filtered rows and empty spaces
        return maxHeight - visibleRowsHeight;
      }
      // case for no data found
      return maxHeight - visibleRowsHeight - (dense ? 113 : 133) - (dense ? 41 : 61);
      // return maxHeight - visibleRowsHeight; // make codnditions as well
    }
    if (maxHeight && visibleRows?.length <= 0) {
      // case for no data found
      if (totalRowsHeight > maxHeight) {
        // case for no data found and when total rows height is greater than max height
        return maxHeight - visibleRowsHeight - (dense ? 113 : 133) - (dense ? 37.56 : 57.56);
      }
      return rowsPerPage * (dense ? 41 : 61) - (dense ? 113 : 133);
    }
    if (!maxHeight || visibleRowsHeight > totalRowsHeight) {
      return 0;
    }
    if (totalRowsHeight > maxHeight) {
      if (maxHeight && visibleRowsHeight < maxHeight) {
        return maxHeight - visibleRowsHeight;
      }
      return 0;
    }
    // return 0;
    return rowsPerPage * (dense ? 41 : 61) - visibleRows.length * (dense ? 41 : 61);
  }, [dense, maxHeight, rows.length, rowsPerPage, totalRowsHeight, visibleRows.length, visibleRowsHeight]);

  const getTitledWord = (word) => {
    if (typeof word === 'string') {
      return word?.replace(/\w+/g, capitalize);
    }
    return '';
  };
  const getCellColor = (title) => {
    if (title === 'Removed From HUB' || title === 'Removed From Project') {
      return 'red';
    }
    return '#212B36';
  };
  const getCellFontWeight = (title) => {
    if (title === 'Removed From HUB' || title === 'Removed From Project') {
      return 'bold';
    }
    return 400;
  };
  const createSortHandler = (property) => (event) => {
    if (setElementFilterConfig) {
      setElementFilterConfig((prev) => ({ ...prev, sortOrder: '', sortBy: '' }));
    }
    handleRequestSort(event, property);
  };
  const isActiveFilter = (activeId) => {
    if (filterConfig && Object.keys(get(filterConfig, 'filters')).length > 0) {
      return Object.keys(get(filterConfig, 'filters')).some(
        (keyName) => keyName === activeId && filterConfig?.filters?.[keyName]?.length > 0
      );
    }
    return false;
  };
  const fixedHeaderContent = () => (
    <TableRow>
      {!disableCheckbox && (role === 1 || role === 2 || enableCheckbox) && (
        <TableCell sx={{ bgcolor: '#F9FAFB' }} padding="checkbox">
          <Checkbox
            size="small"
            color="primary"
            // indeterminate={numSelected > 0 && numSelected < rowCount}
            // checked={rowCount > 0 && numSelected === rowCount}
            checked={isAllChecked}
            onChange={handleSelectAllClick}
            onClick={(event) => handleSelectAllClick(event)}
            disabled={visibleRows?.length <= 0}
          />
        </TableCell>
      )}
      {headCells?.map((headCell) => {
        let a;
        return (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? 'right' : 'left'}
            align={headCell.align || 'left'}
            sx={{ pl: headCell.pl || 'none', bgcolor: '#F9FAFB', minWidth: headCell?.minWidth, maxWidth: headCell?.maxWidth }}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            colSpan={headCell?.colSpan}
            style={{ width: headCell?.width, minWidth: headCell?.width, whiteSpace: 'nowrap' }}
          >
            <TableSortLabel
              active={orderBy === headCell?.id}
              direction={orderBy === headCell?.id ? order : 'asc'}
              // onClick={() => handleSortClick(headCell)}
              // onClick={headCell?.disableSort ? null : headCell?.onClick || createSortHandler(headCell?.id)}
              onClick={(event) => {
                if (!headCell?.disableSort) {
                  // Check if there's a custom onClick function defined for this head cell
                  if (headCell?.onClick) {
                    setOrder('');
                    setOrderBy('');
                    headCell.onClick();
                  } else {
                    createSortHandler(headCell?.id)(event);
                  }
                }
              }}
              sx={{ fontWeight: 'bold', fontSize: '14px' }}
            >
              {headCell?.label || ''} {isActiveFilter(headCell.id) && <Typography sx={{ color: 'red', pl: 0.5 }}>*</Typography>}
              {orderBy === headCell?.id ? (
                <Box component="span" sx={visuallyHidden && visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        );
      })}
      {!disableActions && (enableActions || role === 1 || role === 2) && (
        <TableCell
          align="center"
          sx={{
            bgcolor: '#F9FAFB',
            fontWeight: 'bold',
            // minWidth: 120,
            fontSize: '14px',
            width: ActionWidth || 'unset',
          }}
        >
          Actions{' '}
          {customColumnType && onSettingsClick && (
            <IconButton onClick={(event) => onSettingsClick(event)}>
              <SettingsIcon />
            </IconButton>
          )}
        </TableCell>
      )}
    </TableRow>
  );
  const rowContent = (index, row) => {
    const isItemSelected = isSelected(row?._id);
    // const isSystemElement = row?.defaultEntry || false;
    const isSystemElement = false;
    const shouldHideEditButton =
      isSystemElement || (typeof hideEditButton === 'function' && hideEditButton ? hideEditButton(row) : false);
    return (
      <>
        {!disableCheckbox && (role === 1 || role === 2 || enableCheckbox) && (
          <TableCell padding="checkbox">
            <Checkbox
              onClick={(event) => handleClick(event, row?._id)}
              size="small"
              color="primary"
              checked={isItemSelected}
              disabled={disableCheckboxIf && disableCheckboxIf(row)}
            />
          </TableCell>
        )}
        {headCells.map((cell, cellIndex) => {
          if (cell?.renderElement) {
            return (
              <TableCell
                align={cell.align || 'left'}
                sx={{ minWidth: cell?.minWidth, maxWidth: cell?.maxWidth }}
                colSpan={cell?.colSpan}
              >
                {cell?.renderElement(row)}
              </TableCell>
            );
          }
          return (
            <TableCell
              // colSpan={cell?.colSpan}
              // padding={cellIndex === 0 ? 'none' : 'inherit'}
              align={cell.align || 'left'}
            >
              <Stack direction="row">
                {cellIndex === 0 && nestedRowKey && !hideNestedArrow && (
                  <ChevronRightIcon
                    className={`side-icon ${isNestedRowCollapsed(row?._id) && 'rotate-icon'}`}
                    fontSize="small"
                    sx={{
                      mr: 2,
                      color: '#212B36',
                      opacity: (hideNestedFunc ? hideNestedFunc(row) : false) && 0,
                    }}
                  />
                )}
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: getCellFontWeight(get(row, `${cell.id}`)),
                    color: getCellColor(get(row, `${cell.id}`)),
                  }}
                  variant="captions"
                >
                  {has(row, `${cell.id}`)
                    ? cell?.titleCase && false
                      ? // ? startCase(get(row, `${cell.id}`))
                        getTitledWord(get(row, `${cell.id}`))
                      : get(row, `${cell.id}`)
                    : ''}
                </Typography>
              </Stack>
              {/* {get(row, `${cell.id}`) || row[`${cell.id}`]} */}
            </TableCell>
          );
        })}
        {!disableActions && (enableActions || role === 1 || role === 2) && (
          <TableCell>
            <Stack alignItems="center" justifyContent="center" direction="row">
              {addObservation && get(row, 'studyType') && row?.studyType === 1 && (
                <BootstrapTooltip enterDelay={500} title="Add to Round">
                  <IconButton
                    sx={{ p: 0.5 }}
                    onClick={(event) => !!row && addObservation && addObservation(row, event)}
                    disabled={isItemSelected || disableAddObservation}
                  >
                    <Iconify icon="ant-design:file-add-twotone" style={{ color: ' #00c0f3' }} />
                  </IconButton>
                </BootstrapTooltip>
              )}

              {!shouldHideEditButton && (
                <BootstrapTooltip enterDelay={500} title="Edit">
                  <IconButton
                    sx={{ p: 0.5 }}
                    disabled={isItemSelected}
                    onClick={(event) =>
                      !!row && onEditClick && onEditClick(event, row, nestedCollapsedRows, setNestedCollapsedRows)
                    }
                  >
                    <Iconify icon="iconamoon:edit-duotone" />
                  </IconButton>
                </BootstrapTooltip>
              )}
              {downloadStudyRow && (
                <BootstrapTooltip enterDelay={500} title="Download Study">
                  <IconButton
                    sx={{ p: 0.5 }}
                    onClick={(event) => !!row && downloadStudyRow && downloadStudyRow(row, event)}
                    disabled={isItemSelected}
                  >
                    <Iconify icon="material-symbols:download" style={{ color: ' #00c0f3' }} />
                  </IconButton>
                </BootstrapTooltip>
              )}
              {
                // !isSystemElement &&
                onRowStatusChange &&
                  row?.status &&
                  (row?.status === Types.ACTIVE ? (
                    row?._id && (
                      <BootstrapTooltip enterDelay={500} title="Archive">
                        <IconButton
                          disabled={isItemSelected}
                          sx={{ p: 0.5 }}
                          onClick={(event) => handleStatusButtonClick(event, row, 'archive')}
                        >
                          <Iconify icon="tabler:archive" />
                        </IconButton>
                      </BootstrapTooltip>
                    )
                  ) : (
                    <BootstrapTooltip enterDelay={500} title="Activate">
                      <IconButton
                        disabled={isItemSelected}
                        sx={{ p: 0.5, color: 'success.darker' }}
                        onClick={(event) => handleStatusButtonClick(event, row, 'active')}
                      >
                        <Iconify icon="ph:play-duotone" />
                      </IconButton>
                    </BootstrapTooltip>
                  ))
              }
              {(row?.status === Types.ARCHIVED || enableDelete) && onRowDelete ? (
                <BootstrapTooltip enterDelay={500} title="Remove">
                  <IconButton
                    disabled={isItemSelected}
                    sx={{ p: 0.5, color: 'error.main' }}
                    onClick={(event) => handleDeleteClick(event, row)}
                  >
                    <Iconify icon="ant-design:delete-twotone" />
                  </IconButton>
                </BootstrapTooltip>
              ) : null}
            </Stack>
          </TableCell>
        )}
        {/* {!!nestedRowKey && isNestedRowCollapsed(row?._id) && renderNestedRow({ rowType: nestedRowKey, row })} */}
      </>
    );
  };
  const getPageCounts = useMemo(() => {
    if (isGroupViewToggled) {
      if (dataFiltered?.length > 0) {
        const filteredElement = dataFiltered?.filter((field) => field?.projectCount > 0);
        return filteredElement?.length;
      }
      return 0;
    }
    return dataFiltered?.length;
  }, [dataFiltered, isGroupViewToggled]);

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 100 }}
        open={openBackdrop || isSelectedStudyLoading}
        onClick={handleOpenBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ConfirmDialog
        title="Change Status"
        open={allStatusModelOpened}
        setOpen={setAllStatusModelOpened}
        confirmText="Change Status"
        onConfirm={onConfirmAllStatusChange}
      >
        <Typography variant="body1">
          Are you sure you want to <b>{statusToChange === 'active' ? 'activate' : 'archive'}</b> this?
        </Typography>
      </ConfirmDialog>
      <Box sx={{ width: '100%', height: 'calc(100% - 52px)' }}>
        <Card className="table-wrapper">
          <ConfirmDialog
            title="Change Status"
            open={isStatusModelOpen}
            setOpen={setStatusModelOpen}
            confirmText="Change Status"
            selectedField={selectedRowToChangeStatus}
            onConfirm={onConfirmStatusChange}
          >
            <Typography variant="body1">
              Are you sure you want to <b>{statusType === 'active' ? 'activate' : 'archive'}</b> this?
            </Typography>
          </ConfirmDialog>
          <ConfirmDialog
            title="Delete"
            open={isDeleteModelOpen}
            setOpen={setDeleteModelOpen}
            selectedField={selectedRowToDelete}
            onConfirm={onConfirmDelete}
            confirmText="Delete"
          >
            <Typography variant="body1">
              Are you sure you want to <b>delete</b> this?
            </Typography>
          </ConfirmDialog>
          {customToolbar ? (
            renderToolbar(customToolbar)
          ) : noToolbar ? null : (
            <EnhancedTableToolbar
              selected={selected}
              handleSelectionClick={handleSelectionClick}
              filterName={filterName}
              onFilterName={handleFilterByName}
            />
          )}
          {isLoading && <LinearProgress />}
          {/* <TableContainer sx={{ overflow: 'unset' }}> */}
          {/* <ScrollBar> */}
          {!toolbarProps?.isReording && (!groupHeadCells || !isGroupViewToggled) ? (
            <TableVirtuoso
              context={{
                isSelected,
                dense,
                calculateHeight,
                maxHeight,
                rowsLength: visibleRows.length,
                onRowClick,
                handleRowClick,
                filterName,
                nestedRowKey,
                disableNestedRowClick,
                nestedRowClick,
                renderNestedRow,
                nestedCollapsedRows,
              }}
              data={visibleRows}
              components={VirtuosoTableComponents}
              fixedHeaderContent={fixedHeaderContent}
              itemContent={rowContent}
              style={{
                // height: rowsPerPage === -1 ? maxHeight : 55 * rowsPerPage + 57.56,
                height: rowsPerPage === -1 ? maxHeight : (dense ? 41 : 61) * rowsPerPage + (dense ? 41 : 61),
                maxHeight,
              }}
              // style={{ maxHeight: maxHeight && maxHeight, minHeight: !(visibleRows?.length > 0) && 100 }}
            />
          ) : (
            <TableContainer
              className="table-container"
              sx={{
                // height: maxHeight,
                // maxHeight: maxHeight && maxHeight,
                minHeight: !(visibleRows?.length > 0) && 100,
              }}
            >
              {/* {visibleRows?.length > 0 ? ( */}
              <Table
                stickyHeader={stickyHeader}
                sx={{ minWidth: minWidth || 800 }}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
              >
                <EnhancedTableHead
                  role={role}
                  customColumnType={customColumnType}
                  numSelected={groupHeadCells && isGroupViewToggled ? selectedParent.length : selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  isAllChecked={isAllChecked}
                  disableCheckbox={disableCheckbox}
                  enableCheckbox={enableCheckbox}
                  setElementFilterConfig={setElementFilterConfig}
                  onSettingsClick={onSettingsClick}
                  filterConfig={filterConfig}
                  // rowCount={rows.length}
                  noActions={!!disableActions}
                  enableActions={!!enableActions}
                  rowCount={visibleRows?.length}
                  headCells={headCells}
                  setOrder={setOrder}
                  setOrderBy={setOrderBy}
                  ActionWidth={ActionWidth}
                />
                <TableBody>
                  {!toolbarProps?.isReording ? (
                    visibleRows &&
                    !!visibleRows?.length &&
                    visibleRows.map((row, index) => {
                      const isItemSelected = isSelected(row?._id);
                      const isParentRowSelected = selectedParent.indexOf(row?._id) !== -1;
                      const isCollapsed = isRowCollapsed(row?._id);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      const isArchieved = row?.status ? Boolean(row?.status === Types.ARCHIVED) : false;
                      // const isSystemElement = row?.defaultEntry || false;
                      const isSystemElement = false;
                      const shouldHideEditButton =
                        isSystemElement || (typeof hideEditButton === 'function' && hideEditButton ? hideEditButton(row) : false);
                      return !groupHeadCells || !isGroupViewToggled ? (
                        <>
                          <TableRow
                            hover
                            // onClick={(event) => handleClick(event, row?._id)}
                            onClick={(event) =>
                              handleRowClick
                                ? onRowClick(event, row)
                                : nestedRowKey && !disableNestedRowClick && nestedRowClick(event, row)
                            }
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isItemSelected}
                            sx={{
                              bgcolor: getRowColor(isArchieved, row),
                              cursor: 'pointer',
                              '&.Mui-selected': {
                                bgcolor: (theme) => alpha(theme.palette.primary.light, 0.2),
                              },
                            }}
                          >
                            {!disableCheckbox && (role === 1 || role === 2 || enableCheckbox) && (
                              <TableCell padding="checkbox">
                                <Checkbox
                                  onClick={(event) => handleClick(event, row?._id)}
                                  size="small"
                                  color="primary"
                                  checked={isItemSelected}
                                  disabled={disableCheckboxIf && disableCheckboxIf(row)}
                                />
                              </TableCell>
                            )}
                            {headCells.map((cell, cellIndex) => {
                              if (cell?.renderElement) {
                                return (
                                  <TableCell
                                    align={cell.align || 'left'}
                                    sx={{ minWidth: cell?.minWidth, maxWidth: cell?.maxWidth }}
                                    colSpan={cell?.colSpan}
                                  >
                                    {cell?.renderElement(row)}
                                  </TableCell>
                                );
                              }
                              return (
                                <TableCell
                                  // colSpan={cell?.colSpan}
                                  // padding={cellIndex === 0 ? 'none' : 'inherit'}
                                  align={cell.align || 'left'}
                                >
                                  <Stack direction="row">
                                    {cellIndex === 0 && nestedRowKey && !hideNestedArrow && (
                                      <ChevronRightIcon
                                        className={`side-icon ${isNestedRowCollapsed(row?._id) && 'rotate-icon'}`}
                                        fontSize="small"
                                        sx={{
                                          mr: 2,
                                          color: '#212B36',
                                          opacity: (hideNestedFunc ? hideNestedFunc(row) : false) && 0,
                                        }}
                                      />
                                    )}
                                    <Typography
                                      sx={{
                                        fontSize: '14px',
                                        fontWeight: getCellFontWeight(get(row, `${cell.id}`)),
                                        color: getCellColor(get(row, `${cell.id}`)),
                                      }}
                                      variant="captions"
                                    >
                                      {has(row, `${cell.id}`)
                                        ? cell?.titleCase
                                          ? // ? startCase(get(row, `${cell.id}`))
                                            getTitledWord(get(row, `${cell.id}`))
                                          : get(row, `${cell.id}`)
                                        : ''}
                                    </Typography>
                                  </Stack>
                                  {/* {get(row, `${cell.id}`) || row[`${cell.id}`]} */}
                                </TableCell>
                              );
                            })}
                            {!disableActions && (enableActions || role === 1 || role === 2) && (
                              <TableCell>
                                <Stack alignItems="center" justifyContent="center" direction="row">
                                  {addObservation && get(row, 'studyType') && row?.studyType === 1 && (
                                    <BootstrapTooltip enterDelay={500} title="Add to Round">
                                      <IconButton
                                        sx={{ p: 0.5 }}
                                        onClick={(event) => !!row && addObservation && addObservation(row, event)}
                                        disabled={isItemSelected || disableAddObservation}
                                      >
                                        <Iconify icon="ant-design:file-add-twotone" style={{ color: ' #00c0f3' }} />
                                      </IconButton>
                                    </BootstrapTooltip>
                                  )}

                                  {!shouldHideEditButton && (
                                    <BootstrapTooltip enterDelay={500} title="Edit">
                                      <IconButton
                                        sx={{ p: 0.5 }}
                                        disabled={isItemSelected}
                                        onClick={(event) =>
                                          !!row &&
                                          onEditClick &&
                                          onEditClick(event, row, nestedCollapsedRows, setNestedCollapsedRows)
                                        }
                                      >
                                        <Iconify icon="iconamoon:edit-duotone" />
                                      </IconButton>
                                    </BootstrapTooltip>
                                  )}
                                  {downloadStudyRow && (
                                    <BootstrapTooltip enterDelay={500} title="Download Study">
                                      <IconButton
                                        sx={{ p: 0.5 }}
                                        onClick={(event) => !!row && downloadStudyRow && downloadStudyRow(row, event)}
                                        disabled={isItemSelected}
                                      >
                                        <Iconify icon="material-symbols:download" style={{ color: ' #00c0f3' }} />
                                      </IconButton>
                                    </BootstrapTooltip>
                                  )}
                                  {
                                    // !isSystemElement &&
                                    onRowStatusChange &&
                                      row?.status &&
                                      (row?.status === Types.ACTIVE ? (
                                        row?._id && (
                                          <BootstrapTooltip enterDelay={500} title="Archive">
                                            <IconButton
                                              disabled={isItemSelected}
                                              sx={{ p: 0.5 }}
                                              onClick={(event) => handleStatusButtonClick(event, row, 'archive')}
                                            >
                                              <Iconify icon="tabler:archive" />
                                            </IconButton>
                                          </BootstrapTooltip>
                                        )
                                      ) : (
                                        <BootstrapTooltip enterDelay={500} title="Activate">
                                          <IconButton
                                            disabled={isItemSelected}
                                            sx={{ p: 0.5, color: 'success.darker' }}
                                            onClick={(event) => handleStatusButtonClick(event, row, 'active')}
                                          >
                                            <Iconify icon="ph:play-duotone" />
                                          </IconButton>
                                        </BootstrapTooltip>
                                      ))
                                  }
                                  {(row?.status === Types.ARCHIVED || enableDelete) && onRowDelete ? (
                                    <BootstrapTooltip enterDelay={500} title="Remove">
                                      <IconButton
                                        disabled={isItemSelected}
                                        sx={{ p: 0.5, color: 'error.main' }}
                                        onClick={(event) => handleDeleteClick(event, row)}
                                      >
                                        <Iconify icon="ant-design:delete-twotone" />
                                      </IconButton>
                                    </BootstrapTooltip>
                                  ) : null}
                                </Stack>
                              </TableCell>
                            )}
                          </TableRow>
                          {!!nestedRowKey && isNestedRowCollapsed(row?._id) && renderNestedRow({ rowType: nestedRowKey, row })}
                        </>
                      ) : (
                        <>
                          {!!row[childKey]?.length && (
                            <TableRow
                              hover
                              // onClick={(event) => handleClick(event, row?._id)}
                              onClick={(event) => parentRowClick(event, row)}
                              role="checkbox"
                              aria-checked={isParentRowSelected}
                              tabIndex={-1}
                              key={row.id}
                              selected={isParentRowSelected}
                              sx={{
                                bgcolor: isArchieved && ((theme) => alpha(theme.palette.error.main, 0.07)),
                                cursor: 'pointer',
                                '&.Mui-selected': {
                                  bgcolor: (theme) => alpha(theme.palette.primary.light, 0.2),
                                },
                              }}
                            >
                              <TableCell padding="checkbox">
                                {/* <Stack direction='row' alignItems='center'> */}
                                <Checkbox
                                  onClick={(event) => handleClick(event, row?._id, row[childKey] || null)}
                                  size="small"
                                  color="primary"
                                  checked={isParentRowSelected}
                                  inputProps={{
                                    'aria-labelledby': labelId,
                                  }}
                                />
                                {/* </Stack> */}
                              </TableCell>
                              {groupHeadCells?.map((cell, cellIndex) => {
                                if (cell?.renderElement) {
                                  return <TableCell>{cell?.renderElement(row)}</TableCell>;
                                }
                                return (
                                  <TableCell
                                    colSpan={cell?.colSpan && cell?.colSpan}
                                    sx={{
                                      pl: cellIndex === 0 ? 3 : 'none',
                                      bgcolor: cell?.colSpan && 'red',
                                    }}
                                    padding={cellIndex === 0 ? 'none' : 'inherit'}
                                    align={cell.align || 'left'}
                                  >
                                    <Stack direction="row" alignItems="center">
                                      {cellIndex === 0 && (
                                        <ChevronRightIcon
                                          className={`side-icon ${isCollapsed && 'rotate-icon'}`}
                                          fontSize="small"
                                          sx={{
                                            mr: 2,
                                            color: '#212B36',
                                          }}
                                        />
                                      )}
                                      {row[`${cell?.id}`]}
                                    </Stack>
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          )}
                          {isCollapsed &&
                            childKey &&
                            (row[childKey]?.length ? (
                              row[childKey]?.map((cellRow) => (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  isItemSelected
                                  tabIndex={-1}
                                  key={cellRow._id}
                                  selected={isSelected(cellRow?._id)}
                                  sx={{
                                    bgcolor:
                                      cellRow?.status && cellRow?.status === Types.ARCHIVED
                                        ? (theme) => alpha(theme.palette.error.main, 0.07)
                                        : (theme) => alpha(theme.palette.action.active, 0.06),
                                    cursor: 'pointer',
                                    '&.Mui-selected': {
                                      bgcolor: (theme) => alpha(theme.palette.primary.light, 0.2),
                                    },
                                    // display: isCollapsed ? 'visible' : 'none',
                                    // visibility: 0
                                  }}
                                >
                                  <TableCell padding="checkbox">
                                    <Checkbox
                                      onClick={(event) => handleClick(event, cellRow?._id)}
                                      size="small"
                                      color="primary"
                                      checked={isSelected(cellRow?._id)}
                                      inputProps={{
                                        'aria-labelledby': labelId,
                                      }}
                                    />
                                  </TableCell>
                                  {headCells.map((cell, cellIndex) => {
                                    if (cell?.renderElement) {
                                      return <TableCell>{cell?.renderElement(cellRow)}</TableCell>;
                                    }
                                    return (
                                      <TableCell
                                        sx={{
                                          pl: cellIndex === 0 ? 5 : 'none',
                                        }}
                                        padding={cellIndex === 0 ? 'none' : 'inherit'}
                                        align={cell.align || 'left'}
                                      >
                                        {cellRow[`${cell.id}`]}
                                      </TableCell>
                                    );
                                  })}
                                  <TableCell>
                                    <Stack direction="row">
                                      <IconButton>
                                        <Iconify icon="iconamoon:edit-duotone" />
                                      </IconButton>
                                      <IconButton>
                                        <Iconify icon="tabler:archive" />
                                      </IconButton>
                                    </Stack>
                                  </TableCell>
                                </TableRow>
                              ))
                            ) : (
                              <TableRow>
                                <TableCell />
                                <TableCell>
                                  <Typography>No Data Found</Typography>
                                </TableCell>
                              </TableRow>
                            ))}
                        </>
                      );
                    })
                  ) : (
                    <DndContext
                      modifiers={[restrictToFirstScrollableAncestor, restrictToVerticalAxis, restrictToParentElement]}
                      sensors={sensors}
                      collisionDetection={closestCenter}
                      onDragEnd={handleDragEnd}
                    >
                      {/* <SortableContext
                        items={items}
                        strategy={verticalListSortingStrategy}
                      >
                        {items.map(id => <SortableItem key={id} id={id} />)}
                      </SortableContext> */}
                      <ReorderTableBody
                        visibleRows={visibleReorderRow}
                        isSelected={isSelected}
                        isRowCollapsed={isRowCollapsed}
                        selectedParent={selectedParent}
                        childKey={childKey}
                        parentRowClick={parentRowClick}
                        headCells={headCells}
                        handleClick={handleClick}
                        groupHeadCells={groupHeadCells}
                      />
                    </DndContext>
                  )}

                  {visibleRows?.length <= 0 && (
                    <TableRow>
                      <TableCell sx={{ borderBottom: '1px solid transparent' }} colSpan={15}>
                        {' '}
                        <Box alignItems="center" display="flex" justifyContent="center" sx={{ height: 100, width: '100%' }}>
                          {!isLoading && !visibleRows?.length && (
                            <Typography variant="subtitle1" textAlign="center">
                              {`No data found ${filterName && 'for '} `} <b>{filterName && filterName}</b>
                            </Typography>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
                  {/* {emptyRows > 0 && ( */}
                  {maxHeight && (
                    <TableRow
                      sx={{ '& .MuiTableCell-root': { p: 0 } }}
                      style={{
                        height: calculateHeight(),
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              {/* ) : (
              <Box alignItems="center" display="flex" justifyContent="center" sx={{ height: 200 }}>
                {!isLoading && (
                  <Typography variant="h5" textAlign="center">
                    No data found
                  </Typography>
                )}
              </Box>
            ) */}
            </TableContainer>
          )}
          {/* </ScrollBar> */}
          <TablePagination
            sx={{
              backgroundClip: 'red',
              boxShadow:
                '-webkit-box-shadow: -1px -1px 34px -18px rgba(0,0,0,0.27);-moz-box-shadow: -1px -1px 34px -18px rgba(0,0,0,0.27);box-shadow: -1px -1px 34px -18px rgba(0,0,0,0.27);',
            }}
            rowsPerPageOptions={[5, 10, 15, 18, 25, { label: 'All', value: -1 }]}
            component="div"
            // count={rows?.length}
            count={dataFiltered?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            disabled={isPaginationDisable}
          />
        </Card>
        {!hideCompactViewButton && (
          <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Compact View" />
        )}
      </Box>
    </>
  );
};

EnhancedTable.propTypes = {
  onSettingsClick: PropTypes.func,
  ActionWidth: PropTypes.any,
  headCells: PropTypes.array.isRequired, // Add this line
  childKey: PropTypes.string,
  statusUpdateUrl: PropTypes.string,
  nestedRowKey: PropTypes.string,
  sortOrder: PropTypes.string,
  customColumnType: PropTypes.string,
  sortBy: PropTypes.string,
  maxHeight: PropTypes.number,
  defaultRowsPerPage: PropTypes.oneOf([5, 10, 25, -1]),
  groupHeadCells: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
  minWidth: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  filterCallback: PropTypes.func,
  checkRowCondition: PropTypes.func,
  filterKeys: PropTypes.arrayOf(PropTypes.string),
  customToolbar: PropTypes.node,
  toolbarProps: PropTypes.object,
  nestedRowProps: PropTypes.object,
  stickyHeader: PropTypes.bool,
  handleDragEnd: PropTypes.func,
  handleRowClick: PropTypes.func,
  isLoading: PropTypes.bool,
  hideCompactViewButton: PropTypes.bool,
  disableNestedRowClick: PropTypes.bool,
  hideNestedArrow: PropTypes.bool,
  singleNestedRow: PropTypes.bool,
  enableDelete: PropTypes.bool,
  noToolbar: PropTypes.bool,
  isGlobalFilter: PropTypes.bool,
  hideEditButton: PropTypes.bool,
  disableAddObservation: PropTypes.bool,
  disableActions: PropTypes.bool,
  enableActions: PropTypes.bool,
  disableCheckbox: PropTypes.bool,
  enableCheckbox: PropTypes.bool,
  onEditClick: PropTypes.func,
  onRowStatusChange: PropTypes.func,
  hideNestedFunc: PropTypes.func,
  onRowDelete: PropTypes.func,
  setElementFilterConfig: PropTypes.func,
  addObservation: PropTypes.func,
  disableCheckboxIf: PropTypes.func,
  disabbleNestedArrowIf: PropTypes.func,
  downloadStudyRow: PropTypes.func,
  filterConfig: PropTypes.func,
  filterBy: PropTypes.any,
  filterColumns: PropTypes.any,
};

export default EnhancedTable;
